<!--
  Dashboard Weather Card

  Renders a card with the following details to make up the day's weather forecast:
  - icon
  - date
  - tempLow
  - tempHigh
-->
<template>
	<div :class="$vuetify.breakpoint.width > 833 ? 'ml-4' : 'mx-2'">

		<v-card flat width="94" height="112" class="pa-2 d-flex flex-column justify-space-around align-center">

			<!--Icon-->
			<v-icon class="pa-0" :class="forecast.image_icon" color="primary" size="48"/>

			<!--Date-->
			<app-text category="text-default" class="grey--text pa-0">
				{{ $moment(forecast.dt, "X").format('ddd') }}
			</app-text>

			<!--Temperature (Low | High)-->
			<app-text category="text-default" class="primary--text pa-0">
				{{ forecast.temp.min.toFixed() }}° | {{ forecast.temp.max.toFixed() }}°
			</app-text>

		</v-card>

	</div>
</template>

<script>
export default {
	props: ['forecast']
}
</script>
