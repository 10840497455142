<template>
	<qrcode-stream :style="{height: `${readerSize()}px`, width: `${readerSize()}px`}"
				   @decode="onDecode"
				   @init="onInit"/>
</template>

<script>
import {QrcodeStream} from "vue-qrcode-reader";
// TODO - detect browser and device and show message
export default {
	components: {QrcodeStream},
	name: "QrCodeReader",
	methods: {
		/**
		 * Reader Size
		 *
		 * The QR reader doesn't provide any access to their styling on the API,
		 * and on mobiles it's set to portrait which pushes the screen instructions down below the fold.
		 * This just takes the screen width and is used to set the height and width to the same dimensions.
		 *
		 * @returns {number} - the screen width
		 */
		readerSize() {
			return this.$vuetify.breakpoint.width
		},

		/**
		 * On Decode
		 *
		 * @param result
		 */
		onDecode(result) {
			this.MIX_decodeResult(result);
		},

		/**
		 * On Init
		 *
		 * @param promise
		 * @returns {Promise<void>}
		 */
		async onInit(promise) {
			try {
				await promise;
			} catch (error) {
				this.MIX_onInitError(error);
			}
		},
	}
}
</script>
