<template>

	<page-loading v-if="isPageLoading" :is-page-loading="isPageLoading"/>

	<div v-else style="width: 100%">

		<!--Header-->
		<div class="d-flex align-center pa-4">

			<!--Username and Status-->
			<div class="ml-2">

				<!--Pending-->
				<app-text v-if="form.userStatus === 'PENDING'" category="text-default" class="mt-1">
					<v-icon class="icons8-circle mb-1 mr-2" color="accent" size="32"/>
					{{ form.userName }}
				</app-text>

				<!--Approved-->
				<app-text v-if="form.userStatus === 'APPROVED'" category="text-default" class="mt-1">
					<v-icon class="icons8-checkmark-yes mb-1 mr-2" color="success" size="32"/>
					{{ form.userName }}
				</app-text>

				<!--Suspended-->
				<app-text v-if="form.userStatus === 'SUSPENDED'" category="text-default" class="mt-1">
					<v-icon class="icons8-private mb-1 mr-2" color="error" size="32"/>
					{{ form.userName }}
				</app-text>

				<!--Archived-->
				<app-text v-if="form.userStatus === 'ARCHIVED'" category="text-default" class="mt-1">
					<v-icon class="icons8-xbox-a mb-1 mr-2" color="grey" size="32"/>
					{{ form.userName }}
				</app-text>

			</div>

			<v-spacer/>

			<!--Action buttons (edit | delete)-->
			<div class="d-flex align-center">

				<!--Edit-->
				<v-icon v-if="formMode !== 'New'"
						@click.native="editItem()"
						class="icons8-edit"
						:class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"
						:color="formReadOnly ? 'warning' : 'white'"
						size="32"/>

				<!--Delete-->
				<v-icon v-if="formMode !== 'New' && form.delete === undefined"
						@click.native="deleteItem(form.id)"
						class="icons8-trash frc-icon"
						color="error"
						size="32"/>

			</div>

		</div>

		<!--Page content-->
		<div>

			<!--Tabs (Overview | Location History)-->
			<v-tabs v-model="tabs">

				<v-tabs-slider color="accent"/>

				<!--Overview-->
				<v-tab href="#overview">
					<app-text category="text-small">Overview</app-text>
				</v-tab>

				<!--Location History-->
				<v-tab v-if="formMode !== 'New'" href="#location">
					<app-text category="text-small">Location History</app-text>
				</v-tab>

				<!--Schedule-->
				<!-- <v-tab v-if="formMode !== 'New'" href="#schedule">
					<app-text category="text-small">Schedule</app-text>
				</v-tab> -->

			</v-tabs>

			<!--Tabs Content (Overview | Location History)-->
			<v-tabs-items class="appbackground" v-model="tabs">

				<!--Overview tab content-->
				<v-tab-item value="overview">

					<!--Form-->
					<v-row class="pa-4" no-gutters>

						<!--Profile Picture | User Details-->
						<v-row no-gutters style="width: 100%">

							<!--Profile picture-->
							<v-col
								class="d-flex justify-center align-center pa-2 rounded-lg white profileImage-container"
								:class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mr-4' : ''"
								:cols="$vuetify.breakpoint.width < 600 && 12"
								style="height: 272px; width: 272px">

								<!--If an image is present, render it-->
								<div v-if="form.profilePicFileURL && !tempImage"
									 class="d-flex flex-column align-center">

									<!--Image-->
									<v-img :src="form.profilePicFileURL"
										   class="rounded-lg" cover height="256" width="256"/>

									<!--Upload button-->
									<div v-if="formMode === 'Edit'"
										 style="position: absolute; z-index: 9; margin-top: 200px">

										<photoupload class="mr-n4"
													 allowed-types="image/*"
													 :doc-link="{collection: 'users', documentId: form.id}"
													 folder="users-profile-pictures"/>

									</div>

								</div>

								<!--If a tempImage (upload preview) is present, render it-->
								<div v-else-if="tempImage"
									 class="d-flex flex-column align-center">

									<!--Image-->
									<v-img :src="tempImage"
										   class="rounded" cover height="256" width="256"/>

									<!--Upload button-->
									<div style="position: absolute; z-index: 9; margin-top: 200px">

										<photoupload class="mr-n4"
													 allowed-types="image/*"
													 :doc-link="{collection: 'users', documentId: form.id}"
													 folder="users-profile-pictures"/>

									</div>

								</div>

								<!--If neither an image or tempImage is present, render an icon-->
								<div v-else class="d-flex flex-column align-center" style="width: 272px">

									<!--Image-->
									<v-icon class="icons8-customer" size="256"/>

									<!--Upload button-->
									<div v-if="formMode === 'New' || formMode === 'Edit'"
										 style="position: absolute; z-index: 9; margin-top: 200px">

										<photoupload class="mr-n4"
													 style="width: 100%"
													 allowed-types="image/*"
													 :doc-link="{collection: 'users', documentId: form.id}"
													 folder="users-profile-pictures"/>

									</div>

								</div>

							</v-col>

							<!-- User details -->
							<v-col :class="$vuetify.breakpoint.width >= 600 && 'flex-grow-1'"
								   :cols="$vuetify.breakpoint.width < 600 && 12">

								<!--Name-->
								<app-input input-type="textInput"
										   :class="$vuetify.breakpoint.width < 600 && 'mt-4'"
										   :clearable="false"
										   :error="errors.userName"
										   :error-messages="errors.userNameErrorMessage"
										   :is-form-read-only="formReadOnly"
										   label="Full Name"
										   v-model.trim="form.userName"/>

								<!--Position-->
								<app-input input-type="textInput"
										   class="mt-4"
										   :error="errors.userPosition"
										   :error-messages="errors.userPositionErrorMessage"
										   :is-form-read-only="formReadOnly"
										   label="Job Title"
										   v-model.trim="form.userPosition"/>

								<!--Email-->
								<app-input
									v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) && form.userCanUsePersonalDetails"
									input-type="textInput"
									class="mt-4"
									:error="errors.userEmail"
									:error-messages="errors.userEmailErrorMessage"
									:is-form-read-only="formReadOnly"
									label="Email"
									v-model.trim="form.userEmail"/>
								<app-input v-else
										   input-type="textInput"
										   append-icon="icons8-invisible"
										   class="mt-4"
										   :disabled="true"
										   label="User's email hidden"/>

								<!--Telephone-->
								<app-input
									v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) && form.userCanUsePersonalDetails"
									input-type="textInput"
									class="mt-4"
									:error="errors.userTelephone"
									:error-messages="errors.userTelephoneErrorMessage"
									:is-form-read-only="formReadOnly"
									label="Telephone"
									v-model="form.userTelephone"/>
								<app-input v-else
										   input-type="textInput"
										   append-icon="icons8-invisible"
										   class="mt-4"
										   :disabled="true"
										   label="User's Telephone hidden"/>

							</v-col>

						</v-row>

						<!-- Biography -->
						<v-col cols="12" :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'">
							<app-input input-type="textArea"
									   counter="360"
									   :is-form-read-only="formReadOnly"
									   label="Biography"
									   max-length="360"
									   v-model.trim="form.userBio"/>
						</v-col>

						<!--Consent | Access & Abilities | Configuration | QR Code-->
						<!--Only visible if the viewer is Senior Manager or above, and the user has consented to having their details displayed-->
						<v-row
							v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel)"
							no-gutters>

							<!--Consent to Displaying of Details-->
							<v-checkbox class="d-flex align-start"
										color="primary"
										hide-details
										:disabled="formReadOnly"
										label="I consent to my email address and telephone number being displayed in-app so I can be contacted if necessary"
										v-model="form.userCanUsePersonalDetails"/>

							<!--Access & Abilities | Action Responsibilities-->
							<v-col class="mt-8" cols="12">
								<app-text category="text-medium" class="darkgrey--text">
									Access, Abilities and Action Responsibilities
								</app-text>
								<v-divider class="mt-2"/>
							</v-col>
							<v-col cols="12" xs="12" sm="6">
								<app-input input-type="select"
										   :chips="true"
										   class="mt-4"
										   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
										   :deletable-chips="true"
										   :disabled="!(['Staff-Admin', 'Staff-Director'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Users: Can Assign Access & Abilities'))"
										   :is-form-read-only="formReadOnly"
										   :items="userAccessAbilitiesOptionsData"
										   label="Access and Abilities"
										   :multiple="true"
										   :small-chips="true"
										   v-model="form.userAccessAndAbilities"/>
							</v-col>
							<v-col cols="12" xs="12" sm="6">
								<app-input input-type="select"
										   class="mt-4"
										   :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
										   :deletable-chips="true"
										   :is-form-read-only="formReadOnly"
										   :items="['Teamship Reports']"
										   label="Responsibilities"
										   :multiple="true"
										   :small-chips="true"
										   v-model="form.userObservationResponsibilities"/>
							</v-col>

							<!-- Configuration (User Type | User Role | User Status) -->
							<v-col class="mt-8" cols="12">
								<app-text category="text-medium" class="darkgrey--text">Configuration</app-text>
								<v-divider class="mt-2"/>
							</v-col>

							<!--User type-->
							<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
								   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
								<app-input input-type="select"
										   :error="errors.userType"
										   :error-messages="errors.userTypeErrorMessage"
										   :is-form-read-only="formReadOnly"
										   :items="userTypesOptionsData"
										   label="User type"
										   v-model="form.userType">
								</app-input>
							</v-col>

							<!--User role-->
							<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
								   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
								<app-input input-type="select"
										   :error="errors.userRole"
										   :error-messages="errors.userRoleErrorMessage"
										   :is-form-read-only="formReadOnly"
										   :items="computedUserRoles"
										   label="User Role"
										   v-model="form.userRole">
								</app-input>
							</v-col>

							<!--User category-->
							<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
								   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
								<app-input input-type="select"
										   :error="errors.userStatus"
										   :error-messages="errors.userStatusErrorMessage"
										   :is-form-read-only="formReadOnly"
										   :items="computedUserCategories"
										   label="User Category"
										   :multiple="true"
										   v-model="form.userCategories">
								</app-input>
							</v-col>

							<!--User status-->
							<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
								   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
								<app-input input-type="select"
										   :error="errors.userStatus"
										   :error-messages="errors.userStatusErrorMessage"
										   :is-form-read-only="formReadOnly"
										   :items="userStatusOptionsData"
										   label="User Status"
										   v-model="form.userStatus">
								</app-input>
							</v-col>

							<!--Non-Tech-User (NTU)-->
							<v-col v-if="form.userLevel !== 'Visitor-User'"
								   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
								   :class="$vuetify.breakpoint.width < 600 ? '' : ''">
								<v-checkbox class="d-flex align-start"
											color="primary"
											hide-details
											:disabled="formReadOnly"
											label="This is a Non-Tech-User, and will have limited functionality within the app."
											v-model="form.userIsNtu"/>
							</v-col>

							<!--Visitor Type-->
							<v-col v-if="form.userLevel === 'Visitor-User'"
								   :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
								   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'">
								<app-input input-type="select"
										   :is-form-read-only="formReadOnly"
										   :items="['Regular', 'Temporary']"
										   label="Visitor Type"
										   v-model="form.userVisitorType">
								</app-input>
							</v-col>
							<v-col v-if="form.userLevel === 'Visitor-User'"
								   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
								   class="d-flex align-center mt-1">
								<v-icon class="icons8-high-importance mr-2" color="red" size="24"/>
								<app-text category="text-small" class="grey--text">
									Temporary Visitor's accounts will be deleted after 48 hours
								</app-text>
							</v-col>

							<!--QR Code-->
							<v-col class="mt-8" cols="12">
								<app-text category="text-medium" class="darkgrey--text">QR Code</app-text>
								<app-text category="text-small" class="grey--text">
									This QR Code is unique to this user and can be used to Reverse-SWAPP
								</app-text>
								<v-divider class="mt-2"/>
							</v-col>
							<v-col cols="12" class="d-flex justify-center">

								<div class="ma-4">

									<qrcode-vue class="white rounded-lg pa-8"
												level="H"
												ref="qrcode"
												size="256"
												style="outline: 1px solid grey"
												:value="form.id"/>

								</div>

							</v-col>

						</v-row>

						<!--Save-->
						<v-col v-if="formMode === 'Edit' || formMode === 'New'"
							   cols="12" class="d-flex justify-end mt-4">
							<app-btn @click.native="validateForm"
									 color="success"
									 icon="icons8-save"
									 label="Save"/>
						</v-col>

					</v-row>

					<!--Created and Modified info -->
					<div v-if="formMode !== 'New'" class="pa-4">

						<v-divider/>

						<app-text category="text-small-bold" class="grey--text mt-4">
							Created:
							<span class="font-weight-regular">
								{{ form.createdUserData && form.createdUserData.userName || 'System' }}
								-
								{{ MIX_formatDateTime(form.createdDateTime, "x", "DD-MMM-YYYY") }}
							</span>
							|
							Modified:
							<span class="font-weight-regular">
								{{ form.modifiedUserData.userName }}
								-
								{{ MIX_formatDateTime(form.modifiedDateTime, "x", "DD-MMM-YYYY") }}
							</span>
						</app-text>

					</div>

				</v-tab-item>

				<!--Location History tab content-->
				<v-tab-item value="location" class="pa-0">

					<!--Location History-->
					<v-row class="pa-4" no-gutters>

						<!--Instructional text-->
						<v-col cols="12">

							<!--User is currently SWAPPed [in/out], message-->
							<app-text category="text-default">
								<span class="font-weight-bold">{{ form.userName }}</span>
								is currently SWAPPed
								<span class="font-weight-bold">{{ form.swappStatus === 1 ? 'In' : 'Out' }}</span>
							</app-text>

							<!--SWAPP button-->
							<app-btn v-if="!modifySwappStatus"
									 @click.native="swappUser()"
									 :block="$vuetify.breakpoint.width < 600"
									 class="mt-4"
									 color="primary"
									 icon="icons8-qr-code"
									 :label="`Swapp ${ form.userName.split(' ')[0] } ${ form.swappStatus === 1 ? 'Out' : 'In' }`"/>

							<!--Cancel button-->
							<app-btn v-if="modifySwappStatus"
									 @click.native="swappUser()"
									 :block="$vuetify.breakpoint.width < 600"
									 class="my-4"
									 color="grey"
									 icon="icons8-cancel"
									 label="Cancel"/>

						</v-col>

						<!--Form-->
						<v-row v-if="modifySwappStatus" no-gutters>

							<!--Site-->
							<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
								   cols="12" xs="12" sm="6">
								<v-autocomplete @change="selectSiteLocation()"
												background-color="white"
												class="rounded-lg"
												filled
												flat
												hide-details
												:items="availableSites"
												item-text="siteName"
												:label="$t('fields.lastSite')"
												return-object
												outlined
												v-model="selectedSite"/>

							</v-col>

							<!--Last location-->
							<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
								   cols="12" xs="12" sm="6">
								<v-autocomplete
									@change="selectLocation()"
									background-color="white"
									class="rounded-lg"
									filled
									flat
									hide-details
									:items="availableLocations"
									item-text="locationName"
									:label="$t('fields.lastLocation')"
									no-data-text="No Locations Found"
									outlined
									return-object
									v-model="selectedLocation"/>

							</v-col>

							<!--Update SWAPP status button-->
							<v-col class="d-flex mt-4">

								<v-spacer/>

								<!--Update SWAPP status-->
								<app-btn v-if="modifySwappStatus"
										 @click.native="updateSwappStatus()"
										 :block="$vuetify.breakpoint.width < 600"
										 color="success"
										 :disabled="selectedLocation === null"
										 icon="icons8-qr-code"
										 :label="`Swapp ${ form.userName.split(' ')[0] } ${ form.swappStatus === 1 ? 'Out' : 'In' }`"/>

							</v-col>

						</v-row>

					</v-row>

					<!--Table - Render a table displaying a user's location history-->
					<v-col cols="12">

						<!--Title-->
						<app-text category="text-medium" class="darkgrey--text">
							Location History
						</app-text>

						<v-divider class="mt-2"/>

						<!--Table-->
						<v-data-table :headers="locationsHistoryHeaders"
									  :items="filteredLocations"
									  :items-per-page="5"
									  class="rounded-lg mt-4">

							<!--Render SWAPP status-->
							<template v-slot:[`item.swappStatus`]="{ item }">
								<v-chip class="success" small v-if="item.swappStatus === 1">IN</v-chip>
								<v-chip class="lightgrey" small v-else>OUT</v-chip>
							</template>

							<!--Render SWAPP site-->
							<template v-slot:[`item.site.siteName`]="{ item }">
								<app-text category="text-default">
									{{ MIX_capitalizeText(item.site.siteName) }}
								</app-text>
							</template>

							<!--Render SWAPP location-->
							<template v-slot:[`item.location.locationName`]="{ item }">
								<app-text category="text-default">
									{{ MIX_capitalizeText(item.location.locationName) }}
								</app-text>
							</template>

							<!--Render SWAPP method-->
							<template v-slot:[`item.swappMethod`]="{ item }">
								<!--<app-text category="text-default" class="justify-center">{{ MIX_capitalizeText(item.swappMethod) }}</app-text>-->
								<app-text category="text-default" class="justify-center">
									{{ item.swappMethod }}
								</app-text>
							</template>

							<!--Render SWAPP date-->
							<template v-slot:[`item.createdDateTime`]="{ item }">
								<app-text category="text-default" class="justify-center">
									{{ MIX_formatDateTime(item.createdDateTime, "x", "ddd Do MMM YYYY @ HH:mm") }}
								</app-text>
							</template>

						</v-data-table>

					</v-col>

				</v-tab-item>

				<!--Schedule tab content-->
				<v-tab-item value="schedule" class="pa-0">
					<user-schedule :user="form"/>
				</v-tab-item>

			</v-tabs-items>

		</div>

	</div>

</template>

<script>
import {mapActions, mapGetters} from "vuex"
import DatePicker from "./commonComponents/datePicker.vue"
import UserSchedule from "../views/userSchedule/userSchedule";
import QrcodeVue from 'qrcode.vue'

export default {

	name: "StaffDirectory",

	components: {QrcodeVue, DatePicker, UserSchedule},

	data: () => ({
		collection: "users",
		collectionTitle: "Staff Directory",
		collectionItem: "user",
		collectionItemTitle: "Staff Directory",
		tabs: "overview",

		// * FORM RELATED
		errors: {
			userEmail: false,
			userEmailErrorMessage: '',
			userName: false,
			userNameErrorMessage: '',
			userPosition: false,
			userPositionErrorMessage: '',
			userRole: false,
			userRoleErrorMessage: '',
			userTelephone: false,
			userTelephoneErrorMessage: '',
		},
		formMode: "",
		formReadOnly: true,
		formBackground: "grey lighten-3",
		form: {
			authId: null,
			id: '',
			lastLocation: {
				locationId: '',
				locationName: '',
			},
			lastSite: {
				siteId: '',
				siteName: '',
			},
			privacyPolicyConsent: false,
			profilePicFileURL: null,
			swappMethod: null,
			swappStatus: 0,
			userAccessAndAbilities: [],
			userBio: '',
			userCategories: [],
			userCanUsePersonalDetails: false,
			userCompany: '',
			userEmail: '',
			userIsNtu: false,
			userLevel: '',
			userName: '',
			userObservationResponsibilities: [],
			userPosition: '',
			userRole: '',
			userStatus: '',
			userTelephone: '',
			userType: '',
			userVisitorType: '',

			// Added from admin
			createdDateTime: '',
			createdUserData: {},
			modifiedDateTime: '',
			modifiedUserData: {},
		},
		// * PROFILE PICTURE
		types: "image/*",
		storagePathProfilePic: "users-profile-pictures",
		photoUploadResult: {},
		tempImage: '',

		//check if user was created in admin dashboard
		userInLocalStorage: false,
		userInLocalStorageId: null,

		filterStateItems: ["CURRENT", "ARCHIVED"],

		// * SWAPP STATUS RELATED
		modifySwappStatus: false, // modify swapp status
		availableLocations: [], // locations from the db
		allowLocationSelect: false, // only allow location select once a site is selected
		availableSites: [], // sites from the db
		selectedSite: null, // select site from options
		selectedLocation: null, // select location from options,
		swappDirection: 0,
		locationsHistory: [],
		sitesData: [],
		isPageLoading: true,
	}),

	computed: {
		...mapGetters({
			GET_openItem: "GET_openStaffDirectory",
			GET_panelLayout: "GET_panelLayout", // Panel Layout used for Layout Structure
			GET_openStaffDirectory: "GET_openStaffDirectory",
			GET_confirmBoxResult: "confirmBox_store/GET_confirmBoxResult", // Get Confirm Box Result
			GET_areas: "GET_areas", // Get Areas
			GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
			GET_lookupValues: 'GET_lookupValues',
			GET_currentUser: 'GET_currentUser',
		}),

		// LOCATIONS HISTORY HEADERS
		filteredLocations() {
			const t = this

			return t.MIX_sortByCreatedDateTime(t.locationsHistory)
		},

		locationsHistoryHeaders() {
			return [
				{text: "Status", value: "swappStatus", align: "center", sortable: false, hidden: false, hide: false},
				{text: "Site", value: "site.siteName", align: "left", sortable: false, hidden: false, hide: false},
				{
					text: "Location",
					value: "location.locationName",
					align: "left",
					sortable: false,
					hidden: false,
					hide: false
				},
				{text: "Method", value: "swappMethod", align: "center", sortable: false, hidden: false, hide: false},
				{
					text: "Swapped at",
					value: "createdDateTime",
					align: "center",
					sortable: false,
					hidden: false,
					hide: false
				},
			];
		},

		/**
		 * Computed User Roles
		 *
		 * Return the correct user roles for the specified user type.
		 * @returns {Array} user roles as strings
		 */
		computedUserRoles() {
			const t = this
			let roles = []
			const type = t.form.userType

			if (type === 'Staff') {
				roles = ['Admin', 'Director', 'Senior-Manager', 'Project-Manager', 'User-Site', 'User-Office', 'Operative-Groundworker', 'Operative-Plant']
			} else if (type === 'Visitor') {
				roles = ['User']
				t.form.userRole = 'User'
			} else if (type === 'Developer') {
				roles = ['User']
				t.form.userRole = 'User'
			}

			return roles
		},

		/**
		 * Computed User Categories
		 *
		 * Return the correct user categories for the specified user level.
		 *
		 * @returns {Array} user categories as strings
		 */
		computedUserCategories() {
			const t = this
			const USER_LEVEL = t.form.userLevel
			const CATEGORY_OPTIONS_DATA = t.userCategoriesOptionsData[0]
			let categoryOptions = []

			if (USER_LEVEL === 'Staff-User-Site') categoryOptions = CATEGORY_OPTIONS_DATA.staffUserSite
			if (USER_LEVEL === 'Staff-User-Office') categoryOptions = CATEGORY_OPTIONS_DATA.staffUserOffice
			if (USER_LEVEL === 'Staff-Operative-Groundworker') categoryOptions = CATEGORY_OPTIONS_DATA.staffOperativeGroundworker
			if (USER_LEVEL === 'Staff-Operative-Plant') categoryOptions = CATEGORY_OPTIONS_DATA.staffOperativePlant

			return categoryOptions
		},

	},

	methods: {
		...mapActions({ACT_openItem: "ACT_openStaffDirectory", ACT_openStaffDirectory: "ACT_openStaffDirectory"}),

		/**
		 * Check User In Local Storage
		 *
		 * Check if the user is in the local storage.
		 *
		 * @param email - user email
		 * @returns {Promise<unknown>}
		 */
		async checkUserInLocalStorage(email) {
			const t = this

			return new Promise(function (resolve, reject) {
				t.$firebase.db
					.collection("users")
					.where("userEmail", "==", email)
					.get()
					.then(function (querySnapshot) {
						if (querySnapshot.docs.length === 0) {
							t.userInLocalStorage = false
							return resolve(t.userInLocalStorage)
						} else {
							t.userInLocalStorage = true
							t.userInLocalStorageId = querySnapshot.docs[0].id
							return resolve(t.userInLocalStorage)
						}
					})
					.catch(function (error) {
						return reject(error)
					})
			})
		},

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		deleteItem(id) {
			const t = this
			const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

			t.MIX_confirmBox({
				show: true,
				collection: t.collection,
				id: id,
				title: `Delete Staff Member`,
				description: `Are you sure you want to delete this staff member: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + "Name"]}</strong>?</div>`,
				width,
				trueButtonText: "Yes",
				trueButtonValue: "YES",
				falseButtonText: "No",
				falseButtonValue: "NO",
			})
		},

		editItem() {
			const t = this

			if (t.formMode === "View") {
				t.formMode = "Edit"
				t.formReadOnly = false
				t.formBackground = "white"
			} else if (t.formMode === "Edit") {
				t.formMode = "View"
				t.formReadOnly = true
				t.formBackground = "grey lighten-3"
				t.tempImage = ''
				t.removeUploadedDocument()
				t.refreshItemView()
			}

		},

		async getAvailableSites() {
			const t = this

			const sitesResult = await t.MIX_readDocuments("sites")
			const externalSitesResult = await t.MIX_readDocuments("externalSites")

			if (sitesResult.code === 1 && externalSitesResult.code === 1) {
				t.mergeSitesData(sitesResult.data, externalSitesResult.data)
			} else {
				t.availableSites = []
			}
		},

		async getAvailableLocations() {
			const t = this

			const locationsResult = await t.MIX_readDocumentsByKeyValue("locations", "site.id", t.selectedSite.id)
			if (locationsResult.code === 1) {
				t.availableLocations = locationsResult.data
			} else {
				t.availableLocations = []
			}
		},

		getLocationsHistory() {
			const t = this

			t.$firebase.db
				.collection("swappLogs")
				.where("swappedUser", "==", t.form.id)
				.onSnapshot(function (querySnapshot) {
					t.locationsHistory = []
					querySnapshot.forEach(function (doc) {
						var document = doc.data()
						document.id = doc.id
						if (!document.hasOwnProperty("delete")) {
							t.locationsHistory.push(document)
						} else {
							// Do not Show Notice
						}
					})
				})
		},

		/**
		 * Get Sites Collection
		 *
		 * Clear the table data to avoid duplications.
		 * Fetch all the data from the Sites collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted to the sitesData array.
		 *
		 * @returns {Promise<void>}
		 */
		async getSitesCollectionData() {
			const t = this

			const collection = await t.$firebase.db.collection('sites')
			collection.onSnapshot(snapshot => {

				// Clear the users data to avoid duplications
				t.sitesData = []

				snapshot.forEach(doc => {
					const document = doc.data()
					document.id = doc.id

					// Only add documents that aren't marked as deleted
					if (!document.hasOwnProperty('delete')) {
						t.sitesData.push(document)
					}

				})
			})
		},

		/**
		 * Merge Sites Data
		 *
		 * Merge both the Sites and External Sites and sort them in to alphabetical order.
		 *
		 * @returns {Array} alphabetically sorted sites array
		 */
		mergeSitesData(sites, externalSites) {
			const t = this

			let combinedSites = [...sites, ...externalSites]

			// Ensure each site has a siteName
			combinedSites.forEach(site => {
				site.projectTitle ? site.siteName = site.projectTitle : site.siteName
			})

			// Sort sites alphabetically
			combinedSites.sort((a, b) => {
				return a.siteName > b.siteName ? 1 : -1
			})

			// Ensure each site has a location
			combinedSites = combinedSites.filter(site => {
				return site.locations.length
			})

			t.availableSites = combinedSites

		},

		async openItem() {
			const t = this

			const openItem = await t.GET_openItem

			switch (openItem.process) {
				case "New":
					t.formMode = "New"
					t.formReadOnly = false
					t.formBackground = "white"
					break
				case "View":
					const item1 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
					t.form = item1.data
					t.formMode = "View"
					t.formReadOnly = true
					t.formBackground = "grey lighten-3"
					break
				case "Edit":
					const item2 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
					t.form = item2.data
					t.formMode = "Edit"
					t.formReadOnly = false
					t.formBackground = "white"
					break
			}
		},

		async refreshItemView(id) {
			const t = this

			// If no ID has been passed through, use the form ID
			if (!id) id = t.form.id

			const itemResult = await t.MIX_readDocumentById('users', id)
			const payload = {}
			payload.process = "View"
			payload.popup = false
			payload.data = itemResult.data
			t.ACT_openItem(payload)
			await t.openItem()
		},

		removeUploadedDocument() {
			this.$store.commit("upload_file_store/setFile", null)
		},

		async saveItem() {
			const t = this

			// If form is NEW
			if (t.formMode === "New") {

				// Check if user already exists
				const userExists = await this.checkUserInLocalStorage(t.form.userEmail)

				if (userExists !== true) {
					const createDocumentResult = await t.MIX_createDocument(t.collection, t.form)

					if (createDocumentResult.code === 1) {
						t.MIX_alert(1, `User successfully created`, null, null)
						t.formMode = "View"
						t.formReadOnly = true
						t.formBackground = "grey lighten-3"

						// Add uploaded profile image
						await t.uploadProfileImage()

						await t.refreshItemView(createDocumentResult.data.id)

						t.removeUploadedDocument()

					} else {
						t.MIX_alert(-1, `Error creating user`, null, null)
					}
				} else {
					t.MIX_alert(-1, `This user already exists`, null, null)
				}
			}

			// If form is EDIT
			else if (t.formMode === "Edit") {
				const updateDocumentResult = await this.MIX_updateDocument(t.collection, t.form)
				if (updateDocumentResult.code === 1) {
					t.MIX_alert(1, `User successfully updated`, updateDocumentResult.data, null)
					t.formMode = "View"
					t.formReadOnly = true
					t.formBackground = "grey lighten-3"

					// Add uploaded profile image
					await t.uploadProfileImage()

					await t.refreshItemView()

				} else {
					t.MIX_alert(-1, "Error updating user", null, updateDocumentResult.error)
				}
			}
		},

		selectLocation() {
			const t = this

			if (t.selectedLocation != null) {
				t.form.lastLocation.locationId = t.selectedLocation.id
				t.form.lastLocation.locationName = t.selectedLocation.locationName
			}
		},

		selectSiteLocation() {
			const t = this

			if (t.selectedSite != null) {
				t.form.lastSite.siteId = t.selectedSite.id
				t.form.lastSite.siteName = t.selectedSite.siteName
			}
			t.getAvailableLocations()
			t.allowLocationSelect = true
		},

		/**
		 * Set User Level
		 *
		 * Configure the user's user level from their user type and user role.
		 */
		setUserLevel() {
			const t = this

			t.form.userLevel = t.form.userType + '-' + t.form.userRole
		},

		swappUser() {
			const t = this

			t.getAvailableSites()
			t.modifySwappStatus = !t.modifySwappStatus
		},

		/**
		 * Upload Profile Image
		 *
		 * Upon successful saving/updating of a User document, also save the profile image path.
		 *
		 * @returns {Promise<void>}
		 */
		async uploadProfileImage() {
			const t = this

			if (t.photoUploadResult !== {}) {

				// Save to the document with: collection | user id | image path
				const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
					'users', t.photoUploadResult.docLink, {profilePicFileURL: t.photoUploadResult.fileURL})

				// Render confirmation box
				if (updatePhotosResult.code === 1) {
					this.MIX_alert(1, 'User successfully updated', updatePhotosResult.data, null)
				} else {
					this.MIX_alert(-1, 'Error updating user', null, updatePhotosResult.error)
				}
			}
		},

		async updateSwappStatus() {
			const t = this

			t.form.swappMethod = "Admin"
			if (t.form.swappStatus === 0) {
				t.swappDirection = 1
				t.form.swappStatus = 1
			} else {
				t.swappDirection = 0
				t.form.swappStatus = 0
			}

			const updateDocumentResult = await t.MIX_updateDocument("users", t.form)

			if (updateDocumentResult.code === 1) {
				t.modifySwappStatus = false
				t.MIX_alert(1, "Swapp Status Updated", updateDocumentResult.data, null)
				const user = await t.MIX_readDocumentById("users", t.form.id)
				t.ACT_openStaffDirectory(user.data)
			} else {
				t.MIX_alert(-1, "ERROR - Could not update Status", null, updateDocumentResult.error)
			}
		},

		/**
		 * Validate
		 *
		 * Validates the required fields for presence only.
		 * If any of the fields are missing mark them in an errors object.
		 * When there are no errors left, save the record.
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Name
			if (!t.form.userName.trim()) {
				t.errors.userName = true
				t.errors.userNameErrorMessage = 'Name required'
			}

			// Position
			if (!t.form.userPosition.trim()) {
				t.errors.userPosition = true
				t.errors.userPositionErrorMessage = 'Position required'
			}

			// Email
			const USER_EMAIL = t.form.userEmail.trim()
			if (!USER_EMAIL) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email required'
			}
			// Must be a (simple) valid email address
			else if (!t.MIX_isEmailValid(USER_EMAIL)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email not in the expected format'
			}

			// Telephone
			const USER_TELEPHONE = t.form.userTelephone.trim()
			if (!USER_TELEPHONE) {
				t.errors.userTelephone = true
				t.errors.userTelephoneErrorMessage = 'Telephone Number required'
			} else if (!t.MIX_isTelephoneNumberValid(USER_TELEPHONE)) {
				t.errors.userTelephone = true
				t.errors.userTelephoneErrorMessage = 'Telephone Number not in the expected format'
			}

			// User Type
			if (!t.form.userType) {
				t.errors.userType = true
				t.errors.userTypeErrorMessage = 'A User Type is required'
			}

			// User Role
			if (!t.form.userRole || t.form.userRole === 'User') {
				t.errors.userRole = true
				t.errors.userRoleErrorMessage = 'A User Role is required'
			}

			// User Status
			if (!t.form.userStatus) {
				t.errors.userStatus = true
				t.errors.userStatusErrorMessage = 'A User Status is required'
			}

			// Check if there are any errors left
			if (!Object.values(t.errors).includes(true)) {
				t.setUserLevel()
				t.saveItem()
			}
		},

	},

	watch: {

		GET_confirmBoxResult: {
			handler: async function () {
				const t = this

				if (t.GET_confirmBoxResult.data.collection === t.collection) {
					if (t.GET_confirmBoxResult.data.result === "YES") {
						var deleteResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id)
						if (deleteResult.code === 1) {
							t.MIX_alert(1, `${t.collectionItemTitle} and Training Deleted`, deleteResult.data, null)
							t.ACT_panelLayout({leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: ""})
						} else {
							t.MIX_alert(-1, `Error Deleting ${t.collectionItemTitle}`, null, deleteResult.error)
						}
					}
				} else if (t.GET_confirmBoxResult.data.collection === "training") {
					if (t.GET_confirmBoxResult.data.result === "YES") {
						const updateResult = await t.MIX_updateDocumentFieldsById("users", t.form.id, {trainingCurrent: t.form.trainingCurrent})
						if (updateResult.code === 1) {
							t.MIX_alert(1, `Training deleted`, updateResult.data, null)
							t.refreshItemView()
						} else {
							t.MIX_alert(-1, "Error deleteting training", null, updateResult.error)
						}
					}
				} else {
					// Do nothing
				}
			},
			deep: true,
		},

		/**
		 * Photo Upload Result
		 *
		 * On upload of a profile picture, add its storage path to the photoUploadResult variable.
		 */
		GET_photoUploadResult: {
			handler: async function () {
				const t = this

				t.photoUploadResult = t.GET_photoUploadResult
				t.tempImage = t.photoUploadResult.fileURL

			}, deep: true
		},
	},

	async mounted() {
		const t = this

		// t.removeUploadedDocument()

		const openItem = await t.GET_openItem
		t.formMode = openItem.process

		if (openItem.process === "New") {
			t.formReadOnly = false
			t.formBackground = "white"
		} else {
			t.form = openItem.data
			t.formReadOnly = true
			t.formBackground = "grey lighten-3"
		}
		if (openItem.popup === false) {

		}

		t.getLocationsHistory()

		// Get collections data
		await t.getSitesCollectionData()

		t.isPageLoading = false
	},

};
</script>

<style scoped>
.disabled {
	pointer-events: none;
	cursor: not-allowed;
}

.profileImage-container {
	border: 1px solid lightgray !important;
}

.expandtofill {
	display: block !important;
	width: 100% !important;
	height: 100% !important;
	/* background: #ffffff !important; */
}
</style>
