// <!--
// New Date Picker

// Renders a Date Picker as a dialog box, with the activator being a text input which open the time picker pop up.
// When a new date is selected, the date is emitted back to the calling component
// as 'emitDate'.
// The displayed date on opening a document is passed in as the 'date' prop.
// To set the minDate/maxDate examples: minDate="[100, 'years', 'past'], minDate="[20, 'days', 'future']
// -->
<template>
    <v-dialog ref="dialog"
              v-model="dialog"
              width="300">

        <!--Activator-->
        <template v-slot:activator="{ on, attrs }">

            <v-text-field :append-icon="appendIcon ? appendIcon : 'icons8-calendar'"
                          :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                          class="rounded-lg"
						  :clearable="clearable"
						  @click:clear="[newDate = '', $emit('emitDate', newDate)]"
                          :disabled="disabled || isFormReadOnly"
                          :error="error"
                          :error-messages="errorMessages"
                          filled
                          flat
                          hide-details="auto"
                          :label="label"
                          onkeydown="return false"
                          :outlined="!isFormReadOnly"
                          readonly
                          :value="formatDate"
                          v-bind="attrs"
                          v-on="on"/>

        </template>

        <!--Body-->
        <v-date-picker @change="$emit('emitDate', newDate)"
                       color="primary"
                       first-day-of-week="1"
                       :min="calculateMinDate"
                       :max="calculateMaxDate"
                       scrollable
                       showCurrent
                       v-model="newDate">

            <!--Action buttons-->
            <v-row class="d-flex mb-2" no-gutters>

                <!--Cancel-->
                <app-btn @click.native="dialog = false"
                         color="transparent"
                         label="Cancel"/>

                <v-spacer/>

                <!--Ok-->
                <app-btn @click.native="handleDate"
                         color="success"
                         label="Ok"/>

            </v-row>

        </v-date-picker>

    </v-dialog>
</template>

<script>
export default {

    name: "DatePicker",

    props: [
        'appendIcon',
        'backgroundColor',
		'clearable',
        'date',
        'disabled',
        'error',
        'errorMessages',
        'isFormReadOnly',
        'label',
        'maxDate',
        'minDate'
    ],

    data: () => ({

        dialog: false,
        newDate: '',

    }),

    computed: {

        formatDate() {
            const t = this

            if (t.newDate) {
                return t.$moment(t.newDate).format('ddd Do MMMM YYYY')
            } else if (t.date) {
                return t.$moment(Number(t.date)).format('ddd Do MMMM YYYY')
            }

        },

        // Calculate the minimum date a user can pick for the datepicker based on minDate prop
        calculateMinDate() {
            const t = this
            let minDate = this.$moment(new Date(), 'x').format('YYYY-MM-DD')
            let value = t.$props.minDate[0]
            let uom = t.$props.minDate[1]
            let tense = t.$props.minDate[2]

            // Past
            if (tense === 'past') {
                minDate = t.$moment(minDate).subtract(value, uom).format('YYYY-MM-DD')
            }

            // Future
            if (tense === 'future') {
                minDate = t.$moment(minDate).add(value, uom).format('YYYY-MM-DD')
            }

            return minDate
        },

        // Calculate the maximum date a user can pick for the datepicker based on maxDate prop
        calculateMaxDate() {
            const t = this
            let maxDate = this.$moment(new Date(), 'x').format('YYYY-MM-DD')
            let value = t.$props.maxDate[0]
            let uom = t.$props.maxDate[1]
            let tense = t.$props.maxDate[2]

            // Past
            if (tense === "past") {
                maxDate = t.$moment(maxDate).subtract(value, uom).format('YYYY-MM-DD')
            }

            // Future
            if (tense === "future") {
                maxDate = t.$moment(maxDate).add(value, uom).format('YYYY-MM-DD')
            }

            return maxDate
        },

    },

    methods: {

        handleDate() {
            const t = this

            // Save
            t.$refs.dialog.save(t.newDate)

            // Close the dialog
            t.dialog = false
        }
    },

}
</script>
