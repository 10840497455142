import Vue from 'vue';
import VueRouter from 'vue-router';

// Store
import store from "@/store.js";

// Main Routes
import Actions from "@/views/actions/actions.vue";
import AppSupport from "@/views/appSupport/appSupport.vue";
// import CovidAnswers from "@/views/covidAnswers/covidAnswers.vue";
import CompletedSites from "@/views/completedSites/completedSites.vue";
import Dashboard from "@/views/dashboards/dashboard.vue"
import FireRollCall from "@/views/fireRollCall/fireRollCall.vue"
import Home from '@/views/home/home.vue';
import Observations from "@/views/observations/observations.vue";
import MapPage from "@/views/mapPage/mapPage.vue"
import NewSites from "@/views/newSites/newSites.vue"
import NoticeBoards from "@/views/noticeBoards/noticeBoards.vue";
// import Questions from "@/views/questions/questions.vue";
import SiteDashboard from "@/views/dashboards/siteDashboard.vue"
import Sites from "@/views/sites/sites.vue";
import SitesInProgress from "@/views/sitesInProgress/sitesInProgress.vue";
import StaffDirectory from "@/views/staff/staff-directory.vue";
import Swapp from "@/views/swapp/swapp.vue";
import Unauthorised from "@/views/unauthorised/unauthorised.vue";
import UserProfile from "@/views/userProfile/userProfile.vue";
// import UserSchedule from "@/views/userSchedule/userSchedule.vue";

// Settings
import Settings from "@/views/settings/settings.vue";
import Lookups from "@/views/settings/lookups.vue";

// Auth Routes
import Register from "@/views/auth/register.vue";
import Login from "@/views/auth/login.vue";
import ResetPassword from "@/views/auth/reset-password.vue";
import Restricted from "@/views/auth/restricted.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Staff-User', 'Visitor-User'],
			userStatus: 'APPROVED'
		}
	},

	// Main Routes - Main Routes - Main Routes - Main Routes //

	{
		path: '/actions',
		name: 'Actions',
		component: Actions,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/appSupport',
		name: 'AppSupport',
		component: AppSupport,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Visitor-User'],
			userStatus: 'APPROVED'
		}
	},
	// {
	// 	path: '/covidAnswers',
	// 	name: 'CovidAnswers',
	// 	component: CovidAnswers,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },
	{
		path: '/completedSites',
		name: 'CompletedSites',
		component: CompletedSites,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/fireRollCall',
		name: 'FireRollCall',
		component: FireRollCall,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Site', 'Staff-User-Office', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/mapPage',
		name: 'MapPage',
		component: MapPage,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Visitor-User'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/newSites',
		name: 'NewSites',
		component: NewSites,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/observations',
		name: 'Observations',
		component: Observations,
		meta: {
			requiresAuth: true,
			userLevel: [],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/noticeBoards',
		name: 'NoticeBoards',
		component: NoticeBoards,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant'],
			userStatus: 'APPROVED'
		}
	},
	// {
	// 	path: '/questions',
	// 	name: 'Questions',
	// 	component: Questions,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },
	{
		path: '/siteDashboard',
		name: 'SiteDashboard',
		component: SiteDashboard,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/sites',
		name: 'Sites',
		component: Sites,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/sitesInProgress',
		name: 'SitesInProgress',
		component: SitesInProgress,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Staff-User', 'Visitor-User'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/staff-directory',
		name: 'StaffDirectory',
		component: StaffDirectory,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/unauthorised',
		name: 'Unauthorised',
		component: Unauthorised,
		meta: {
			requiresAuth: false,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Staff-User', 'Visitor-User'],
			userStatus: ['PENDING', 'REJECTED', 'SUSPENDED', 'ARCHIVED'],
			deleted: true
		}
	},
	{
		path: '/userProfile',
		name: 'UserProfile',
		component: UserProfile,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Visitor-User'],
			userStatus: 'APPROVED'
		}
	},
	// {
	// 	path: '/userSchedule',
	// 	name: 'UserSchedule',
	// 	component: UserSchedule,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['SA', 'SM', 'SU', 'DA'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },

	// Settings - Settings - Settings - Settings //
	{
		path: '/settings',
		component: Settings,
		meta: {
			requiresAuth: true,
			userLevel: ['DA']
		}
	},
	{
		path: '/settings/lookups',
		name: 'Lookups',
		component: Lookups,
		meta: {
			requiresAuth: true,
			userLevel: ['DA']
		}
	},

	// SWAPP - SWAPP - SWAPP - SWAPP //
	{
		path: '/swapp/:direction?',
		name: 'Swapp',
		component: Swapp,
		meta: {
			requiresAuth: true,
			userLevel: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Visitor-User'],
			userStatus: 'APPROVED'
		}
	},

	// *  AUTH ROUTES
	// ? register for normal user
	{path: "/register/:id?", name: "Register", component: Register},
	{path: "/login", name: "Login", component: Login},
	{path: "/restricted", name: "Restricted", component: Restricted},
	{path: "/reset-password", name: "ResetPassword", component: ResetPassword,},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    
    // Allow unauthorized and login routes without checks
    if (!requiresAuth || to.name === 'Unauthorised' || to.name === 'Login') {
        next();
        return;
    }

    let checkUser = store.getters.GET_currentUser;

		navigator.serviceWorker.register('/service-worker.js').then(reg => {
			// sometime later…
			reg.update();
		});

		// If the user is not logged in, redirect them to the login page
		if (!checkUser) {
			next("/login");
		}

		// If the user has been deleted, redirect them to the Suspended page
		else if (checkUser?.delete && !to.meta.deleted) {
			next({name: 'Unauthorised'})
		}

		// If the user's status is ARCHIVED/REJECTED/SUSPENDED/, redirect them to the Suspended page
		else if ((checkUser.userStatus === 'ARCHIVED' || checkUser.userStatus === 'REJECTED' || checkUser.userStatus === 'SUSPENDED') && !to.meta.userStatus.includes(checkUser.userStatus)) {
            next({name: 'Unauthorised'})
		}

		// If the user does not have access to the route, redirect them to the Home page
		else if (!to.meta.userLevel.includes(checkUser.userLevel)) {
			next({name: 'Home'})
		}

		// If the user's status is not APPROVED, redirect them to the Unauthorised page
		else if (!to.meta.userStatus.includes(checkUser.userStatus.toUpperCase()) && !to.meta.deleted) {
			next({name: 'Unauthorised'})
		}

		else {
			next();
		}
});

export default router;
