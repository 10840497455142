import Vue from 'vue';

const firebase = require('@/firebase/firebase-config.js'); // Import Firebase Config for Firebase Object Access
const moment = require('moment');
import {mapGetters} from 'vuex';

/*
    CRUD Promise Based Operations
    CREATE DOCUMENTS
    this.MIX_createDocument (collectionName, document)

    READ DOCUMENTS
    this.MIX_readDocumentById (collectionName, id)
    this.MIX_readDocuments (collectionName)
    TODO - Read Documents that Match a Where Clause

    UPDATE DOCUMENTS
    this.MIX_updateDocument (collectionName, document)
    this.MIX_updateDocumentFieldsById (collectionName, id, fields)
    TODO - Update Fields in Multiple Documents using Array of iDs

    DELETE DOCUMENTS
    this.MIX_deleteDocument (collectionName, id)
    TODO - Delete Multiple Documents using Array of Ids
    this.MIX_destroyDocument (collectionName, id)
*/


const mixin = {
	data: () => ({
		isUploading: false,
		uploadValue: 0,
	}),
	computed: {
		...mapGetters(['GET_currentUser', 'GET_currentUserData'])
	},
	methods: {
		// * DOCUMENTS
		// CREATE DOCUMENT
		MIX_createDocument(collectionName, document) {
			const t = this
			return new Promise(function (resolve) {

				document.createdUserData = t.GET_currentUserData
				document.createdDateTime = moment().format('x')
				document.modifiedUserData = t.GET_currentUserData
				document.modifiedDateTime = moment().format('x')

				if (collectionName) {
					if (!document?.id) {
						firebase.db.collection(collectionName).add(document)
							.then(document => resolve({
								code: 1,
								message: 'Document Written to Collection',
								data: {id: document.id},
								error: null
							}))
							.catch(error => resolve({
								code: -1,
								message: 'Error Writing Document to Collection',
								data: null,
								error: error
							}))
					} else {
						firebase.db.collection(collectionName).doc(document.id).set(document)
							.then(() => resolve({
								code: 1,
								message: 'Document Written to Collection',
								data: {id: document.id},
								error: null
							}))
							.catch((error) => resolve({
								code: -1,
								message: 'Error Writing Document to Collection',
								data: null,
								error: error
							}))
					}
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null})
				}
			})
		},

		MIX_createLookupTable: function (collectionName, document) {
			var t = this;
			return new Promise(function (resolve) {
				document = JSON.parse(JSON.stringify(document));
				/*
					TODO - Change to Current User
				*/
				// delete document.id;

				document.createdUserData = t.GET_currentUserData;
				document.createdDateTime = moment().format('x'); // add created timestamp to document
				document.modifiedUserData = t.GET_currentUserData;
				document.modifiedDateTime = moment().format('x'); // add created timestamp to document
				if (collectionName !== null) {
					if (document.id === undefined) {
						firebase.db.collection(collectionName).add(document)
							.then((document) => resolve({
								code: 1,
								message: 'Document Written to Collection',
								data: {id: document.id},
								error: null
							}))
							.catch((error) => resolve({
								code: -1,
								message: 'Error Writing Document to Collection',
								data: null,
								error: error
							}));
					} else {
						firebase.db.collection(collectionName).doc(document.id).set(document)
							.then(() => resolve({
								code: 1,
								message: 'Document Written to Collection',
								data: {id: document.id},
								error: null
							}))
							.catch((error) => resolve({
								code: -1,
								message: 'Error Writing Document to Collection',
								data: null,
								error: error
							}));
					}
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null});
				}
			})
		},

		// READ DOCUMENT BY ID FROM COLLECTION
		MIX_readDocumentById: function (collectionName, id) {
			return new Promise(function (resolve) {
				if ((collectionName !== null) && (id !== '')) {
					firebase.db.collection(collectionName).doc(id).get()
						.then(function (doc) {
							var document = {}
							document = doc.data();
							document.id = doc.id;
							resolve({code: 1, message: 'Document Read from Collection', data: document, error: null})
						})
						.catch((error) => resolve({
							code: -1,
							message: 'Error Reading Document from Collection',
							data: null,
							error: error
						}));
				} else {
					resolve({code: 0, message: 'Warning Collection or Id was not specified', data: null, error: null});
				}
			})
		},

		// READ DOCUMENTS FROM COLLECTION
		// NOTE: Excludes Documents with Delete Flag set to 1
		MIX_readDocuments: function (collectionName) {
			return new Promise(function (resolve) {
				if (collectionName !== null) {
					firebase.db.collection(collectionName).get()
						.then(function (querySnapshot) {
							var documents = [];
							querySnapshot.forEach(function (doc) {
								var document = {}
								document = doc.data();
								document.id = doc.id;
								// Exclude Documents with Delete Flag
								if (!document.hasOwnProperty('delete')) {
									documents.push(document);
								}
							})
							if (documents.length !== 0) {
								resolve({
									code: 1,
									message: 'Documents Read from Collection',
									data: documents,
									error: null
								})
							} else {
								resolve({
									code: 0,
									message: 'Warning No Documents could not be found in Collection',
									data: null,
									error: error
								});
							}

						})
						.catch((error) => resolve({
							code: -1,
							message: 'Error Reading Documents from Collection',
							data: null,
							error: error
						}));
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null});
				}
			})
		},

		// READ DOCUMENTS BY KEY VALUE FROM COLLECTION
		// NOTE: Excludes Documents with Delete Flag set to 1
		MIX_readDocumentsByKeyValue: function (collectionName, key, value) {
			return new Promise(function (resolve) {
				if (collectionName !== null) {
					firebase.db.collection(collectionName).where(key, "==", value).get()
						.then(function (querySnapshot) {
							var documents = [];
							querySnapshot.forEach(function (doc) {
								var document = {}
								document = doc.data();
								document.id = doc.id;
								// Exclude Documents with Delete Flag
								if (!document.hasOwnProperty('delete')) {
									documents.push(document);
								}
							})
							if (documents.length !== 0) {
								resolve({
									code: 1,
									message: 'Documents Read from Collection',
									data: documents,
									error: null
								})
							} else {
								resolve({
									code: 0,
									message: 'Warning No Documents could not be found in Collection',
									data: null,
									error: error
								});
							}

						})
						.catch((error) => {
							resolve({
								code: -1,
								message: 'Error Reading Documents from Collection',
								data: null,
								error: error
							})
						});
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null});
				}
			})
		},

		// READ DOCUMENTS BY KEY VALUE FROM COLLECTION
		// NOTE: Excludes Documents with Delete Flag set to 1
		MIX_readDocumentsWhere: function (collectionName, where) {
			return new Promise(function (resolve) {
				if (collectionName !== null) {
					var collection = firebase.db.collection(collectionName)
					for (var i = 0; i < where.length; i++) {
						var query = {field: where[i].key, operator: where[i].operator, value: where[i].value}
						collection = collection.where(query.field, query.operator, query.value)
					}
					collection.get()
						.then(function (querySnapshot) {
							var documents = [];
							querySnapshot.forEach(function (doc) {
								var document = {}
								document = doc.data();
								document.id = doc.id;
								// Exclude Documents with Delete Flag
								if (!document.hasOwnProperty('delete')) {
									documents.push(document);
								}
							})
							if (documents.length !== 0) {
								resolve({
									code: 1,
									message: 'Documents Read from Collection',
									data: documents,
									error: null
								})
							} else {
								resolve({
									code: 0,
									message: 'Warning No Documents could not be found in Collection',
									data: null,
									error: error
								});
							}

						})
						.catch((error) => resolve({
							code: -1,
							message: 'Error Reading Documents from Collection',
							data: null,
							error: error
						}));
//                    return resolve({ code: 0, message: 'xxxWarning Collection was not specified', data: null, error: null });
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null});
				}
			})
		},

		// READ DOCUMENTS CREATEDBY USER FROM COLLECTION
		// TODO NOT TEESTED
		// NOTE: Excludes Documents with Delete Flag set to 1
		MIX_readDocumentsCreatedBy: function (collectionName, userId) {
			return new Promise(function (resolve) {
				if (collectionName !== null) {
					firebase.db.collection(collectionName).where('userData.id', "==", userId).get()
						.then(function (querySnapshot) {
							var documents = [];
							querySnapshot.forEach(function (doc) {
								var document = {}
								document = doc.data();
								document.id = doc.id;
								// Exclude Documents with Delete Flag
								if (!document.hasOwnProperty('delete')) {
									documents.push(document);
								}
							})
							if (documents.length !== 0) {
								resolve({
									code: 1,
									message: 'Documents Read from Collection',
									data: documents,
									error: null
								})
							} else {
								resolve({
									code: 0,
									message: 'Warning No Documents could not be found in Collection',
									data: null,
									error: error
								});
							}

						})
						.catch((error) => resolve({
							code: -1,
							message: 'Error Reading Documents from Collection',
							data: null,
							error: error
						}));
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null});
				}
			})
		},

		// UPDATE DOCUMENT IN COLLECTION
		// NOTE: OVERWRITES EXISTING DOCUMENT
		MIX_updateDocument: function (collectionName, document) {
			const t = this;
			return new Promise(function (resolve) {
				document = JSON.parse(JSON.stringify(document));
				/*
					TODO - Change to Current User
				*/
				//        document.modifiedUserId = t.GET_currentUser.id;
				document.modifiedUserData = t.GET_currentUserData;
				document.modifiedDateTime = moment().format('x'); // add created timestamp to document
				if (collectionName !== null) {
					if (document.id === undefined) {
						resolve({
							code: 1,
							message: 'Warning Document Could not be Updated in Collection',
							data: {id: document.id},
							error: null
						})
					} else {
						firebase.db.collection(collectionName).doc(document.id).set(document)
							.then(() => resolve({
								code: 1,
								message: 'Document Updated / Overwritten in Collection',
								data: {id: document.id},
								error: null
							}))
							.catch((error) => resolve({
								code: -1,
								message: 'Error Writing Document to Collection',
								data: null,
								error: error
							}));
					}
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null});
				}
			})
		},

		// UPDATE DOCUMENT FIELDS IN COLLECTION
		// NOTE: OVERWRITES EXISTING DOCUMENT
		MIX_updateDocumentFieldsById: function (collectionName, id, fields) {
			const t = this
			return new Promise(function (resolve) {
				fields = JSON.parse(JSON.stringify(fields))
				/*
					TODO - Change to Current User
				*/
				fields.modifiedUserData = t.GET_currentUserData;
				fields.modifiedDateTime = moment().format('x'); // add created timestamp to document
				if (collectionName !== null) {
					if (id === undefined) {
						resolve({
							code: 1,
							message: 'Warning Fields Could not be Updated in Document',
							data: {id: id},
							error: null
						})
					} else {
						firebase.db.collection(collectionName).doc(id).update(fields)
							.then(() => resolve({
								code: 1,
								message: 'Fields Updated in Document',
								data: {id: document.id},
								error: null
							}))
							.catch((error) => resolve({
								code: -1,
								message: 'Error Writing Fields to Document',
								data: null,
								error: error
							}));
					}
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null});
				}
			})
		},

		// UPDATE NESTED ARRAY FROM DOCUMENT
		MIX_updateDocumentNestedArrayById: function (collectionName, id, updateArray, fields) {
			var t = this;
			return new Promise(function (resolve) {
				//fields = JSON.parse(JSON.stringify(fields));
				/*
					TODO - Change to Current User
				*/
				// fields.modifiedUserData = t.GET_currentUserData;
				// fields.modifiedDateTime = moment().format('x'); // add created timestamp to document
				if (collectionName !== null) {
					if (id === undefined) {
						resolve({
							code: 1,
							message: 'Warning Fields Could not be Updated in Document',
							data: {id: id},
							error: null
						})
					} else {
						firebase.db.collection(collectionName).doc(id).update({
							[updateArray]: firebase.fv.arrayUnion(fields)
						})
							.then(() => resolve({
								code: 1,
								message: 'Fields Updated in Document',
								data: {id: document.id},
								error: null
							}))
							.catch((error) => resolve({
								code: -1,
								message: 'Error Writing Fields to Document',
								data: null,
								error: error
							}));
					}
				} else {
					resolve({code: 0, message: 'Warning Collection was not specified', data: null, error: null});
				}
			})
		},

		// DELETE DOCUMENT - HIDE BY DELETE
		MIX_deleteDocument: function (collectionName, id) {
			var t = this;
			return new Promise(function (resolve) {
				if ((collectionName !== null) && (id !== '')) {
					/*
						TODO - Change to Current User
					*/
					firebase.db.collection(collectionName).doc(id).set({
						delete: moment().format('x'),
						deleteUserData: t.GET_currentUserData,
						//  modifiedUserId: t.GET_currentUser.id,
						// TODO Add DeletedUserData
						modifiedUserData: t.GET_currentUserData,
						modifiedDateTime: moment().format('x') // add created timestamp to document
					}, {merge: true})
						.then(() => resolve({
							code: 1,
							message: 'Delete Flag Set to 1 in Document',
							data: {id: id, delete: 1},
							error: null
						}))
						.catch((error) => resolve({
							code: -1,
							message: 'Error Updating Delete Flag in Document',
							data: null,
							error: error
						}));
				} else {
					resolve({code: 0, message: 'Warning Collection or Id was not specified', data: null, error: null});
				}
			})
		},

		// DELETE DOCUMENT PERMANENTLY - REMOVES FROM COLLECTION
		MIX_destroyDocument: function (collectionName, id) {
			return new Promise(function (resolve) {
				if ((collectionName !== null) && (id !== '')) {
					firebase.db.collection(collectionName).doc(id).delete()
						.then(() => resolve({
							code: 1,
							message: 'Document Permanently Remove from Collection',
							data: {id: id},
							error: null
						}))
						.catch((error) => resolve({
							code: -1,
							message: 'Error Deleting Document Permanently ',
							data: null,
							error: error
						}));
				} else {
					resolve({code: 0, message: 'Warning Collection or Id was not specified', data: null, error: null});
				}
			})
		},

		// DELETE LINKED DOCUMENT PERMANENTLY - REMOVES FROM COLLECTION
		MIX_destroyLinked: function (collections, documentId) {
			var batch = firebase.db.batch();
			return new Promise(async function (resolve) {
				try {
					if (JSON.stringify(collections) !== '[]') {
						for (var i = 0; i < collections.length; i++) {
							var collection = firebase.db.collection(collections[i])
							const response = await collection.where('docLink.documentId', '==', documentId).get();
							response.docs.forEach((doc) => {
								batch.delete(doc.ref);
							});
						}
						await batch.commit();
						resolve({
							code: 1,
							message: 'Pemanently Delete Linked Completely Succeessfully',
							data: null,
							error: null
						})
					} else {
						resolve({
							code: 0,
							message: 'WARINING - Pemanently Delete Linked Was not Supplied Collections',
							data: null,
							error: null
						})
					}
				} catch (error) {
					resolve({
						code: -1,
						message: 'ERROR - Pemanently Delete Linked Was not Supplied Collections',
						data: null,
						error: error
					})
					console.log(error)
				}
			});
		},

		// DELETE LINKED DOCUMENTS
		MIX_deleteLinked: function (collections, documentId) {
			var t = this;
			var batch = firebase.db.batch();
			const fields = {
				delete: moment().format('x'),
				deleteUserData: t.GET_currentUserData,
				modifiedUserData: t.GET_currentUserData,
				modifiedDateTime: moment().format('x') // add created timestamp to document
			};
			return new Promise(async function (resolve) {
				try {
					if (JSON.stringify(collections) !== '[]') {
						for (var i = 0; i < collections.length; i++) {
							var collection = firebase.db.collection(collections[i])
							const response = await collection.where('docLink.documentId', '==', documentId).get();
							response.docs.forEach((doc) => {
								batch.update(doc.ref, fields);
							});
						}
						await batch.commit();
						resolve({code: 1, message: 'Delete Linked Completely Succeessfully', data: null, error: null})
					} else {
						resolve({
							code: 0,
							message: 'WARINING - Delete Linked Was not Supplied Collections',
							data: null,
							error: null
						})
					}
				} catch (error) {
					resolve({
						code: -1,
						message: 'ERROR - Delete Linked Was not Supplied Collections',
						data: null,
						error: error
					})
					console.log(error)
				}
			});
		},

		// UNDELETE LINKED DOCUMENTS
		MIX_undeleteLinked: function (collections, documentId) {
			var t = this;
			var batch = firebase.db.batch();
			const fields = {
				modifiedUserData: t.GET_currentUserData,
				modifiedDateTime: moment().format('x') // add created timestamp to document
			};
			fields['delete'] = firebase.fv.delete()
			fields['deleteUserData'] = firebase.fv.delete()
			return new Promise(async function (resolve) {
				try {
					if (JSON.stringify(collections) !== '[]') {
						for (var i = 0; i < collections.length; i++) {
							var collection = firebase.db.collection(collections[i])
							const response = await collection.where('docLink.documentId', '==', documentId).get();
							response.docs.forEach((doc) => {
								batch.update(doc.ref, fields);
							});
						}
						await batch.commit();
						resolve({code: 1, message: 'Undelete Linked Completely Succeessfully', data: null, error: null})
					} else {
						resolve({
							code: 0,
							message: 'WARINING - Undelete Linked Was not Supplied Collections',
							data: null,
							error: null
						})
					}
				} catch (error) {
					resolve({
						code: -1,
						message: 'ERROR - Undelete Linked Was not Supplied Collections',
						data: null,
						error: error
					})
					console.log(error)
				}
			});
		},

		// UNDELETE DOCUMENT - HIDE BY DELETE
		MIX_undeleteDocument: function (collectionName, id) {
			var t = this;
			return new Promise(function (resolve) {
				if ((collectionName !== null) && (id !== '')) {
					let updates = {};
					updates['delete'] = firebase.fv.delete()
					updates['deleteUserData'] = firebase.fv.delete()
					firebase.db.collection(collectionName).doc(id)
						.update(updates)
						.then(() => resolve({
							code: 1,
							message: 'Delete Flag Removed',
							data: {id: id, delete: 1},
							error: null
						}))
						.catch((error) => resolve({
							code: -1,
							message: 'Error Removing Delete Flag in Document',
							data: null,
							error: error
						}));
				} else {
					resolve({code: 0, message: 'Warning Collection or Id was not specified', data: null, error: null});
				}
			})
		},

		// DELETE DOCUMENT - HIDE BY DELETE AND IN NESTED COLLECTION
		MIX_removeFromNestedArray(collectionName, docId, removeFrom, removeId) {
			return new Promise(function (resolve) {
				if ((collectionName !== null) && (removeId !== null) && (docId !== '') && (removeFrom !== null)) {
					firebase.db.collection(collectionName).doc(docId).update({
						[removeFrom]: firebase.fv.arrayRemove(removeId)
					})
						.then(() => resolve({
							code: 1,
							message: 'ID Removed from Array',
							data: {id: removeId},
							error: null
						}))
						.catch((error) => resolve({
							code: -1,
							message: 'Error Deleting ID from Arrayy ',
							data: null,
							error: error
						}));
				} else {
					resolve({code: 0, message: 'Warning Collection or Id was not specified', data: null, error: null});
				}
			})
		},

		// * GOOGLE CLOUD STORAGE DELETE FILE
		MIX_destroyFile: function (location) {
			var t = this;
			return new Promise(function (resolve) {
				const storageRef = t.$firebase.storage.ref().child(location); // Create Storage Reference to File
				storageRef.delete()
					.then(() => {
						resolve({code: 1, message: 'File Deleted Successfully', data: null, error: null});
					})
					.catch((error) => {
						resolve({code: -1, message: 'ERROR Deleting File', data: null, error: error});
					})

			})
		},

		// * GOOGLE CLOUD STORAGE INTEGRATION WITH FIREBASE
		MIX_uploadFile(folder, fileData, docLink, comment) {
			const t = this
			let location
			return new Promise(function (resolve) {

				if (folder !== undefined) {
					location = folder + `/${fileData.name}`
				} else {
					location = fileData.name
				}

				const STORAGE_REF = t.$firebase.storage.ref().child(location).put(fileData)

				STORAGE_REF.on(`state_changed`,
					(snapshot) => {
						t.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100 //upload progress
						t.isUploading = true
					},
					(error) => resolve({code: -1, message: 'Error Uploading File', data: null, error}),
					() => {
						t.uploadValue = 100 // upload value after loading file
						t.isUploading = false

						STORAGE_REF.snapshot.ref.getDownloadURL()
							.then(async (url) => {

								// Create the file metadata
								const storeFile = {
									fileName: fileData.name,
									fileLocation: location,
									fileSize: fileData.size,
									fileType: fileData.type,
									fileURL: url,
									docLink,
									comment,
								}
								// Change the file in store
								const CREATE_FILE_RESULT = await t.MIX_createDocument('files', storeFile)
								if (CREATE_FILE_RESULT.code === 1) {
									storeFile.id = CREATE_FILE_RESULT.data.id
									resolve({
										code: 1,
										message: 'Successfully uploaded file',
										data: storeFile,
										error: null
									})
								} else {
									resolve({
										code: -1,
										message: 'Error uploading file',
										data: storeFile,
										error: CREATE_FILE_RESULT.error
									})
								}
							});
					}
				);
			})
		},

		// // read documents by key value pair
		// readDocumentsByKeyValue(collectionName, dataObject, key, value) {
		//     var t = this;
		//         t.$firebase.db.collection(collectionName).where(key, "==", value).onSnapshot((querySnapshot) => {
		//             var data = [];
		//             var Data = {};
		//             querySnapshot.forEach((doc) => {
		//                 Data = doc.data();
		//                 Data.id = doc.id;
		//                 data.push(Data)
		//             });
		//             this[dataObject] = data;
		//         })

		// },
		// // read document by key value pair
		// readDocumentByKeyValue(collectionName, userId, key, value) {
		//     var t = this;
		//     return new Promise(function(resolve) {
		//         t.$firebase.db.collection(collectionName).where('userId', "==", userId).where(key, "==", value).onSnapshot((querySnapshot) => {
		//             var data = [];
		//             var Data = {};
		//             querySnapshot.forEach((doc) => {
		//                 Data = doc.data();
		//                 Data.id = doc.id;
		//                 data.push(Data)
		//             });
		//             resolve (data)
		//         })
		//     })

		// },
		// // update
		// // create document
		// updateDocument(collectionName, document) {
		//     var t = this;
		//     document = JSON.parse(JSON.stringify(document))
		//     return new Promise(function(resolve) {
		//         document.updatedUserId = t.$store.getters.currUser.userID; // add userId to document
		//         document.modifiedDateTime = t.$moment().format('x'); // add created timestamp to document
		//         t.$firebase.db.collection(collectionName).doc(document.id).set(document)
		//             .then(() => {
		//                 resolve ({ code: 1, message: 'Document Updated Successfully in Database', data: null, error: null })
		//             })
		//             .catch((error) => {
		//                 resolve ({ code: -1, message: 'Error Updating Document in Database', data: null, error: error })
		//             });
		//     })
		// },

	}
}


export default {
	install(Vue, options) {
		Vue.mixin(mixin)
	}
}
