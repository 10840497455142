<template>
	<v-row no-gutters>

		<!--Page Loader-->
		<page-loading :is-page-loading="isPageLoading"/>

		<!--Page Content-->
		<v-row v-if="!computedIsPageLoading" no-gutters style="height: 100%">

			<!--Left Panel-->
			<v-col v-if="computedSplitScreenColumns.left"
				   :cols="computedSplitScreenColumns.left"
				   class="pa-4">

				<!--Title-->
				<page-title icon="icons8-content"
							page-title="Actions"
							:description="computedPageTitle"
							:divider="true"/>

				<!--Searches-->
				<v-row no-gutters>

					<!--Document Name-->
					<v-col :class="$vuetify.breakpoint.width <= 600 ? '' : 'pr-2'"
						   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6">

						<app-input v-model.trim="searchByActionName"
								   append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="File Name"/>

					</v-col>

					<!--Posted By-->
					<v-col :class="$vuetify.breakpoint.width <= 600 ? 'mt-4' : 'pl-2'"
						   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6">

						<app-input v-model.trim="searchByPostedBy"
								   append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="Notified By"/>

					</v-col>

				</v-row>

				<!--Action Buttons - only show for Users with 'Action Creator' in userAccessAndAbilities-->
				<div v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Actions: Can Create and Upload Documents')"
					 class="d-flex justify-end mt-4">

					<!--New-->
					<app-btn v-if="!isRightPanelOpen"
							 @click.native="openRightPanel"
							 :block="$vuetify.breakpoint.width <= 600"
							 icon="icons8-content"
							 label="New"/>

				</div>

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  :headers="computedTableHeaders"
							  :items="computedTableData"
							  class="rounded-lg my-4">

					<!--Id-->
					<template v-slot:item.id="{item}">
						<app-text category="text-small">{{ item.id }}</app-text>
					</template>

					<!--Icon-->
					<template v-slot:item.documentType="{item}">
						<div class="d-flex justify-center pa-2">
							<v-img v-if="item.documentType === 'application/pdf'"
								   :src="require('@/assets/images/icons8-pdf.svg')" width="32"></v-img>
							<v-img
								v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
								:src="require('@/assets/images/icons8-microsoft-excel-2019.svg')" width="32"></v-img>
							<v-img
								v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
								:src="require('@/assets/images/icons8-microsoft-powerpoint-2019.svg')"
								width="32"></v-img>
							<v-img
								v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
								:src="require('@/assets/images/icons8-microsoft-word-2019.svg')" width="32"></v-img>
							<v-img v-if="['image/jpeg', 'image/jpg', 'image/png'].includes(item.documentType)"
								   :src="require('@/assets/images/icons8-image (1).svg')" width="32"></v-img>
							<notification-badge
								v-if="item.documentAcceptanceRequired && shouldNotificationBadgeShow(item)"
								content="New"
								:dot="false"/>
						</div>
					</template>

					<!--Document Name-->
					<template v-slot:item.documentName="{item}">
						<app-text category="text-small">{{ item.documentName }}</app-text>
					</template>

					<!--Document Posted By-->
					<template v-slot:item.documentPostedBy="{item}">
						<app-text category="text-small">
							{{ item.documentPostedBy }}
						</app-text>
					</template>

					<!--Document Modified Date-->
					<template v-slot:item.modifiedDateTime="{item}">
						<app-text category="text-small">
							{{ $moment(Number(item.modifiedDateTime)).format('ddd Do MMM \'YY @ HH:mm') }}
						</app-text>
					</template>

					<!--Actions-->
					<template v-slot:item.actions="{item}">
						<v-btn class="primary--text white"
							   depressed @click="openRightPanel(item)">
							<v-icon class="icons8-forward pa-0" color="primary"/>
						</v-btn>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600">

					<!--No documents message-->
					<app-text v-if="!computedTableData.length" class="d-flex justify-center text-center mt-4">
						You have no Actions to view
					</app-text>

					<!--Cards-->
					<v-card v-for="item in computedTableData" :key="item.id"
							@click="openRightPanel(item)"
							class="rounded-lg mt-4 pa-4" flat>

						<!--Notification Badge-->
						<div class="d-flex justify-end mr-4">
							<notification-badge
								v-if="item.documentAcceptanceRequired && shouldNotificationBadgeShow(item)"
								content="New"
								:dot="false"/>
						</div>

						<!--Document Name-->
						<div class="d-flex align-center">
							<div class="d-flex mr-4">

								<v-img v-if="item.documentType === 'application/pdf'"
									   :src="require('@/assets/images/icons8-pdf.svg')"
									   max-width="32"/>

								<v-img
									v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
									:src="require('@/assets/images/icons8-microsoft-excel-2019.svg')"
									max-width="32"/>

								<v-img
									v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
									:src="require('@/assets/images/icons8-microsoft-powerpoint-2019.svg')"
									max-width="32"/>

								<v-img
									v-if="item.documentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
									:src="require('@/assets/images/icons8-microsoft-word-2019.svg')"
									max-width="32"/>

								<v-img v-if="['image/jpeg', 'image/jpg', 'image/png'].includes(item.documentType)"
									   :src="require('@/assets/images/icons8-image (1).svg')"
									   max-width="32"/>

							</div>

							<app-text class="font-weight-bold">{{ item.documentName }}</app-text>

						</div>

						<!--Icon | Posted By-->
						<div class="d-flex align-start mt-2">

							<!--Icon-->
							<v-icon class="icons8-business-building mr-2" color="primary" size="16"/>

							<!--Document Name-->
							<app-text category="text-small" class="grey--text">
								{{ item.documentPostedBy }}
							</app-text>

						</div>

						<!--Icon | Date-->
						<div class="d-flex align-start mt-2">

							<!--Icon-->
							<v-icon class="icons8-upload mr-2" color="primary" size="16"/>

							<!--Date-->
							<app-text category="text-small" class="grey--text">
								{{ $moment(Number(item.modifiedDateTime)).format('ddd Do MMM \'YY @ HH:mm') }}
							</app-text>

						</div>

						</notification-badge>

					</v-card>

				</div>

			</v-col>

			<!--Right panel-->
			<v-col v-if="isRightPanelOpen"
				   :class="computedSplitScreenColumns.left && 'borderLeft'"
				   :cols="computedSplitScreenColumns.right">

				<!--Action Bar-->
				<div class="d-flex align-center justify-space-between appbackground pa-4">

					<!--Title-->
					<app-text category="text-medium">
						{{ selectedItem && selectedItem.documentName ? selectedItem.documentName : 'New' }}
					</app-text>

					<v-spacer/>

					<!--Action Buttons-->
					<div>

						<!--Export Button-->
						<app-btn v-if="tabs === 'responses'"
							@click.native="MIX_exportDocuments(computedExportResponses.headers, 'Actions Responses', computedExportResponses.data)"
								 class="mr-4"
							icon="icons8-export-csv"
							label="Export"/>

						<!--Edit Icon-->
						<!--Can only be edited by Admin-->
						<!-- <edit-icon v-if="selectedItem && GET_currentUser.userAccessAndAbilities.includes('Action Creator')"
								   :isActive="!isFormReadOnly" @click.native="editForm"/> -->

						<!--Delete Icon-->
						<!--Can only be deleted by Admin-->
						<delete-icon-dialog
							v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Actions: Can Create and Upload Documents') && tabs === 'overview'"
							:disabled="!isFormReadOnly"
							:item-to-delete="selectedItem && selectedItem.documentName"
							v-on:emitDeleteFromDialog="emittedDeleteFromDialog"/>

						<!--Close Icon-->
						<close-icon @click.native="closeRightPanel"/>

					</div>

				</div>

				<!--Tabs-->
				<v-tabs v-model="tabs" show-arrows>

					<v-tabs-slider color="accent"/>

					<!--Overview - Action Creators only-->
					<v-tab v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Actions: Can Create and Upload Documents')"
						   href="#overview">
						<app-text category="text-small">Overview</app-text>
					</v-tab>

					<!--Viewer-->
					<v-tab v-if="selectedItem" href="#viewer">
						<app-text category="text-small">Viewer</app-text>
					</v-tab>

					<!--Responses - Action Creators only-->
					<v-tab v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Actions: Can View Responses')
								 && selectedItem && selectedItem.documentAcceptanceRequired"
						   href="#responses">
						<app-text category="text-small">Responses</app-text>
					</v-tab>

				</v-tabs>

				<!--Tab Content-->
				<v-tabs-items v-model="tabs">

					<!--Overview Tab Content-->
					<v-tab-item class="appbackground pa-4" value="overview">

						<actions-form :doesFormStartReadOnly="isFormReadOnly"
									  :formData="selectedItem"
									  :usersCollectionData="usersCollectionData"
									  v-on:emitCloseRightPanel="emittedCloseRightPanel"/>

					</v-tab-item>

					<!--Viewer Tab Content-->
					<v-tab-item class="appbackground" value="viewer">

						<actions-viewer :formData="selectedItem"
										:userActionsResponsesCollectionData="userActionsResponsesCollectionData"
										:usersCollectionData="usersCollectionData"
										@emitUserReadActionDocument="handleUserReadActionDocument"
										v-on:emitCloseRightPanel="emittedCloseRightPanel"/>

					</v-tab-item>

					<!--Responses Tab Content-->
					<v-tab-item class="appbackground" value="responses">

						<actions-responses :actionData="selectedItem"
										   :actionResponsesCollectionData="actionsResponsesCollectionData"
										   :usersCollectionData="usersCollectionData"/>

					</v-tab-item>

				</v-tabs-items>

			</v-col>

		</v-row>

	</v-row>
</template>

<script>
import {mapGetters} from "vuex";
import ActionsForm from "@/views/actions/actionsForm/actionsForm"
import ActionsViewer from "@/views/actions/actionsViewer/actionsViewer"
import ActionsResponses from "@/views/actions/actionsResponses/actionsResponses"

export default {

	name: "actions",

	components: {ActionsViewer, ActionsForm, ActionsResponses},

	data: () => ({
		isRightPanelOpen: false,
		isFormReadOnly: true,
		searchByActionName: '',
		searchByPostedBy: '',
		selectedItem: {},
		tableHeaders: [
			{
				text: 'Id',
				value: 'id',
				sortable: false,
				align: 'left',
				hiddenByDefault: true,
				hideWithWidth: true
			},
			{
				text: 'File Type',
				value: 'documentType',
				sortable: false,
				align: 'center',
				hiddenByDefault: false,
				hideWithWidth: false,
				width: '88px'
			},
			{
				text: 'File',
				value: 'documentName',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: 'Notified By',
				value: 'documentPostedBy',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: 'Updated On',
				value: 'modifiedDateTime',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false,
			},
			{
				text: '',
				value: 'actions',
				sortable: false,
				align: 'right',
				hiddenByDefault: false,
				hideWithWidth: false,
				width: '96px',
			},
		],
		tabs: '',

		// Collection Data
		actionsCollectionData: [],
		isActionsCollectionDataLoaded: false,
		actionsResponsesCollectionData: [],
		isActionsResponsesCollectionDataLoaded: false,
		userActionsResponsesCollectionData: [],
		isUserActionsResponsesCollectionDataLoaded: false,
		usersCollectionData: [],
		isUsersCollectionDataLoaded: false,
		isPageLoading: true,
	}),

	computed: {
		...mapGetters({
			GET_currentUser: 'GET_currentUser',
		}),

		/**
		 * Computed Export Responses
		 *
		 * Create and return an object containing the column headers, and data for the export.
		 * The data is made up of fields from the selected document, the responses for this document, and user data.
		 *
		 * @returns {{headers: {hasAccepted: string, responseDateTime: string, documentName: string, userName: string, firstOpenedDateTime: string}, data: *[]}}
		 */
		computedExportResponses() {
			const t = this
			const DOCUMENT_ACTION_RESPONSES = t.actionsResponsesCollectionData
			const SELECTED_DOCUMENT = t.selectedItem
			const USERS_DATA = t.usersCollectionData
			let data = []
			let headers = {}

			headers = {
				documentName: 'Document Name',
				userName: 'User Name',
				firstOpenedDateTime: 'First Opened',
				hasAccepted: 'Has Accepted',
				responseDateTime: 'Date Responded',
			}

			DOCUMENT_ACTION_RESPONSES.forEach(doc => {

				const USER = USERS_DATA.find(u => u.id === doc.userId)

				const dataObject = {
					documentName: SELECTED_DOCUMENT.documentName ? SELECTED_DOCUMENT.documentName : 'Name not Found',
					userName: USER.userName ? USER.userName : 'Name not found',
					firstOpenedDateTime: doc.firstOpenedDateTime ? t.$moment(Number(doc.firstOpenedDateTime)).format('Do ddd MMM \'YY @ HH:mm') : 'Not opened',
					hasAccepted: doc.hasAccepted ? 'Yes' : 'No',
					responseDateTime: doc.responseDateTime ? t.$moment(Number(doc.responseDateTime)).format('Do ddd MMM \'YY @ HH:mm') : 'Not responded',
				}

				data.push(dataObject)
			})

			return {headers, data}
		},

		/**
		 * Computed Is Page Loading
		 *
		 * Return a boolean for the page loading spinner to denote if all data has been loaded.
		 *
		 * @returns {boolean} if data has been loaded or not
		 */
		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isActionsCollectionDataLoaded
				&& t.isUserActionsResponsesCollectionDataLoaded
				&& t.isUsersCollectionDataLoaded
			)

			return t.isPageLoading
		},

		computedPageTitle() {
			const t = this
			let description

			if (['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(t.GET_currentUser.userLevel) || t.GET_currentUser.userAccessAndAbilities.includes('Actions: Can Create and Upload Documents')) {
				description = 'Upload and view documents, and view document user responses.'
			} else {
				description = 'View your Actions, and check any that require you to accept them.'
			}

			return description
		},

		/**
		 * Computed Split Screen Columns
		 *
		 * Calculate the width of the left snd right panels, based on the currently selected functionality.
		 * The left panel has the table, the right panel has the forms.
		 *
		 * @returns {{left: number, right: number}}
		 */
		computedSplitScreenColumns() {
			const t = this
			let left = 12
			let right = 0

			if (t.$vuetify.breakpoint.width >= 1200) {
				if (t.isRightPanelOpen) {
					left = 4
					right = 8
				}
			} else {
				if (t.isRightPanelOpen) {
					left = 0
					right = 12
				}
			}

			return {left, right}
		},

		/**
		 * Computed Table Data
		 *
		 * Return a sorted array of JSON objects.
		 * Visibility is as follows:
		 *  - Senior Management can see all documents.
		 *  - Non-Senior Management can only see documents relevant to their userLevel, and, that were created after the user registered.
		 *
		 * @returns {Array} Array of processed JSON data
		 */
		computedTableData() {
			const t = this
			const CURRENT_USER_DATA = t.GET_currentUser
			let tableData = t.actionsCollectionData

			// Search by Action Name
			if (t.searchByActionName) {
				tableData = tableData.filter(data =>
					data.documentName.toUpperCase().includes(t.searchByActionName.toUpperCase()))
			}

			// Search by Posted By
			if (t.searchByPostedBy) {
				tableData = tableData.filter(data =>
					data.documentPostedBy.toUpperCase().includes(t.searchByPostedBy.toUpperCase()))
			}

			if (!['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(CURRENT_USER_DATA.userLevel)) {
				tableData = tableData.filter(data => {

					// Check the current user's userLevel is included in the document's userLevels
					const isUserLevelIncluded = data.documentUserLevels.includes(CURRENT_USER_DATA.userLevel)

					// Check the document was created after the user was created
					const isDocumentCreatedBeforeUser = data.createdDateTime > CURRENT_USER_DATA.createdDateTime

					return isDocumentCreatedBeforeUser && isUserLevelIncluded
				})
			}

			// Sort by updated date
			tableData = tableData.sort((a, b) => a.createdDateTime > b.createdDateTime ? 1 : -1)

			return tableData
		},

		/**
		 * Computed Table Headers
		 *
		 * Using the tableHeaders data, hide the fields that aren't required.
		 *
		 * @returns {Array} an array of table header objects
		 */
		computedTableHeaders() {
			const t = this
			let tableHeaders = t.tableHeaders

			tableHeaders = tableHeaders.filter(header => !header.hiddenByDefault)

			if (t.computedSplitScreenColumns.left < 12) {
				tableHeaders = tableHeaders.filter(header => !header.hideWithWidth)
			}

			return tableHeaders
		},

	},

	methods: {

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 *
		 * @returns {Promise<void>}
		 */
		async getActionsCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('actions')
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) collectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.actionsCollectionData = collectionData
					t.isActionsCollectionDataLoaded = true
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 * Get all the Action Responses for the selected document.
		 *
		 * @returns {Promise<void>}
		 */
		async getActionsResponsesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('actionsResponses')
				.where('documentId', '==', t.selectedItem.id)
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) collectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.actionsResponsesCollectionData = collectionData
					t.isActionsResponsesCollectionDataLoaded = true
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 * Get all Actions Responses for the current user.
		 *
		 * @returns {Promise<void>}
		 */
		async getUserActionsResponsesCollectionData() {
			const t = this
			let collectionData = []
			const currentUser = t.GET_currentUser

			await t.$firebase.db.collection('actionsResponses')
				.where('userId', '==', currentUser.id)
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push active documents
						if (!document.hasOwnProperty('delete')) collectionData.push(document)

					})

					// Assign the documents in one and set isLoaded to true
					t.userActionsResponsesCollectionData = collectionData
					t.isUserActionsResponsesCollectionDataLoaded = true
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch the data from the collection and assign them to the class state.
		 *
		 * @returns {Promise<void>}
		 */
		async getUsersCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('users')
				.where('userLevel', '!=', 'Visitor-User')
				.onSnapshot(snapshot => {

					// Clear the array to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						// Only add documents that:
						//  - Aren't marked as deleted
						//  - Are Approved
						//  - Have NOT signed up with 2FA
						//  - Have signed up with, and have passed 2FA
						if (!DOCUMENT.hasOwnProperty('delete') &&
							DOCUMENT.userStatus === 'APPROVED' &&
							(!DOCUMENT.userShouldUse2fa || (DOCUMENT.userHasPassed2fa))
						) {
							collectionData.push(DOCUMENT)
						}

					})

					collectionData = collectionData.sort((a, b) => a.userName > b.userName ? 1 : -1)

					// Assign the documents in one and set isLoaded to true
					t.usersCollectionData = collectionData
					t.isUsersCollectionDataLoaded = true
				})
		},

		/**
		 * Close Right Panel
		 *
		 * When closing the form, reset it to ensure it opens as new.
		 */
		closeRightPanel() {
			const t = this

			t.isFormReadOnly = true
			t.isRightPanelOpen = false
			t.tabs = ''
		},

		/**
		 * Edit Form
		 *
		 * Toggle the read only state of the form.
		 */
		editForm() {
			const t = this

			t.isFormReadOnly = !t.isFormReadOnly;
		},

		/**
		 * Emitted Close Right Panel
		 *
		 * Take the emitted message and call to close the right panel.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedCloseRightPanel() {
			const t = this

			t.closeRightPanel()
		},

		/**
		 * Emitted Delete From Dialog
		 *
		 * Take the emitted message from the 'deleteIconDialog', and mark the selected document as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedDeleteFromDialog() {
			const t = this

			const documentResult = await t.MIX_deleteDocument('actions', t.selectedItem.id)

			// If the document was successfully deleted, destroy any associated ActionResponses documents
			if (documentResult.code === 1) {
				for (const actionResponse of t.actionsResponsesCollectionData) {
					if (actionResponse.documentId === t.selectedItem.id) {
						await t.MIX_destroyDocument('actionsResponses', actionResponse.id)
					}
				}
			}

			t.closeRightPanel()

			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully Deleted Document',
				'Error Deleting Document')
		},

		/**
		 * Handle User Read Action Document
		 *
		 * Silently update the current user's current Action Response document to show they've opened it.
		 *
		 * @param actionResponseId the ID of the opened Action Response document
		 */
		async handleUserReadActionDocument(actionResponseId) {
			const t = this
			const dateTimeNow = t.$moment().format('x')

			await t.MIX_updateDocumentFieldsById(
				'actionsResponses',
				actionResponseId,
				{firstOpenedDateTime: dateTimeNow}
			)

		},

		/**
		 * Should Notification Badge Show
		 *
		 * Get the current user's current ActionResponse document and check the hasAccepted status.
		 *
		 * @param currentActionDocument the currently selected Actions Document
		 * @returns {boolean} whether to show the notification badge, or not
		 */
		shouldNotificationBadgeShow(currentActionDocument) {
			const t = this
			let shouldShow

			const userActionResponses = t.userActionsResponsesCollectionData

			const currentActionResponse = userActionResponses.find(uar => uar.documentId === currentActionDocument.id)

			if (currentActionResponse?.id) shouldShow = !currentActionResponse.hasAccepted

			return shouldShow
		},

		/**
		 * Open Right Panel
		 *
		 * Open the CRUD form.
		 * If the form is opened using the new button, it will be blank.
		 * If the form is opened from the table, it will be assigned here and populated.
		 *
		 * @param item JSON containing the selected data, if required
		 */
		async openRightPanel(item) {
			const t = this
			// const isAllowed = t.GET_currentUser.userAccessAndAbilities.includes('Action Creator')
			t.closeRightPanel()

			// Set whether the form fields should be disabled or not
			if (!item?.id) t.isFormReadOnly = false

			// Set the selected item
			t.selectedItem = item

			// If the current user is an Action Creator, fetch the ActionsResponses documents
			// if (item?.id && isAllowed) await t.getActionsResponsesCollectionData()
			if (item?.id) await t.getActionsResponsesCollectionData()

			t.isRightPanelOpen = true
		},

	},

	async mounted() {
		const t = this

		await t.getActionsCollectionData()
		await t.getUserActionsResponsesCollectionData()

		// Only import the 'users' collection if the current user is an Action Creator in Ac&Ab
		if (['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(t.GET_currentUser.userLevel) || ['Actions: Can Create and Upload Documents', 'Actions: Can View Responses'].includes(t.GET_currentUser.userAccessAndAbilities)) {
			await t.getUsersCollectionData()
		} else {
			t.isUsersCollectionDataLoaded = true
		}

	}

}
</script>

<style scoped>
.borderLeft {
	border-left: 4px solid #999999;
	min-height: calc(100vh - 128px);
}
</style>
