<template>
    <div
        style="height: 100%; display: flex; flex-direction: column;  justify-content: space-between; align-items: center"
        class="white rounded-lg pa-4 "
    >
        <v-img :src="require('@/assets/logo-virgin-pulse.png')" contain width="200" height="100" />

        <app-btn
            :block="true"
            class="mt-4"
            color="primary"
            href="https://iam.virginpulse.com/auth/realms/virginpulse/protocol/openid-connect/auth?client_id=genesis-ui&redirect_uri=https%3A%2F%2Fapp.member.virginpulse.com%2F&state=fd2b192d-5ffe-48d1-8127-aab213ab5bb1&nonce=c4057426-16e8-40da-8673-4794c50c1eb0&response_mode=fragment&response_type=code&scope=openid"
            label="Open Virgin Pulse"
        />
    </div>
</template>

<script>
export default {
    name: 'VirginPulseCard',

    data: () => ({}),

    methods: {}
}
</script>

<style scoped></style>
