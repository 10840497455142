<template>
	<div>

		{{computedInit}}

		<v-divider/>

		<!--Title-->
		<div class="d-flex justify-center primary">
			<app-text category="text-large" class="white--text pa-4">Recognition</app-text>
		</div>

		<!--Form-->
		<v-row class="px-4" no-gutters>

			<!--Title-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-input inputType="textInput"
						   :error="errors.observationTitle"
						   :error-messages="errors.observationTitleErrorMessage"
						   :disabled="isReadOnly"
						   :is-form-read-only="isReadOnly"
						   label="Title"
						   placeholder="Please provide a title"
						   v-model.trim="form.observationTitle"/>
			</v-col>

			<!--Site-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-input inputType="select"
						   :error="errors.observationSite"
						   :error-messages="errors.observationSiteErrorMessage"
						   :disabled="isReadOnly"
						   :is-form-read-only="isReadOnly"
						   :items="computedSitesData"
						   item-text="siteName"
						   label="Site"
						   :return-object="true"
						   v-model="selectedSiteObject"/>
			</v-col>

			<!--Users-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-input inputType="autocomplete"
						   :error="errors.observationRecognisedUser"
						   :error-messages="errors.observationRecognisedUserErrorMessage"
						   :disabled="isReadOnly"
						   :is-form-read-only="isReadOnly"
						   :items="usersData"
						   item-text="userName"
						   label="User"
						   :return-object="true"
						   v-model="selectedRecognisedUserObject"/>
			</v-col>

			<!--Description-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
				<app-input inputType="textArea"
						   :error="errors.observationDescription"
						   :error-messages="errors.observationDescriptionErrorMessage"
						   :disabled="isReadOnly"
						   :is-form-read-only="isReadOnly"
						   label="Description of Recognition"
						   placeholder="Please provide details of why you are recognising them"
						   v-model.trim="form.observationDescription"/>
			</v-col>

			<!--Reporting User-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
				<app-input input-type="textInput"
						   :disabled="true"
						   :is-form-read-only="isReadOnly"
						   label="Reporter's Name"
						   v-model="selectedReportingUserObject.userName"/>
			</v-col>

			<!--Images-->
			<div class="d-flex justify-space-between" style="width: 100%">
				<form-section-title class="mt-4"
									description="Add supporting images"
									title="Images"/>

				<!--Photo upload-->
				<photoupload v-if="form.observationReportingFiles.length < 3"
							 allowed-types="image/*"
							 comment="reporting"
							 :doc-link="{collection: 'observations', documentId: form.id}"
							 folder="/observations"
							 label="Add"/>
			</div>
			<v-col class="mt-4"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
				   style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 16px">

				<!--Image One-->
				<div class="d-flex align-center justify-center rounded-lg"
					 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

					<v-icon v-if="!form.observationReportingFiles[0]" class="icons8-image-document"
							size="48"/>
					<v-img v-else
						   @click="openFilePreview(form.observationReportingFiles[0])"
						   aspect-ratio="1"
						   :src="form.observationReportingFiles[0]"
						   class="rounded-lg ma-2"
						   width="48"/>

				</div>

				<!--Image Two-->
				<div class="d-flex align-center justify-center rounded-lg"
					 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

					<v-icon v-if="!form.observationReportingFiles[1]" class="icons8-image-document"
							size="48"/>
					<v-img v-else
						   @click="openFilePreview(form.observationReportingFiles[1])"
						   aspect-ratio="1"
						   :src="form.observationReportingFiles[1]"
						   class="rounded-lg ma-2"
						   width="48"/>

				</div>

				<!--Image Three-->
				<div class="d-flex align-center justify-center rounded-lg"
					 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

					<v-icon v-if="!form.observationReportingFiles[2]" class="icons8-image-document"
							size="48"/>
					<v-img v-else
						   @click="openFilePreview(form.observationReportingFiles[2])"
						   aspect-ratio="1"
						   :src="form.observationReportingFiles[2]"
						   class="rounded-lg ma-2"
						   width="48"/>

				</div>


			</v-col>

			<!--Save Button-->
			<v-col cols="12" class="d-flex justify-end mt-4">

				<!--Save-->
				<app-btn v-if="!isReadOnly" @click.native="saveDocument"
						 class="mb-4"
						 color="success"
						 icon="icons8-save"
						 label="Save"/>

			</v-col>

		</v-row>

		<form-meta-data class="mx-4" :form-data="form"/>

		<!--Dialog ------------------------------------------------------------------------------------------------- -->

		<!--File Preview Dialog-->
		<v-dialog max-width="75vw" v-model="isFilePreviewVisible">
			<v-toolbar class="d-flex justify-end rounded-t-lg" color="primary">
				<delete-icon-dialog @emitDeleteFromDialog="deleteFile(selectedFile)"
									item-to-delete="this file."/>
				<close-icon @click.native="closeFilePreview"/>
			</v-toolbar>
			<v-img :src="selectedFile" class="rounded-b-lg"/>
		</v-dialog>

	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {

	name: "recognitionForm",

	props: ['externalSitesData', 'formData', 'isReadOnly', 'sitesData', 'usersData'],

	data: () => ({
		errors: {
			observationDescription: false,
			observationDescriptionErrorMessage: '',
			observationRecognisedUser: false,
			observationRecognisedUserErrorMessage: '',
			observationSite: false,
			observationSiteErrorMessage: '',
			observationTitle: false,
			observationTitleErrorMessage: '',
		},
		fileUploadResult: {},
		form: {
			id: '',
			observationDescription: '',
			observationRecognisedUser: '',
			observationSite: '',
			observationStatus: 'Resolved',
			observationTitle: '',
			observationType: 'Recognition',
			observationReportingFiles: [],

			createdUserData: {},
			createdDateTime: '',
			modifiedUserData: {},
			modifiedDateTime: '',
		},
		selectedSiteObject: {},
		selectedReportingUserObject: {},
		selectedRecognisedUserObject: {},
		selectedFile: {},
		isFilePreviewVisible: false,

		// Data
		filesCollectionData: [],
	}),

	computed: {
		...mapGetters({
			GET_currentUser: 'GET_currentUser',
			GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult'
		}),

		/**
		 * Computed Init
		 *
		 * If this is an existing document, set the form data.
		 * If it's not, the default form will be used.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = {...t.$props.formData}
			const SITES_DATA = t.$props.sitesData
			const EXTERNAL_SITES_DATA = t.$props.externalSitesData
			const USERS_DATA = t.$props.usersData

			// Set the form data if it's an existing document
			if (FORM_DATA?.id) {
				t.form = FORM_DATA
				t.selectedSiteObject = SITES_DATA.find(site => site.id === FORM_DATA.observationSite) || EXTERNAL_SITES_DATA.find(site => site.id === FORM_DATA.observationSite)
				t.selectedReportingUserObject = t.form.createdUserData
				t.selectedRecognisedUserObject = USERS_DATA.find(user => user.id === FORM_DATA.observationRecognisedUser)
				t.getFilesCollectionData()
			} else {
				t.selectedReportingUserObject = t.GET_currentUser
			}
		},

		/**
		 * Computed Sites Data
		 *
		 * Offices and Project Sites need to be in the same list, but Project Sites have a 'projectTitle' instead of
		 * 'siteName', so add a siteName to all Project Sites and return as a single array.
		 *
		 * @returns {array} array of site objects
		 */
		computedSitesData() {
			const t = this
			const OFFICES_DATA = t.$props.sitesData
			const SITES_DATA = t.$props.externalSitesData

			SITES_DATA.forEach(site => site.siteName = site.projectTitle)

			return [...OFFICES_DATA, ...SITES_DATA]
		},

	},

	methods: {

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close File Preview
		 *
		 * Close the file preview dialog and reset the selected file.
		 */
		closeFilePreview() {
			const t = this

			t.selectedFile = {}
			t.isFilePreviewVisible = false
		},

		/**
		 * Delete File
		 *
		 * Update the document to remove the file from the array.
		 * Update Files collection to mark the selected photo as deleted.
		 *
		 * @param fileURL - the URL of the file to delete
		 * @returns {Promise<void>}
		 */
		async deleteFile(fileURL) {
			const t = this
			const FILES_DATA = t.filesCollectionData
			let documentResult

			// Get file data
			const FILE_DATA = FILES_DATA.find(fileData => fileData.fileURL === fileURL)

			// Remove the FILE_DATA from either the reporting or responding files array, depending on which tab is open
			t.form.observationReportingFiles = t.form.observationReportingFiles.filter(f => f !== fileURL)

			// Update the document to remove the file
			documentResult = await t.MIX_updateDocument('observations', t.form)
			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully Deleted File',
				'Error Deleting File',
			)

			// If the document was successfully updated, delete the file from storage
			if (documentResult.code === 1) documentResult = await t.MIX_deleteDocument('files', FILE_DATA.id)

			t.closeFilePreview()
		},

		/**
		 * Get Files Collection Data
		 *
		 * Get the files collection data for the current document.
		 */
		async getFilesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('files')
				.where('docLink', '==', t.form.id)
				.onSnapshot(snapshot => {

					collectionData = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						if (!DOCUMENT.hasOwnProperty('delete')) collectionData.push(DOCUMENT)
					})

					t.filesCollectionData = collectionData
				})
		},

		/**
		 * Open File Preview
		 *
		 * Open the file preview dialog and set the selected file.
		 * @param imageURL the file path to set
		 */
		openFilePreview(imageURL) {
			const t = this

			t.selectedFile = imageURL
			t.isFilePreviewVisible = true
		},

		/**
		 * Save Document
		 *
		 * Set the required data and save/update the document.
		 *
		 *
		 * @returns {Promise<void>}
		 */
		async saveDocument() {
			const t = this
			let documentResult

			// Only continue if the form has passed validated
			if (!t.validateForm()) return

			// Get the site ID from the selected site object
			t.form.observationSite = t.selectedSiteObject.id

			// Get the user ID from the selected user object
			t.form.observationRecognisedUser = t.selectedRecognisedUserObject.id

			// Create
			if (!t.form.id) {

				// Generate new document ID
				t.form.id = t.MIX_generateId()

				documentResult = await t.MIX_createDocument('observations', t.form)

				if (documentResult.code === 1) t.sendRecognitionObservationEmail()
			}

			// Update
			else {
				documentResult = await t.MIX_updateDocument('observations', t.form)
			}

			// If the document was successfully updated, update the Files collection to add the docLink
			if (documentResult.code === 1) {

				// Update the Files collection to add the docLink
				await t.MIX_updateDocumentFieldsById('files', t.fileUploadResult.id, {docLink: t.form.id})
			}

			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully saved Report',
				'Error saving Report')

			t.$emit('emitCloseRightPanel')
		},

		/**
		 * Send Recognition Observation Email
		 *
		 * Trigger FB function (sendNewObservationEmail) to send email notifying selected users of a new Observation.
		 */
		sendRecognitionObservationEmail() {
			const t = this

			const CREATE_EMAIL = t.$firebase.functions.httpsCallable('sendRecognitionObservationEmail')

			CREATE_EMAIL(t.form)
				.then(() => console.log('Successfully sent Recognition Observation email'))
				.catch(error => console.error('Error sending Recognition Observation email', error))
		},

		/**
		 * Validate Form
		 *
		 * Validate the form and set the errors.
		 *
		 * @returns {boolean} true if the form is valid, false if not
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Title
			if (!t.form.observationTitle.trim()) {
				t.errors.observationTitle = true
				t.errors.observationTitleErrorMessage = 'Please provide a Title'
			}

			// Site
			if (!t.selectedSiteObject.id) {
				t.errors.observationSite = true
				t.errors.observationSiteErrorMessage = 'Please select a Site'
			}

			// Recognised User
			if (!t.selectedRecognisedUserObject.id) {
				t.errors.observationRecognisedUser = true
				t.errors.observationRecognisedUserErrorMessage = 'Please select a User'
			}

			// Description
			if (!t.form.observationDescription.trim()) {
				t.errors.observationDescription = true
				t.errors.observationDescriptionErrorMessage = 'Please provide a Description'
			}

			return !Object.values(t.errors).includes(true)
		},

	},

	watch: {

		GET_photoUploadResult: {
			async handler() {
				const t = this
				const PHOTO_UPLOAD_RESULT = t.GET_photoUploadResult

				t.fileUploadResult = PHOTO_UPLOAD_RESULT

				if (PHOTO_UPLOAD_RESULT.comment === 'reporting') {
					t.form.observationReportingFiles.push(PHOTO_UPLOAD_RESULT.fileURL)
					await t.MIX_updateDocumentFieldsById(
						'observations',
						t.form.id,
						{observationReportingFiles: t.form.observationReportingFiles})
				}

			}
		}

	},

}
</script>

<style scoped>

</style>
