<template>
	<v-form>

		{{ computedInit }}

		<!--Form Fields-->
		<v-row class="d-flex flex-column" no-gutters>

			<app-text>Uploaded files must be in either a PDF, .docx, .pptx, .xlsx, JPG or PNG format.</app-text>

			<!--Document Name-->
			<app-input input-type="textInput"
					   class="mt-4"
					   :error="errors.documentName"
					   :error-messages="errors.documentNameErrorMessage"
					   :is-form-read-only="computedIsFormReadOnly"
					   label="Document Name"
					   v-model.trim="form.documentName"/>

			<!--User Levels-->
			<app-input input-type="select"
					   :chips="true"
					   class="mt-4"
					   :deletable-chips="true"
					   :error="errors.documentUserLevels"
					   :error-messages="errors.documentUserLevelsErrorMessage"
					   :is-form-read-only="computedIsFormReadOnly"
					   :items="actionsUserLevelsOptionsData"
					   label="User Levels"
					   :multiple="true"
					   :small-chips="true"
					   v-model="form.documentUserLevels"/>

			<!--Acceptance Required Checkbox-->
			<div class="d-flex">

				<!--Checkbox-->
				<v-checkbox :disabled="computedIsFormReadOnly"
							hide-details="auto"
							v-model="form.documentAcceptanceRequired"/>

				<!--Title-->
				<app-text category="text-default" class="mt-5">Is Acceptance Required?</app-text>

			</div>

			<!--Acceptance Information Text-->
			<app-text category="text-small" class="grey--text mt-2">
				By checking this box, all Users will be required to acknowledge they've read and accepted this document,
				and will have a notification dot until accepted.
			</app-text>

			<!--Photo Upload-->
			<div class="d-flex align-center mt-8">
				<photoupload :allowed-types="['application/pdf', '.docx', 'application/msword', 'image/*']"
							 :disabled="computedIsFormReadOnly"
							 :doc-link="{collection: 'actions', documentId: form.id}"
							 label="Add File"/>

				<div>

					<!--File Name-->
					<app-text v-if="fileUploadResult">
						{{ fileUploadResult.fileName }}
					</app-text>

					<!--File Size-->
					<app-text v-if="fileUploadResult">
						{{ (fileUploadResult.fileSize / 1024).toFixed(1) }}
						<span class="grey--text">KB</span>
					</app-text>

				</div>

			</div>

			<!--Save button-->
			<div v-if="!computedIsFormReadOnly" class="d-flex justify-end mt-4">
				<app-btn color="success"
						 :disabled="!form.id && !(form.documentName && (fileUploadResult && fileUploadResult.id))"
						 icon="icons8-save" label="Save" @click.native="saveDocument"/>
			</div>

		</v-row>

	</v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {

	name: "actionsForm",

	props: ['doesFormStartReadOnly', 'formData', 'usersCollectionData'],

	data: () => ({
		fileUploadResult: null,
		form: {
			id: '',
			documentName: '',
			documentUrl: null,
			documentUserLevels: [],
			documentAcceptanceRequired: false,

			createdDateTime: '',
		},
		errors: {
			documentName: false,
			documentNameErrorMessage: '',
			documentUserLevels: false,
			documentUserLevelsErrorMessage: '',
		},
		imagePreview: '',
		isFormReadOnly: false,
	}),

	computed: {
		...mapGetters({
			GET_currentUser: 'GET_currentUser',
			GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
		}),

		/**
		 * Computed Init
		 *
		 * If this is an existing document, set the form data.
		 * If it's not, the default form will be used.
		 */
		computedInit() {
			const t = this

			const formData = t.$props.formData

			if (formData?.id) t.form = formData

		},

		/**
		 * Computed Is Form Read Only
		 *
		 * When the form is first created in the Supervisor's Control Panel,
		 * the form should be editable.
		 * If the form is open through the Reporting pages, it should open uneditable.
		 *
		 * @returns {boolean} is form read only
		 */
		computedIsFormReadOnly() {
			const t = this

			return t.$props.doesFormStartReadOnly
				? t.$props.doesFormStartReadOnly
				: t.isFormReadOnly
		},

	},

	methods: {

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Save Document
		 *
		 * Create/update the document.
		 *
		 * @returns {Promise<void>}
		 */
		async saveDocument() {
			const t = this
			let documentResult

			if (!t.validateForm()) return

			// Create
			if (!t.form?.id) {

				// Generate new IDs
				t.form.id = t.MIX_generateId()

				// Save new document
				documentResult = await t.MIX_createDocument('actions', t.form)

				// If the document was successfully saved, add the post-processing
				if (documentResult.code === 1) {

					// Update the File document with the new docLink
					await t.MIX_updateDocumentFieldsById('files', t.fileUploadResult.id, {
						docLink: t.form.id,
					})

					// Save new actionsResponse document, must contain a new object for every user
					if (t.form.documentAcceptanceRequired) await t.handleNewAcceptanceRequiredDocuments()
				}

			}

			// Update
			else {
				documentResult = await t.MIX_updateDocument('actions', t.form)
			}

			if (t.imagePreview && documentResult.code === 1) await t.uploadFile()

			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully Saved Action',
				'Error Saving Action'
			)

			t.$emit('emitCloseRightPanel')
		},

		async handleNewAcceptanceRequiredDocuments() {
			const t = this
			let usersData = t.usersCollectionData

			// Get all the users concerned with this document
			if (t.form.documentUserLevels?.length) {
				usersData = usersData.filter(user => t.form.documentUserLevels.includes(user.userLevel))
			}

			await t.saveNewDocumentResponse(usersData)

			await t.sendNewDocumentEmail(usersData, t.form)
		},

		sendNewDocumentEmail(users, document) {
			const t = this

			// Remove all NTUs so they do not get notified
			users = users.filter(user => !user.userIsNtu)

			// Create a list of email addresses
			const userEmails = users.map(user => {
				return user.userEmail
			})

			const createEmail = t.$firebase.functions.httpsCallable('sendNewDocumentEmail')
			createEmail({
				emailAddresses: userEmails,
				documentName: document.documentName,
			})
				.then(result => {
					console.log('Successfully sent New Document emails: ', result)
					t.dialog = false
				})
				.catch(error => {
					console.error('Error sending New Document emails: ', error)
				})

		},

		/**
		 * Save newActionsResponse Document
		 * Creates an actionsResponse document for each user that the actions document is sent to
		 *  **/
		async saveNewDocumentResponse(usersData) {
			const t = this
			const db = t.$firebase.db
			const batch = db.batch()

			for (const user of usersData) {

				const documentResponseObject = {
					id: t.MIX_generateId(),
					createdDateTime: new Date().getTime(),
					documentId: t.form.id,
					firstOpenedDateTime: '',
					hasAccepted: false,
					responseDateTime: '',
					userId: user.id
				}

				const ref = await db.collection('actionsResponses')
					.doc(documentResponseObject.id)

				batch.set(ref, documentResponseObject)

			}

			batch.commit()
				.then(() => console.log("Successfully batched ActionsResponses"))
				.catch(error => console.error('Error batching ActionsResponses: ', error))

		},

		/**
		 * Upload File
		 *
		 * Upon successful saving/updating of a document, also save the file's image path.
		 *
		 * @returns {Promise<void>}
		 */
		async uploadFile() {
			const t = this

			if (t.fileUploadResult !== {}) {

				// Save to the document with: collection | user id | image path
				const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
					'actions',
					t.form.id,
					{documentUrl: t.fileUploadResult.fileURL}
				)

				t.MIX_renderConfirmationAlert(
					updatePhotosResult,
					'Successfully Uploaded File',
					'Error Uploading File'
				)

			}
		},

		/**
		 * Validate Form
		 *
		 * Validate all required form fields before saving.
		 *
		 * @returns {boolean} if the form has passed validation or not
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Document Name
			if (!t.form.documentName?.trim()) {
				t.errors.documentName = true
				t.errors.documentNameErrorMessage = 'Document Name required'
			}
			// User Levels
			if (!t.form.documentUserLevels?.length) {
				t.errors.documentUserLevels = true
				t.errors.documentUserLevelsErrorMessage = 'At least 1 User Level required'
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	watch: {

		/**
		 * Photo Upload Result
		 *
		 * On upload of a profile picture, add its storage path to the fileUploadResult variable.
		 */
		GET_photoUploadResult: {
			async handler() {
				const t = this

				t.fileUploadResult = t.GET_photoUploadResult
				t.form.documentType = t.GET_photoUploadResult.fileType
				t.imagePreview = t.fileUploadResult.fileURL

			}, deep: true
		},

	}

}
</script>

<style scoped>

</style>
