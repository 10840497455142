<template>
	<div class="formCheckBox-container">

		<v-icon :class="isChecked && 'icons8-checkmark'"
				color="success"
				:disabled="disabled"
				size="32"/>

	</div>
</template>

<script>
export default {

	name: "formCheckBox",

	props: ['disabled', 'isChecked'],

}
</script>

<style scoped>
.formCheckBox-container {
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1/1;
	background-color: white;
	border: 1px solid grey;
	border-radius: 8px;
	padding: 8px;
	width: 48px;
	height: 48px;
}
</style>
