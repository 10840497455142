<!--
Unauthorised

Renders a page for user's with accounts that are awaiting approval.
The page is locked down until it is approved.
-->
<template>
    <div class="d-flex flex-column align-center pa-4" style="width: 100%">
        <!--Page title-->
        <page-title :icon="computedPageData.icon" :page-title="computedPageData.title" />

        <v-divider style="width: 100%" />

        <!--Welcome | Information | Refresh Button | ReCaptcha-->
        <div class="d-flex flex-column justify-center">
            <!--Welcome | Information-->
            <v-card class="rounded-lg mt-4 pa-4" flat max-width="600">
                <!--Welcome-->
                <app-text category="text-default-bold">Welcome {{ computedUserName }}!</app-text>

                <!--Information-->
                <app-text category="text-default" class="mt-4">{{ computedPageData.description }}</app-text>

                <!--Instructional text-->
                <app-text category="text-default" class="mt-4">
                    Please contact support@swapp.work for further assistance.
                </app-text>
            </v-card>

            <!--Refresh Button-->
            <app-btn @click.native="handleRefreshButton" :block="true" class="text-capitalize mt-4" color="#CCC" label="Refresh" />
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app'

export default {
    name: 'unauthorised',

    data: () => ({
        errors: {
            verificationCode: false,
            verificationCodeErrorMessage: ''
        },
        isEmailVerified: false,
        userEmail: '',
        userTelephone: '',
        userStatus: 'PENDING',
        userShouldUse2fa: null,
        verificationId: '',
        verificationCode: '',
        isVerificationCodeInputVisible: false,
        userHasPassed2fa: false
    }),

    computed: {
        /**
         * Computed Page Data
         *
         * Returns an icon, page title, and description based on the user's position within authentication.
         * E.g. if the user required 2FA:
         *   true -> verify email > pass 2FA > approved by Admin
         *   false -> approved by Admin
         *
         * @returns {{icon: string, description: string, title: string}}
         */
        computedPageData() {
            const t = this
            const HAS_PASSED_2FA = t.userHasPassed2fa
            const IS_EMAIL_VERIFIED = t.isEmailVerified
            const SHOULD_USE_2FA = t.userShouldUse2fa
            const USER_STATUS = t.userStatus
            let pageData = { description: '', icon: '', title: '' }

            // if (!SHOULD_USE_2FA || HAS_PASSED_2FA) {
            if (t.GET_currentUser.delete) {
                pageData.icon = 'icons8-private'
                pageData.title = 'Deleted'
                pageData.description = 'Your account has been deleted'
            } else if (USER_STATUS.toUpperCase() === 'PENDING') {
                pageData.icon = 'icons8-private'
                pageData.title = 'Awaiting Approval'
                pageData.description = 'Your account is awaiting approval, you will be granted access when it has been approved'
            } else if (USER_STATUS.toUpperCase() === 'SUSPENDED') {
                pageData.icon = 'icons8-private'
                pageData.title = 'Suspended'
                pageData.description = 'Your account has been suspended'
            } else if (USER_STATUS.toUpperCase() === 'REJECTED') {
                pageData.icon = 'icons8-private'
                pageData.title = 'Rejected'
                pageData.description = 'Your account has been rejected'
            } else if (USER_STATUS.toUpperCase() === 'ARCHIVED') {
                pageData.icon = 'icons8-private'
                pageData.title = 'Archived'
                pageData.description = 'Your account has been archived'
            } else if (USER_STATUS.toUpperCase() === 'APPROVED') {
                pageData.icon = 'icons8-checkmark-yes'
                pageData.title = 'Approved'
                pageData.description = 'Your account has been approved. Please refresh the page to continue'
                t.GET_currentUser.userStatus = 'APPROVED'
                t.MIX_go('/')
            }
            return pageData
            // } else if (SHOULD_USE_2FA && IS_EMAIL_VERIFIED) {
            //     pageData.icon = 'icons8-2fa'
            //     pageData.title = '2 Factor Authentication'
            //     pageData.description = `Thanks for verifying your email address, and for opting to keep your account secure. Please enter the authorisation code sent to your mobile number ending ${t.userTelephone.slice(
            //         -4
            //     )} in the box below.`

            //     return pageData
            // } else if (SHOULD_USE_2FA && !IS_EMAIL_VERIFIED) {
            //     pageData.icon = 'icons8-email'
            //     pageData.title = 'Verify Your Email'
            //     pageData.description = `You need to verify your email address before your account can be approved. We've sent an email to ${t.userEmail} with a link for you to follow.`
            //     return pageData
            // }
        },

        /**
         * Computed UserName
         *
         * Returns the current user's first name from their stored userName.
         *
         * @returns {name} - the current user's first name.
         */
        computedUserName() {
            const t = this

            return t.GET_currentUser.userName.split(' ')[0]
        }
    },

    methods: {
        /**
         * Handle Refresh Button
         *
         * Set the action for the button under different statuses.
         */
        handleRefreshButton() {
            const t = this

            t.MIX_go('/')
        },

        validateForm() {
            const t = this

            t.errors.verificationCode = false
            t.errors.verificationCodeErrorMessage = ''

            if (!t.verificationCode.trim() || t.verificationCode.trim().length !== 6) {
                t.errors.verificationCode = true
                t.errors.verificationCodeErrorMessage = 'You must enter a 6 digit code'
            }

            return !Object.values(t.errors).includes(true)
        }
    },

    async created() {
        const t = this
        let unsubscribe = null

        try {
            const currentUser = t.$firebase.auth.currentUser
            if (!currentUser) return

            unsubscribe = t.$firebase.db
                .collection('users')
                .doc(t.GET_currentUser.id)
                .onSnapshot(doc => {
                    if (!doc.exists) {
                        console.warn('No user document found')
                        return
                    }
                    const data = doc.data()
                    t.userStatus = data.userStatus || 'PENDING'
                    t.userEmail = data.userEmail || ''
                    t.userTelephone = data.userTelephone || ''
                    t.userShouldUse2fa = data.userShouldUse2fa || false
                    t.userHasPassed2fa = data.userHasPassed2fa || false

                    if (t.$firebase.auth.currentUser) {
                        t.isEmailVerified = t.$firebase.auth.currentUser.emailVerified
                    }

                    // Only redirect if status is explicitly APPROVED
                    if (data.userStatus === 'APPROVED') {
                        t.MIX_go('/')
                    }
                })
        } catch (error) {
            console.error('Error in unauthorized created hook:', error)
        }

        // Cleanup subscription on component destroy
        this.$once('hook:destroyed', () => {
            if (unsubscribe) unsubscribe()
        })
    }
}
</script>
