<template>
	<v-row no-gutters>

		<page-loading :is-page-loading="isPageLoading"/>

		<v-row v-if="!computedIsPageLoading" no-gutters style="width: 100% !important;">

			<!--Left panel-->
			<v-col :cols="GET_panelLayout.leftPanel" class="pa-4">

				<!--Title-->
				<page-title icon="icons8-skyscrapers"
							pageTitle="Offices"
							description="Create, view and manage offices."
							:divider="true"/>

				<!--Searches-->
				<v-row no-gutters>

					<!--Site Name-->
					<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
						<app-input input-type="textInput"
								   :append-icon="'icons8-search'"
								   :clearable="true"
								   label="Site Name"
								   v-model="filterBySiteName"/>
					</v-col>

					<!--Site Contact Name-->
					<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'px-2'">
						<app-input input-type="textInput"
								   :append-icon="'icons8-search'"
								   :clearable="true"
								   label="Site Contact Name"
								   v-model="filterBySiteContactName"/>
					</v-col>

					<!--Site Contact Position-->
					<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">
						<app-input input-type="textInput"
								   :append-icon="'icons8-search'"
								   :clearable="true"
								   label="Site Contact Position"
								   v-model="filterBySiteContactPosition"/>
					</v-col>

				</v-row>

				<!--Action buttons >= 600-->
				<div
					v-if="(['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Offices: Can Create, View, Edit, and Delete')) && $vuetify.breakpoint.width >= 600"
					class="d-flex justify-end mt-4">

					<!--New button-->
					<app-btn @click.native="openItem('', 'New', false)"
							 color="primary mr-4"
							 icon="icons8-skyscrapers"
							 label="New"/>

					<!--Columns button-->
					<app-btn @click.native="columnsDialog = true"
							 color="primary mr-4"
							 hide-label-below="1060"
							 icon="icons8-select-column"
							 label="Columns"/>

					<!--Export button-->
					<app-btn
						v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel)"
						@click.native="MIX_exportDocuments(headersCSV,'sites',formatExport)"
						color="primary"
						hide-label-below="1060"
						icon="icons8-export-csv"
						label="Export"/>

				</div>

				<!--Action buttons < 600-->
				<app-btn
					v-if="(['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Offices: Can Create, View, Edit, and Delete')) && $vuetify.breakpoint.width < 600"
					@click.native="openItem('', 'New', false)"
					:block="true"
					color="primary mr-4 mt-4"
					icon="icons8-skyscrapers"
					label="New"/>

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  class="rounded-lg mt-4"
							  :headers="computedHeaders"
							  :items="computedTableData"
							  item-key="id"
							  sort-by="siteName">

					<!--Id-->
					<template v-slot:item.id="{ item }">
						<app-text category="text-small">{{ item.id }}</app-text>
					</template>

					<!--Site name-->
					<template v-slot:[`item.siteName`]="{ item }">
						<app-text category="text-small">{{ item.siteName }}</app-text>
					</template>

					<!--Telephone-->
					<template v-slot:[`item.siteTelephone`]="{ item }">
						<app-text category="text-small">{{ item.siteTelephone }}</app-text>
					</template>

					<!--Contact name-->
					<template v-slot:[`item.siteContact.contactName`]="{ item }">
						<app-text category="text-small">{{ item.siteContact.contactName }}</app-text>
					</template>

					<!--Contact position-->
					<template v-slot:[`item.siteContact.contactPosition`]="{ item }">
						<app-text category="text-small">{{ item.siteContact.contactPosition }}</app-text>
					</template>

					<!--Contact telephone-->
					<template v-slot:[`item.siteContact.contactTelephone`]="{ item }">
						<app-text category="text-small">{{ item.siteContact.contactTelephone }}</app-text>
					</template>

					<!--Contact mobile-->
					<template v-slot:[`item.siteContact.contactMobile`]="{ item }">
						<app-text category="text-small">{{ item.siteContact.contactMobile }}</app-text>
					</template>

					<!--Contact email-->
					<template v-slot:[`item.siteContact.contactEmail`]="{ item }">
						<app-text category="text-small">{{ item.siteContact.contactEmail }}</app-text>
					</template>

					<!--Action button-->
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn
							v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Offices: Can Create, View, Edit, and Delete')"
							@click="openItem(item.id, 'View', false)"
							depressed class="primary--text white">
							<v-icon class="icons8-forward" color="primary"/>
						</v-btn>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600">

					<v-card v-for="site in computedTableData" :key="site.id"
							@click="openItem(site.id, 'View', false)"
							class="rounded-lg mt-4 pa-4" flat>

						<!--Site name-->
						<app-text category="text-default-bold">{{ site.siteName }}</app-text>

						<!--Site Contact Name & Position-->
						<app-text category="text-small" class="grey--text">
							{{ `${getSiteDetails(site).name} (${getSiteDetails(site).position})` }}
						</app-text>

						<!--Site Contact Telephone-->
						<app-text category="text-small" class="grey--text">
							{{ getSiteDetails(site).telephone }}
						</app-text>

					</v-card>

				</div>

			</v-col>

			<!--Right panel-->
			<transition name="custom-classes-transition"
						enter-active-class="animate__animated animate__fadeIn animated__faster"
						leave-active-class="animate__animated animate__fadeOut animated__faster"
						mode="out-in">
				<rightpanel></rightpanel>
				<!-- <component :is="dynamicComponent" :key="dynamicComponentKey"></component>      -->
			</transition>

			<!--Column selection box-->
			<v-dialog v-model="columnsDialog" scrollable max-width="300px">
				<v-card>
					<!--Title-->
					<app-text category="text-medium" class="primary--text ma-4">Show/Hide Columns</app-text>

					<v-divider/>

					<!--List items-->
					<v-card-text>
						<v-list>
							<v-list-item v-for="(header, index) in headers.slice(0, -1)" :key="index">
								<app-text category="text-default">
									<v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
												:false-value="true" :true-value="false" :label="header.text"/>
								</app-text>
							</v-list-item>
						</v-list>
					</v-card-text>

					<v-divider/>

					<!--Close button-->
					<v-card-actions class="text-right">
						<v-spacer/>
						<v-btn @click="columnsDialog = false" color="primary" text>Close</v-btn>
					</v-card-actions>

				</v-card>
			</v-dialog>

		</v-row>

	</v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import sitesManagerSelectionModal from './sitesManagerSelectionModal/sitesManagerSelectionModal'

export default {

	name: "Sites",

	components: {
		sitesManagerSelectionModal
	},

	data: () => ({
		title: 'Sites',
		collection: 'sites',
		collectionItem: 'sites',
		collectionItemTitle: 'Site',
		columnsDialog: false,
		customWhere: [],
		exportSites: [],
		filterBySiteName: '',
		filterBySiteContactName: '',
		filterBySiteContactPosition: '',
		headers: [
			{
				text: 'Id',
				value: 'id',
				align: 'start',
				sortable: false,
				hidden: true,
				hide: true
			},
			{
				text: 'Name',
				value: 'siteName',
				align: 'start',
				sortable: false,
				hidden: false,
				hide: false
			},
			// {
			//     text: 'Telephone',
			//     value: 'siteTelephone',
			//     align: 'start',
			//     sortable: false,
			//     hidden: false,
			//     ide: false,
			// },
			{
				text: 'Site Contact Name',
				value: 'siteContact.contactName',
				align: 'start',
				sortable: false,
				hidden: false,
				hide: false,
			},
			{
				text: 'Site Contact Position',
				value: 'siteContact.contactPosition',
				align: 'start',
				sortable: false,
				hidden: false,
				hide: false,
			},
			{
				text: 'Site Contact Telephone',
				value: 'siteContact.contactTelephone',
				align: 'start',
				sortable: false,
				hidden: false,
				hide: false,
			},
			// {
			//     text: 'Site Contact Mobile',
			//     value: 'siteContact.contactMobile',
			//     align: 'start',
			//     sortable: false,
			//     hidden: false,
			//     hide: false,
			// },
			{
				text: 'Site Contact Email',
				value: 'siteContact.contactEmail',
				align: 'start',
				sortable: false,
				hidden: false,
				hide: false,
			},
			{
				text: '',
				value: 'actions',
				align: 'right',
				sortable: false,
				hidden: false,
				hide: false,
				width: '96px'
			},
		],
		headersCSV: {
			siteName: 'Site Name',
			siteTelephone: 'Site Telephone',
			siteAddressLine1: 'Site Address Line 1',
			siteAddressLine2: 'Site Address Line 2',
			siteAddressLine3: 'Site Address Line 3',
			siteTown: 'Site Town',
			siteCounty: 'Site County',
			sitePostcode: 'Site Postcode',
			siteContactName: 'Site Contact Name',
			siteContactPosition: 'Site Contact Position',
			siteContactTelephone: 'Site Contact Telephone',
			siteContactMobile: 'Site Contact Mobile',
			siteContactEmail: 'Site Contact Email',
			siteNotes: 'Site Notes'
		},

		// Data
		sitesCollectionData: [],
		isSitesCollectionDataLoaded: false,
		usersCollectionData: [],
		isUsersCollectionDataLoaded: false,
		isPageLoading: true,
	}),

	computed: {
		...mapGetters({
			GET_panelLayout: 'GET_panelLayout',
			GET_currentUser: 'GET_currentUser',
		}),

		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isSitesCollectionDataLoaded &&
				t.isUsersCollectionDataLoaded
			)

			return t.isPageLoading
		},

		formatExport() {
			const t = this
			const sites = JSON.parse(JSON.stringify(t.sitesCollectionData))
			t.exportSites = []

			for (let i = 0; i < sites.length; i++) {
				// var clientName = "";
				// if (sites[i].clientData.clientName) {
				//     clientName = sites[i].clientData.clientName
				// } else {
				//     clientName = "UNKNOWN"
				// }
				const siteName = "";
				if (sites[i].siteName) {
					siteName = sites[i].siteName
				} else {
					siteName = "UNKNOWN"
				}
				const siteTelephone = "";
				if (sites[i].siteTelephone) {
					siteTelephone = sites[i].siteTelephone
				} else {
					siteTelephone = "UNKNOWN"
				}
				const siteAddressLine1 = "";
				if (sites[i].siteAddress.addressLine1) {
					siteAddressLine1 = sites[i].siteAddress.addressLine1
				} else {
					siteAddressLine1 = "UNKNOWN"
				}
				const siteAddressLine2 = "";
				if (sites[i].siteAddress.addressLine2) {
					siteAddressLine2 = sites[i].siteAddress.addressLine2
				} else {
					siteAddressLine2 = "UNKNOWN"
				}
				const siteAddressLine3 = "";
				if (sites[i].siteAddress.addressLine3) {
					siteAddressLine3 = sites[i].siteAddress.addressLine3
				} else {
					siteAddressLine3 = "UNKNOWN"
				}
				const siteTown = "";
				if (sites[i].siteAddress.town) {
					siteTown = sites[i].siteAddress.town
				} else {
					siteTown = "UNKNOWN"
				}
				const siteCounty = "";
				if (sites[i].siteAddress.county) {
					siteCounty = sites[i].siteAddress.county
				} else {
					siteCounty = "UNKNOWN"
				}
				const sitePostcode = "";
				if (sites[i].siteAddress.postcode) {
					sitePostcode = sites[i].siteAddress.postcode
				} else {
					sitePostcode = "UNKNOWN"
				}
				// const siteArea = "";
				// if (sites[i].siteArea) {
				//     siteArea = sites[i].siteArea
				// } else {
				//     siteArea = "UNKNOWN"
				// }
				// const siteCommercialManager = "";
				// if (sites[i].siteCommercialManager) {
				//     siteCommercialManager = sites[i].siteCommercialManager
				// } else {
				//     siteCommercialManager = "UNKNOWN"
				// }
				// const siteManager = "";
				// if (sites[i].siteManager) {
				//     siteManager = sites[i].siteManager
				// } else {
				//     siteManager = "UNKNOWN"
				// }
				const siteContactName = "";
				if (sites[i].siteContact.contactName) {
					siteContactName = sites[i].siteContact.contactName
				} else {
					siteContactName = "UNKNOWN"
				}
				const siteContactPosition = "";
				if (sites[i].siteContact.contactPosition) {
					siteContactPosition = sites[i].siteContact.contactPosition
				} else {
					siteContactPosition = "UNKNOWN"
				}
				const siteContactTelephone = "";
				if (sites[i].siteContact.contactTelephone) {
					siteContactTelephone = sites[i].siteContact.contactTelephone
				} else {
					siteContactTelephone = "UNKNOWN"
				}
				const siteContactMobile = "";
				if (sites[i].siteContact.contactMobile) {
					siteContactMobile = sites[i].siteContact.contactMobile
				} else {
					siteContactMobile = "UNKNOWN"
				}
				const siteContactEmail = "";
				if (sites[i].siteContact.contactEmail) {
					siteContactEmail = sites[i].siteContact.contactEmail
				} else {
					siteContactEmail = "UNKNOWN"
				}
				// const ppeRequired = "";
				// if (sites[i].ppeRequired) {
				//     ppeRequired = sites[i].ppeRequired
				// } else {
				//     ppeRequired = "UNKNOWN"
				// }
				// const dbsCheck = "";
				// if (sites[i].dbsCheck) {
				//     dbsCheck = sites[i].dbsCheck
				// } else {
				//     dbsCheck = "UNKNOWN"
				// }
				// const siteSector = "";
				// if (sites[i].siteSector) {
				//     siteSector = sites[i].siteSector
				// } else {
				//     siteSector = "UNKNOWN"
				// }
				const siteNotes = "";
				if (sites[i].siteNotes) {
					siteNotes = sites[i].siteNotes
				} else {
					siteNotes = "UNKNOWN"
				}
				this.exportSites.push({
					// clientName: clientName,
					siteName: siteName,
					siteTelephone: siteTelephone,
					siteAddressLine1: siteAddressLine1,
					siteAddressLine2: siteAddressLine2,
					siteAddressLine3: siteAddressLine3,
					siteTown: siteTown,
					siteCounty: siteCounty,
					sitePostcode: sitePostcode,
					// siteArea: siteArea,
					// siteCommercialManager: siteCommercialManager,
					// siteManager: siteManager,
					siteContactName: siteContactName,
					siteContactPosition: siteContactPosition,
					siteContactTelephone: siteContactTelephone,
					siteContactMobile: siteContactMobile,
					siteContactEmail: siteContactEmail,
					// ppeRequired: ppeRequired,
					// dbsCheck: dbsCheck,
					// siteSector: siteSector,
					siteNotes: siteNotes
				})
			}
			return t.exportSites;
		},

		computedHeaders() {
			const t = this
			const headers = t.headers
			let filteredHeaders = []

			headers.forEach(header => {

				// If the column is not hidden, show it
				if (!header.hidden) {
					filteredHeaders.push(header)
				}

			})

			return filteredHeaders
		},

		/**
		 * Computed Table Data
		 *
		 * Sort the sites array in to alphabetical order, A-Z.
		 *
		 * @returns array - array containing the alphabetised list of sites
		 */
		computedTableData() {
			const t = this
			let sitesData = t.sitesCollectionData

			// Filter By Site Name
			if (t.filterBySiteName) {
				sitesData = sitesData.filter(site =>
					site.siteName.toUpperCase().match(t.filterBySiteName.toUpperCase()))
			}

			// Filter By Site Contact Name
			if (t.filterBySiteContactName) {
				sitesData = sitesData.filter(site =>
					site.siteContact?.contactName.toUpperCase().match(t.filterBySiteContactName.toUpperCase()))
			}

			// Filter By Site Contact Position
			if (t.filterBySiteContactPosition) {
				sitesData = sitesData.filter(site =>
					site.siteContact?.contactPosition.toUpperCase().match(t.filterBySiteContactPosition.toUpperCase()))
			}

			sitesData = sitesData.sort((a, b) => {
				return a.siteName > b.siteName ? 1 : -1
			})

			return sitesData
		},

	},

	methods: {
		...mapActions({'ACT_openItem': 'ACT_openSites', 'ACT_openSites': 'ACT_openSites'}),

		/**
		 * Get Collection Data
		 *
		 * Fetch all the data from the collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getUsersCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('users')
				.onSnapshot(snapshot => {

					// Clear the data to avoid duplications
					collectionData = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						// Only add documents that:
						//  - Aren't marked as deleted
						//  - Are Approved
						//  - Have NOT signed up with 2FA
						//  - Have signed up with, and have passed 2FA
						if (!DOCUMENT.hasOwnProperty('delete') &&
							DOCUMENT.userStatus === 'APPROVED' &&
							(!DOCUMENT.userShouldUse2fa || (DOCUMENT.userHasPassed2fa))
						) {
							collectionData.push(DOCUMENT)
						}
					})

					// Sort alphabetically by userName
					collectionData = collectionData.sort((a, b) => a.userName > b.userName ? 1 : -1)

					t.usersCollectionData = collectionData
					t.isUsersCollectionDataLoaded = true
				})
		},

		/**
		 * Get Collection Data
		 *
		 * Fetch all the data from the collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getSitesCollectionData() {
			const t = this
			let sitesCollectionData = []

			await t.$firebase.db.collection('sites')
				.onSnapshot(snapshot => {

					// Clear the data to avoid duplications
					sitesCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only add documents that aren't marked as deleted
						if (!document.hasOwnProperty('delete')) sitesCollectionData.push(document)
					})

					t.sitesCollectionData = sitesCollectionData
					t.isSitesCollectionDataLoaded = true
				})
		},

		async openItem(id, process, popup) {
			const t = this
			const item = {}

			item.process = process
			item.popup = popup

			if (item.process !== 'New') {
				const itemResult = await t.MIX_readDocumentById('sites', id)
				item.data = itemResult.data
			} else {
				item.data = null
			}

			t.ACT_openItem(item)

			if (t.$vuetify.breakpoint.lgAndDown) {
				t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
				t.MIX_setPanelLayout(12, 0, false, '')
			} else {
				t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)

				// * HIDE HEADERS
				for (let i = 0; i < t.headers.length; i++) {
					if (t.headers[i].hide === true) {
						t.headers[i].hidden = true
					}
				}
			}

		},

		/**
		 * Render Confirmation Alert
		 *
		 * Take the response of creating a document and render:
		 *  -> 'Success' alert if all is ok
		 *  -> 'Error' alert if something when wrong
		 */
		renderConfirmationAlert(document, successMessage, failureMessage) {
			const t = this

			if (document.code === 1) {
				t.MIX_alert(1, successMessage, null, null)
			} else {
				t.MIX_alert(-1, failureMessage, null, null)
			}
		},

		/**
		 * Get Site Details
		 *
		 * Return some site details to render in the mobile cards.
		 *
		 * @param site the site data to get the details from
		 * @returns {Object} Site Name, Site Contact Name, Site Contact Position
		 */
		getSiteDetails(site) {
			let name, position, telephone

			if (site.siteContact?.contactName) name = site.siteContact.contactName

			if (site.siteContact?.contactPosition) position = site.siteContact.contactPosition

			if (site.siteContact?.contactTelephone) telephone = site.siteContact.contactTelephone

			return {name, position, telephone}
		},

	},

	async mounted() {
		const t = this

		t.MIX_setPanelLayout(12, 0, false, ''); // Hide Right Panel
		////t.updateBreadcrumbs(); // Update Breadcrumbs in Title
		// INSERT CUSTOM WHERES HERE
		// t.customWhere.push ({ key: '', operator: '==', value: '' });

		await t.getSitesCollectionData()
		await t.getUsersCollectionData()
	}

}
</script>
