<template>
    <div>

        <v-text-field v-if="inputType === 'textInput'"
                      @input="updateValue($event)"
                      :append-icon="appendIcon"
                      :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                      class="rounded-lg"
                      :clearable="clearable"
					  :counter="counter"
                      :disabled="disabled || isFormReadOnly"
                      :error="error"
                      :error-messages="errorMessages"
                      filled
                      flat
                      hide-details="auto"
                      :hint="hint"
                      :label="label"
                      :outlined="!outlined && !isFormReadOnly"
                      :placeholder="placeholder"
                      :type="type && type"
                      :value="value"/>

        <v-textarea v-if="inputType === 'textArea'"
                    @input="updateValue($event)"
                    :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                    class="rounded-lg"
					:clearable="clearable"
					:counter="counter"
                    :disabled="disabled || isFormReadOnly"
                    :error="error"
                    :error-messages="errorMessages"
                    filled
                    flat
                    hide-details="auto"
                    :label="label"
					:maxlength="maxLength"
                    :outlined="!outlined && !isFormReadOnly"
                    :placeholder="placeholder && placeholder"
                    :hint="hint && hint"
                    :rows="rows || 4"
                    :value="value"/>

        <v-select v-if="inputType === 'select'"
                  @input="updateValue($event)"
                  :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                  class="rounded-lg"
                  :clearable="clearable"
                  :chips="chips"
                  :deletable-chips="deletableChips"
                  :disabled="disabled || isFormReadOnly"
                  :error="error"
                  :error-messages="errorMessages"
                  filled
                  flat
                  hide-details="auto"
                  :items="items"
                  :item-text="itemText && itemText"
                  item-value="value"
                  :label="label"
                  :multiple="multiple"
                  :outlined="!outlined && !isFormReadOnly"
                  :return-object="returnObject"
                  :small-chips="smallChips"
                  :value="value"/>

		<v-autocomplete v-if="inputType === 'autocomplete'"
                  @input="updateValue($event)"
                  :background-color="backgroundColor ? 'backgroundColor' : 'white'"
                  class="rounded-lg"
                  :clearable="clearable"
                  :chips="chips"
                  :deletable-chips="deletableChips"
                  :disabled="disabled || isFormReadOnly"
                  :error="error"
                  :error-messages="errorMessages"
                  filled
                  flat
                  hide-details="auto"
                  :items="items"
                  :item-text="itemText && itemText"
                  item-value="value"
                  :label="label"
                  :multiple="multiple"
                  :outlined="!outlined && !isFormReadOnly"
                  :return-object="returnObject"
                  :small-chips="smallChips"
                  :value="value"/>

    </div>
</template>

<script>
export default {
    name: "appInput",

    props: [
        'appendIcon',
        'backgroundColor',
        'clearable',
        'chips',
		'counter',
        'deletableChips',
        'disabled',
        'error',
        'errorMessages',
        'inputType',
        'hint',
        'items',
        'isFormReadOnly',
        'itemText',
        'label',
		'maxLength',
        'multiple',
        'outlined',
        'placeholder',
        'returnObject',
        'rows',
        'smallChips',
        'type',
        'value',
        'vBind',
        'vOn',
    ],

    methods: {
        updateValue(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style>
/* Not being used. This counter-acts the margin-top added when a text field is un-disabled */
.removeMarginTopFromInput input {
    margin-top: 0 !important;
}
</style>
