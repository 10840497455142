<template>

	<v-list nav dense class="sidebar">

		<v-list-item v-for="(item, index) in computedTabs"
					 :key="index"
					 link
					 @click="go(item.path, item.name)"
					 active-class="darkgrey"
					 class="list-item">

			<v-list-item-icon class="white--text">

				<v-icon class="navicons white--text">{{ item.icon }}</v-icon>

				<!--Show a notification for the number of ActionsResponses the current user hasn't accepted -->
				<notification-badge v-if="item.name === 'Actions' && numberOfActionsNotifications"
									:content="numberOfActionsNotifications"
									:dot="false"/>

			</v-list-item-icon>

			<v-list-item-title class="white--text text-left">{{ item.name }}</v-list-item-title>

		</v-list-item>

	</v-list>

</template>

<script>
import {mixinNotifications} from "@/mixin-notifications";
import {mapGetters} from 'vuex'; // Map the Getters from the Vuex Store

export default {

	name: "MenuList",

	mixins: [mixinNotifications],

	data: () => ({
		tabs: [
			// {name: 'Home', path: "/", icon: "icons8-home", userLevels: ['SA']},
			{
				name: 'Locations',
				path: "/dashboard",
				icon: "icons8-doughnut-chart",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager']
			},
			{
				name: 'Directory',
				path: "/staff-directory",
				icon: "icons8-people",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'Actions',
				path: "/actions",
				icon: "icons8-content",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			// {
			// 	name: 'Teamship',
			// 	path: "/observations",
			// 	icon: "icons8-speech-bubble",
			// 	userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			// },
			{
				name: 'Fire Roll Call',
				path: "/fireRollCall",
				icon: "icons8-fires",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager']
			},
			{name: 'Notice Board', path: "/noticeBoards", icon: "icons8-task-completed", userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager']},
			{
				name: 'Offices',
				path: "/sites",
				icon: "icons8-skyscrapers",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant']
			},
			{
				name: 'New Opportunities',
				path: "/newSites",
				icon: "icons8-circled-w",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager']
			},
			{
				name: 'Current Projects',
				path: "/sitesInProgress",
				icon: "icons8-bulldozer",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager']
			},
			{
				name: 'Completed Projects',
				path: "/completedSites",
				icon: "icons8-handshake",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager']
			},
			{
				name: 'My Profile',
				path: "/userProfile",
				icon: "icons8-customer",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Visitor-User']
			},
			{
				name: 'App Support',
				path: "/appSupport",
				icon: "icons8-online-support",
				userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager', 'Staff-User-Office', 'Staff-User-Site', 'Staff-Operative-Groundworker', 'Staff-Operative-Plant', 'Visitor-User']
			},

			// {name: 'Covid', path: "/covidAnswers", icon: "icons8-coronavirus", userLevels: ['Staff-Admin', 'Staff-Senior-Manager', 'Staff-Manager']},
			// {name: 'IT Support', path: "/itSupport", icon: "icons8-computer-support", userLevels: ['SA', 'SU', 'DA']},
			// {name: 'Questions', path: "/questions", icon: "icons8-ask-question", userLevels: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager']},
			// {name: 'My Schedule', path: "/userSchedule", icon: "icons8-schedule", userLevels: ['SA', 'SM', 'SU', 'DA']},
			// {name: 'Settings', path: "/settings", icon: "icons8-settings", userLevels: ['DA']},
		],
		langPath: 'menuItems'
	}),

	computed: {
		...mapGetters({
			GET_mobileNav: 'GET_mobileNav',
			GET_currentUser: 'GET_currentUser'
		}),

		/**
		 * Computed Tabs
		 *
		 * Return a new set of tabs based on the current user's access and permissions.
		 *
		 * IMPORTANT! - The current user's userLevel MUST be granted in the Router userLevels, or they won't see anything
		 *
		 * @returns {[]} array of JSON tabs
		 */
		computedTabs() {
			const t = this
			const tabs = t.tabs
			const newTabs = []

			tabs.forEach(tab => {

				// Give automatic access if the current user's userLevel matches any of the tab's required levels
				if (tab.userLevels.includes(t.GET_currentUser.userLevel)) newTabs.push(tab)

				// Give access if the current user has the correct permissions in Access & Abilities
				else {

					// Dashboard
					if (tab.name === 'Locations' && t.GET_currentUser.userAccessAndAbilities.includes('Dashboard Access')) newTabs.push(tab)

					// Fire Roll Call
					if (tab.name === 'Fire Roll Call' && t.GET_currentUser.userAccessAndAbilities.includes('Fire Roll Call: Can View')) newTabs.push(tab)

					// New Opportunities
					if (tab.name === 'New Opportunities' && t.GET_currentUser.userAccessAndAbilities.includes('New Opportunities: Can View')) newTabs.push(tab)

					// Current Projects
					if (tab.name === 'Current Projects' && t.GET_currentUser.userAccessAndAbilities.includes('Current Projects: Can View')) newTabs.push(tab)

					// Completed Projects
					if (tab.name === 'Completed Projects' && t.GET_currentUser.userAccessAndAbilities.includes('Completed Projects: Can View')) newTabs.push(tab)

					// Notice Board
					if (tab.name === 'Notice Board' && t.GET_currentUser.userAccessAndAbilities.includes('Notice Board: Can Create, View, Edit, and Delete')) newTabs.push(tab)

				}
			})

			return newTabs
		}
	},

	methods: {
		// // updateBreadcrumbs(name, path) {
		// //     var t = this;
		// //     var breadcrumbs = [];
		// //     var uperrcaseName = t.MIX_capitalizeText(name);
		// //     breadcrumbs.push ({ name: uperrcaseName, path: path}) // Set the Main Path
		// //     t.MIX_breadcrumbs(breadcrumbs);
		// // },

		go(path, name) {
			this.$router.push(path).catch((err) => {
				console.log("Route error: " + err)
			})
			this.MIX_mobileNav(false)
			//// this.updateBreadcrumbs(name, path);
		},
	}

}
</script>

<style scoped>

.list-item {
	transition: 0.3s
}

.list-item:hover {
	background: var(--v-accent-base)
}

.navicons {
	font-size: 24px !important;
	font-weight: bold !important;
}

.v-tab--active {
	background-color: var(--v-primary-base) !important;
}
</style>
