<!--
REGISTER

Renders the registration form, containing the following:
	- Email (input)
	- Full Name (input)
	- Contact Number (input)
	- Position (input)
	- T&C's (checkbox)
	- Log In (button)
	- Next (button)
	- Register (link)
	- Rest Password (link)
-->
<template>
	<div class="d-flex justify-center pa-4" style="width: 100%">
		<div style="width: 100%; max-width: 512px">

			<!--Logo-->
			<div class="d-flex justify-center">
				<v-img :src="require('@/assets/images/company-logo-win-blue.svg')"/>
			</div>

			<v-divider class="mt-8"/>

			<!--Title-->
			<app-text category="text-large" class="primary--text mt-8">Register</app-text>

			<!--Instruction-->
			<app-text category="text-default" class="mt-4">
				Please use the form below to register as a user.
			</app-text>

			<!--Registration Form-->
			<firebase-auth-register class="mt-4"/>

			<!--Action Buttons-->
			<div class="d-flex flex-column align-center mt-8">

				<!--Register-->
				<app-text category="text-default" class="justify-center mb-4">Don't have an account?
					<router-link :to="{ path: 'register' }" replace class="btn btn-link">
						Register
					</router-link>
				</app-text>

				<!--Reset-->
				<app-text category="text-default" class="justify-center">Forgot Password?
					<router-link :to="{ path: 'reset-password' }" replace class="btn btn-link">
						Reset password
					</router-link>
				</app-text>

				<app-text class="mt-4">v{{ versionNumber }}</app-text>

			</div>

		</div>
	</div>
</template>

<script>
import {version as appVersionNumber} from '../../../package.json'

export default {

	name: "Register",

	data: () => ({
		versionNumber: appVersionNumber,
	})

};
</script>
