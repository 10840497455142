<!--
Location component

Renders a dialog component to add a new Location to a Site.
-->
<template>
	<v-dialog v-model="GET_location.show"
			  max-width="512">

		<v-card color="appbackground" class="pb-1">

			<!--Title-->
			<app-text category="text-default" class="primary white--text pa-4">Add Location</app-text>

			<!--Location name-->
			<app-input input-type="textInput"
					   class="ma-4"
					   label="Location name"
					   v-model="locationName"/>

			<!--Action buttons-->
			<div class="d-flex ma-4">

				<!--Cancel-->
				<app-btn @click.native="closeLocation"
						 color="grey"
						 icon="icons8-cancel"
						 label="Cancel"/>

				<v-spacer/>

				<!--Save-->
				<app-btn @click.native="saveLocation"
						 class="ml-4"
						 color="success"
						 icon="icons8-save"
						 label="Save"/>
			</div>

		</v-card>

	</v-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {

	data: () => ({
		id: '',
		locationName: '',
		description: '',
		site: {},
	}),

	computed: {
		...mapGetters({
			GET_location: 'location_store/GET_location',
			GET_locationResult: 'location_store/GET_locationResult'
		}),
	},

	methods: {
		...mapActions({
			ACT_locationResult: 'location_store/ACT_locationResult',
		}),

		closeLocation() {
			this.GET_location.show = false
			// this.MIX_location(false, ''); // Hide locations Dialog
		},

		saveLocation: async function () {
			const document = {}

			document.id = this.MIX_generateId();
			document.locationName = this.locationName;
			document.description = this.description;
			document.site = this.GET_location.siteDetails;

			const createDocumentResult = await this.MIX_createDocument('locations', document);

			if (createDocumentResult.code === 1) {
				const locations = this.GET_location.locations // get existing locations

				locations.push(createDocumentResult.data.id); // push new location

				const updateDocumentResult = await this.MIX_updateDocumentFieldsById(this.GET_location.collectionName, this.GET_location.id, {locations: locations})

				if (updateDocumentResult.code === 1) {

					this.id = ''
					this.locationName = ''
					this.description = ''
					this.site = {}

					this.MIX_alert(1, 'location Saved', createDocumentResult.data, null);

					this.ACT_locationResult({
						field: this.GET_location.field,
						value: this.location,
						id: this.GET_location.id,
						locationId: createDocumentResult.data.id
					}); // Save location

					this.MIX_location(false, '', '');

				} else {
					this.MIX_alert(-1, 'ERROR - Could not save location', null, updateDocumentResult.error);
				}
			} else {
				this.MIX_alert(-1, 'ERROR - Could not save location', null, createDocumentResult.error);
			}
		}

	}
}
</script>
