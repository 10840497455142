<template>
    <div
        style="height: 100%; display: flex; flex-direction: column;  justify-content: space-between; align-items: center"
        class="white rounded-lg pa-4 "
    >
        <v-img :src="require('@/assets/logo-sharepoint.png')" contain width="200" height="100" />

        <app-btn :block="true" class="mt-4" color="primary" href="https://waltersgroup.sharepoint.com/sites/BMS/" label="Open Sharepoint" />
    </div>
</template>

<script>
export default {
    name: 'SharePointCard',

    data: () => ({}),

    methods: {}
}
</script>

<style scoped></style>
