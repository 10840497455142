<template>
	<v-card>

		<!--Toolbar-->
		<v-toolbar class="pl-2 primary">

			<app-text category="text-medium" class="white--text">Completed Project</app-text>

			<v-spacer/>

			<close-icon @click.native="closeDialog"/>

		</v-toolbar>

		<!--Content-->
		<div class="pa-4">

			<!--Description-->
			<app-text>
				You are about to mark this project as 'Complete' which will start the process of turning it into a Completed Project.
				<br>
				<br>
				To do this, click the YES button below, complete the checklist, and the following will happen:
				<br>
				<br>
				- The project will be viewable in the Completed Projects page
				<br>
				<br>
				- <strong>Completed Project</strong> email will be sent to all users
				<br>
				<br>
				- <strong>360 Review</strong> calendar reminder will be sent to the project team (dated four weeks after the Contract Award Date)
			</app-text>

			<v-divider class="mt-4"/>

			<!--Instructional Text-->
			<app-text category="text-default-bold" class="mt-4">
				Do you want to mark this project as complete?
			</app-text>

			<!--Action Buttons-->
			<div class="d-flex justify-space-between mt-4">

				<!--No-->
				<app-btn @click.native="closeDialog"
						 color="lightgrey"
						 icon="icons8-cancel"
						 label="Cancel"/>

				<!--Yes-->
				<app-btn @click.native="handleYesButton"
						 color="success"
						 icon="icons8-checkmark-yes"
						 label="Yes"/>

			</div>

		</div>

	</v-card>
</template>

<script>
export default {

	name: "jobCompleteDialog",

	methods: {

		closeDialog() {
			const t = this

			t.$emit('emitCloseJobCompleteDialog')
		},

		handleYesButton() {
			const t = this

			t.$emit('emitSetJobAsComplete')
		},

	},

}
</script>

<style scoped>

</style>
