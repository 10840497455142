<template>
    <div style="width: 100% !important;">
        <div v-if="!is2faVisible" ref="loginForm" class="mt-4" lazy-validation v-model="valid">
            <!--Email-->
            <app-input
                input-type="textInput"
                append-icon="icons8-envelope"
                :error="errors.userEmail"
                :error-messages="errors.userEmail ? errors.userEmailErrorMessage : ''"
                label="Email"
                v-model.trim="userEmail"
            />

            <!--Password-->
            <v-text-field
                @click:append="show = !show"
                :append-icon="show ? 'icons8-eye' : 'icons8-invisible'"
                :background-color="inputBackgroundColor"
                class="rounded-lg mt-4"
                :error="errors.userPassword"
                :error-messages="errors.userPassword ? errors.userPasswordErrorMessage : ''"
                filled
                flat
                hide-details="auto"
                :label="$t('fields.password')"
                outlined
                required
                :type="show ? 'text' : 'password'"
                v-model.trim="userPassword"
                v-on:keyup.enter="validateForm()"
            />

            <!--Action buttons-->
            <div class="d-flex justify-space-between mt-4">
                <!--Register-->
                <app-btn @click.native="MIX_go('/register')" class="mr-2" color="grey" label="Register" style="width: calc(50% - 8px)" />

                <!--Next-->
                <app-btn @click.native="validateForm" class="ml-2" color="primary" label="Next" style="width: calc(50% - 8px)" />
            </div>
        </div>

        <div class="d-flex flex-column align-center mt-4">
            <!--Recaptcha-->
            <div id="recaptcha-container-id" class="g-recaptcha" style="display: none;"></div>

            <div v-if="is2faVisible" style="width: 100%">
                <v-divider />

                <app-text category="text-default-bold" class="mt-4">
                    An authorisation code has been sent to you via SMS, please enter the code in the box below to continue.
                </app-text>

                <!--Verification Code-->
                <app-input
                    input-type="textInput"
                    class="mt-4"
                    :error="errors.verificationCode"
                    :error-messages="errors.verificationCodeErrorMessage"
                    label="Verification Code"
                    style="width: 100%"
                    v-model="verificationCode"
                />

                <!--Action Buttons-->
                <div class="d-flex mt-4" style="width: 100%">
                    <!--Cancel-->
                    <app-btn @click.native="is2faVisible = false" class="mr-2" color="grey" label="Cancel" style="width: calc(50% - 8px)" />

                    <!--Verify-->
                    <app-btn @click.native="get2faResult" class="ml-2" color="success" label="Verify" style="width: calc(50% - 8px)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app'

export default {
    name: 'Login',

    data: () => ({
        inputBackgroundColor: 'white',
        valid: true,
        show: false,
        userEmail: '',
        userPassword: '',
        loading: false, // to show loading bar
        errors: {
            userEmail: false,
            userEmailErrorMessage: '',
            userPassword: false,
            userPasswordErrorMessage: '',
            verificationCode: false,
            verificationCodeErrorMessage: ''
        },
        verificationCode: '',
        recaptchaVerifier: null,
        is2faVisible: false
    }),

    methods: {
        checkCurrentUser(userID) {
            const t = this
            let currentUser = []

            t.$firebase.db
                .collection('users')
                .doc(userID)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        const document = doc.data()
                        document.id = doc.id
                        currentUser = document
                    } else {
                        currentUser = null
                        t.MIX_alert(-1, 'Account not found.', null, null)
                    }
                })

            return currentUser
        },

        /**
         * Validate Form
         *
         * Validate the form before logging in.
         * If there are any errors, an error message will appear under the input.
         * If there are no errors, login() will be called
         */
        validateForm() {
            const t = this

            // Reset errors
            t.errors.userEmail = false
            t.errors.userPassword = false

            // If no email is present
            if (!t.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is required'
            }
            // If email is not valid
            else if (!/.+@.+\..+/.test(t.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is not valid'
            }

            // If no password is present
            if (!t.userPassword.trim()) {
                t.errors.userPassword = true
                t.errors.userPasswordErrorMessage = 'Password is required'
            }

            // If there are no errors, call to log in
            if (!Object.values(t.errors).includes(true)) t.login()
        },

        async login() {
            let t = this

            t.MIX_progressBar({ show: true })

            t.userEmail = t.userEmail.toLowerCase()

            t.$firebase.auth
                .signInWithEmailAndPassword(t.userEmail.trim(), t.userPassword.trim())
                .then(data => {
                    t.loading = true

                    const user = t.checkCurrentUser(data.user.uid)

                    if (user !== null) {
                        t.MIX_progressBar({ show: false })
                        t.MIX_FIREBASE_currentUser(data.user)

                        // TODO REMOVE this line
                        this.$store.commit('SET_currentUser', data.user)
                        t.$router.push('/').catch(async error => {
                            console.error('Login error: ', error.message)
                        })
                    }
                })
                .catch(async error => {
                    // console.error('Error logging in: ', error)
                    // console.error('Error logging in: ', error.code)

                    t.MIX_progressBar({ show: false })

                    // If user not found
                    if (['auth/user-not-found', 'auth/wrong-password'].includes(error.code)) {
                        t.MIX_alert(-1, 'No account has been found with these login details', null, null)
                        return
                    }

                    // If 2FA is required
                    t.is2faVisible = true
                    if (error.code === 'auth/multi-factor-auth-required') {
                        t.resolver = error.resolver
                        t.run2fa()
                    }
                })
        },
        async resetRecaptcha() {
            if (this.recaptchaVerifier) {
                await this.recaptchaVerifier.clear()
            }
            // Get the Firebase app instance first
            const app = firebase.app()

            this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                'recaptcha-container-id',
                {
                    size: 'invisible',
                    callback: response => {
                        console.log('reCAPTCHA verified:', response)
                    }
                },
                app
            ) // Pass the Firebase app instance here
        },

        /**
         * Run 2FA
         *
         * Setup 2FA nd send a verification code to the user's mobile phone.
         *
         * @returns {Promise<void>}
         */
        async run2fa() {
            const t = this

            try {
                // Reset reCAPTCHA before starting verification
                await t.resetRecaptcha()

                await t.recaptchaVerifier.verify

                const phoneInfoOptions = {
                    multiFactorHint: t.resolver.hints[0],
                    session: t.resolver.session
                }

                const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
                const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, t.recaptchaVerifier)
                t.verificationId = verificationId
            } catch (error) {
                console.error('2FA error:', error)
                // If recaptcha fails, reinitialize it
                if (error.code === 'auth/invalid-app-credential') {
                    t.resetRecaptcha()
                }
                t.MIX_alert(-1, 'Failed to send verification code. Please try again.', null, null)
            }
        },

        /**
         * Get 2FA Result
         *
         * Check the verification details and complete the 2FA.
         * If all is good, update the user's document.
         *
         * @returns {Promise<void>}
         */
        async get2faResult() {
            const t = this
            const VERIFICATION_ID = t.verificationId
            const VERIFICATION_CODE = t.verificationCode

            // Only continue if the verification code is valid
            if (!t.validate2faForm()) return

            const CRED = new firebase.auth.PhoneAuthProvider.credential(VERIFICATION_ID, VERIFICATION_CODE)

            const MULTI_FACTOR_ASSERTION = firebase.auth.PhoneMultiFactorGenerator.assertion(CRED)

            try {
                const CREDENTIAL = await t.resolver.resolveSignIn(MULTI_FACTOR_ASSERTION)

                t.MIX_progressBar({ show: false })

                setInterval(() => {
                    if (t.$route.name === 'Login') t.MIX_go('/')
                }, 500)
            } catch (error) {
                console.error('Error verifying code: ', error)

                if (error.code === 'auth/invalid-verification-code') {
                    t.errors.verificationCode = true
                    t.errors.verificationCodeErrorMessage = 'Sorry, this code is not recognised'

                    return
                }
            }
        },

        validate2faForm() {
            const t = this

            t.errors.verificationCode = false
            t.errors.verificationCodeErrorMessage = ''

            if (!t.verificationCode.trim() || t.verificationCode.trim().length !== 6) {
                t.errors.verificationCode = true
                t.errors.verificationCodeErrorMessage = 'You must enter a 6 digit code'
            }

            return !Object.values(t.errors).includes(true)
        }
    },
    beforeDestroy() {
        if (this.recaptchaVerifier) {
            this.recaptchaVerifier.clear()
        }
    },
    mounted() {
        // this.resetRecaptcha()
    }
}
</script>
