<!--
Fire Roll Call Home Page Button

Renders an expansion panel with a link to the Fire Roll Call.
-->
<template>
    <v-expansion-panels
        v-if="['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Fire Roll Call: Can View')"
        class="rounded-lg pa-0" flat>
        <v-expansion-panel>

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex">

                    <!--Icon-->
                    <v-icon class="icons8-fires ma-n1" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">Fire Roll Call</app-text>

                </div>

                <!--Text-->
                <app-text category="text-default" class="mt-4">Start a fire Roll call</app-text>


            </v-expansion-panel-header>

            <!--Body-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Action Button-->
                <div class="mt-4 text-right">
                    <app-btn @click.native="MIX_go('/fireRollCall')"
                             :block="true"
                             color="primary"
                             :height="buttonSizeDefault"
                             label="Fire Roll Call"/>
                </div>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: "fireRollCallHomePageCard"
}
</script>
