<template>
	<v-row class="ma-4" no-gutters>

		<!--Title-->
		<page-title icon="icons8-online-support"
					pageTitle="App Support"
					description="Have any comment or suggestions? Send us a message and we'll help where we can."
					:divider="true"/>

		<!--App support form-->
		<v-form style="width: 100%">

			<!--Support type | Asset number-->
			<v-row no-gutters>

				<!--Title-->
				<v-col :cols="$vuetify.breakpoint.width < 840 ? 12 : 6"
					   :class="$vuetify.breakpoint.width < 840 ? '' : 'pr-2'">
					<app-input inputType="textInput"
							   :error="errors.appSupportTitle"
							   label="Title"
							   v-model.trim="form.appSupportTitle"/>
				</v-col>

				<!--Type-->
				<v-col :cols="$vuetify.breakpoint.width < 840 ? 12 : 6"
					   :class="$vuetify.breakpoint.width < 840 ? 'mt-4' : 'pl-2'">
					<app-input inputType="select"
							   :error="errors.appSupportType"
							   :items="appSupportTypesOptionsData"
							   label="Support Type"
							   v-model="form.appSupportType"/>
				</v-col>

				<!--Description-->
				<v-col :cols="$vuetify.breakpoint.width < 840 ? 12 : 12"
					   :class="$vuetify.breakpoint.width < 840 ? 'mt-4' : 'mt-4'">
					<app-input inputType="textArea"
							   :error="errors.appSupportDescription"
							   label="Description"
							   placeholder="Try and give as much detail as you can so we can help you out"
							   rows="8"
							   v-model.trim="form.appSupportDescription"/>
				</v-col>

			</v-row>

			<!--Action buttons-->
			<div class="d-flex justify-end mt-4">

				<!--Submit-->
				<app-btn @click.native="saveDocument"
						 color="success"
						 label="Submit" icon="icons8-checkmark-yes"/>

			</div>

		</v-form>

	</v-row>
</template>

<script>
export default {

	name: "appSupport",

	data: () => ({
		errors: {
			appSupportType: false,
			appSupportTitle: false,
			appSupportDescription: false,
		},
		form: {
			appSupportType: '',
			appSupportTitle: '',
			appSupportDescription: '',

			createdDateTime: '',
			createdUserData: {},
			modifiedDateTime: '',
			modifiedUserData: {}
		},
	}),

	methods: {

		/**
		 * Clear Fields On Successful Submission
		 *
		 * If the submission was successful, clear the fields to assert completeness.
		 */
		clearFieldsOnSuccessfulSubmission() {
			const t = this

			t.form.appSupportTitle = ''
			t.form.appSupportType = ''
			t.form.appSupportDescription = ''
		},

		/**
		 * Render Confirmation Alert
		 *
		 * Take the response of creating a document and render:
		 *  -> 'Success' alert if all is ok.
		 *  -> 'Error' alert if something when wrong.
		 */
		renderConfirmationAlert(document) {
			const t = this

			if (document.code === 1) {
				t.MIX_alert(1, `App Support request sent`, null, null)
				t.clearFieldsOnSuccessfulSubmission()
			} else {
				t.MIX_alert(-1, `Error sending App Support request`, null, null)
			}
		},

		/**
		 * Save Questions
		 *
		 * Save the document with the collection name and form details.
		 *
		 * @returns {Promise<void>}
		 */
		async saveDocument() {
			const t = this
			let documentResult

			// Only continue if form is valid
			if (!t.validateForm()) return

			// Create document
			documentResult = await t.MIX_createDocument('appSupport', t.form)

			// If document was created successfully, continue with post-processing
			if (documentResult.code === 1) {
				// Send email to Support Desk
				t.sendSupportEmail()
				// Clear fields
				t.clearFieldsOnSuccessfulSubmission()
				// Call for a confirmation alert
				t.renderConfirmationAlert(documentResult)
			}

		},

		/**
		 * Send Support Email
		 *
		 * Send an email to the Support Desk with the details of the support request.
		 */
		sendSupportEmail() {
			const t = this
			const CREATE_EMAIL = t.$firebase.functions.httpsCallable('sendSupportEmail')

			CREATE_EMAIL({
				supportType: t.form.appSupportType,
				supportTitle: t.form.appSupportTitle,
				supportDescription: t.form.appSupportDescription,
				reportersId: t.GET_currentUser.id,
				reportersEmail: t.GET_currentUser.userEmail,
				reportersUserName: t.GET_currentUser.userName,
			})
				.then(() => console.log('Successfully sent Support email'))
				.catch(error => console.error('Error sending Support email', error))
		},

		/**
		 * Validate
		 *
		 * Validate fields for presence, if there are no errors, call to save.
		 */
		validateForm() {
			const t = this

			t.form.appSupportTitle.trim() ? t.errors.appSupportTitle = false : t.errors.appSupportTitle = true
			t.form.appSupportType ? t.errors.appSupportType = false : t.errors.appSupportType = true
			t.form.appSupportDescription.trim() ? t.errors.appSupportDescription = false : t.errors.appSupportDescription = true

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>
