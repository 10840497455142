import Vue from "vue";

const qr_code_generator_mixins = {
	data: () => ({}),
	methods: {
		MIX_generateCode(showPopup, value, size, level, id, renderAs, background, foreground, showValue, locationName, siteName, siteTelephone) {
			var payload = {};
			payload.value = value; // the value content of qrcode
			payload.size = size; //the size of qrcode element.
			// error code correction level - block size is chosen so that no attempt is made at correcting more than 15 errors per block;
			//this limits the complexity of the decoding algorithm
			payload.level = level; // levels: 'L' - low; 'M' - medium; 'Q' - quartile; 'H' - high
			payload.id = id; // element id
			payload.renderAs = renderAs; // generate QRcode as canvas or svg.
			payload.background = background; // background color of qrcode
			payload.foreground = foreground; // foreground color of qrcode
			payload.showValue = showValue;
			payload.showPopup = showPopup;
			payload.locationName = locationName;
			payload.siteName = siteName;
			payload.siteTelephone = siteTelephone;
			this.$store.dispatch('qr_code_generator_store/ACT_qr_code_generator', payload)
		},
	}
}

export default {
	install(Vue, options) {
		Vue.mixin(qr_code_generator_mixins)
	}
};
