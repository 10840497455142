<template>

	<v-btn :block="block"
		   :class="classes || 'rounded-lg'"
		   :color="color ? color : 'primary'"
		   :dark="dark"
		   depressed
		   :disabled="disabled"
		   :height="height ? height : buttonSizeDefault"
		   target="_blank"
		   :href="href">

		<v-icon v-if="icon"
				:class="icon"
				:color="iconColor"
				:size="iconSize ? iconSize : 24"/>

		<app-text v-if="!hideLabelBelow || $vuetify.breakpoint.width > hideLabelBelow"
				  :category="textSize || 'text-default'" :class="icon && label && 'ml-2'">
			{{ label }}
		</app-text>

	</v-btn>

</template>

<script>
export default {

	props: [
		'block',
		'classes',
		'color',
		'dark',
		'disabled',
		'icon',
		'iconColor',
		'iconSize',
		'label',
		'height',
		'hideLabelBelow',
		'target',
		'href',
		'textSize',
	],

	name: "appBtn",

}
</script>

<style scoped>

</style>
