<template>
	<div class="white rounded-lg pa-4">

		<!--Icon | Title-->
		<div class="d-flex">

			<!-- QR code -->
			<v-icon class="icons8-qr-code ma-n1" color="accent" size="48"/>

			<!-- Title -->
			<app-text category="text-medium-bold" class="primary--text ml-4">Your QR Code</app-text>

		</div>

		<!--Text-->
		<app-text category="text-default" class="mt-4">
			This is your QR Code for Reverse-SWAPP
		</app-text>

		<v-divider class="mt-2"/>

		<!--QR Code-->
		<div class="d-flex align-center justify-center ma-4">
			<qrcode-vue class="white rounded-lg pa-8"
						level="H"
						ref="qrcode"
						size="256"
						style="outline: 1px solid lightgrey"
						:value="userData.id"/>
		</div>

	</div>
</template>

<script>

import QrcodeVue from "qrcode.vue";

export default {

	name: "UserQrCode",

	props: ['userData'],

	components: {QrcodeVue},

	data: () => ({}),

	methods: {},

}
</script>

<style scoped>

</style>
