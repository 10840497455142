<template>
	<div>

		<!--Start of USER-INPUT-->
		<v-form>

			<app-input input-type="textInput"
					   append-icon="icons8-envelope"
					   :error="errors.email"
					   :error-messages="errors.emailErrorMessage"
					   label="Email"
					   v-model.trim="email"/>

			<!--Action Buttons (Login | Reset Password)-->
			<div>

				<!--Back to Login-->
				<app-btn @click.native="MIX_go('/login')"
						 :block="true"
						 class="mt-4 grey"
						 color="grey"
						 label="Back to Log In"/>

				<v-spacer/>

				<!--Reset Password-->
				<app-btn @click.native="validateEmail"
						 :block="true"
						 class="mt-4"
						 color="primary"
						 label="Reset Password"/>

			</div>

		</v-form>

	</div>
</template>

<script>
export default {

	name: "ResetPassword",

	data: () => ({
		inputBackgroundColor: "white",
		email: "",
		errors: {
			email: false,
			emailErrorMessage: ''
		},
	}),

	methods: {

		validateEmail() {
			const t = this
			const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


			t.errors.email = false
			t.errors.emailErrorMessage = ''

			if (!t.email.trim()) {
				t.errors.email = true
				t.errors.emailErrorMessage = 'Email required'
			} else if (!emailRegex.test(t.email.trim())) {
				t.errors.email = true
				t.errors.emailErrorMessage = 'Email is not valid'
			}

			if (!Object.values(t.errors).includes(true)) {
				t.resetPassword()
			}
		},

		resetPassword: function () {
			const t = this
			t.email = t.email.toLowerCase()

			t.$firebase.auth
				.sendPasswordResetEmail(t.email)
				.then(function () {
					// Email sent.
					t.MIX_alert(1, "A link to reset your password has been sent to your email address. Check your inbox.", null, null)
				})
				.catch(function (error) {
					t.MIX_alert(-1, error, null, null)
				})

		},
	},

};
</script>
