<template>
    <div>
        <v-progress-linear v-if="loading === true" color="primary" indeterminate />

        <!--Form-->
        <v-window v-model="step">
            <!--User - Step 1 --------------------------------------------------------------------------------- User -->
            <v-window-item :value="1">
                <v-form lazy-validation ref="registerUser" v-model="valid1">
                    <!--Name-->
                    <app-input
                        input-type="textInput"
                        class="mt-4"
                        :error="errors.userName"
                        :error-messages="errors.userNameErrorMessage"
                        label="Full Name"
                        v-model.trim="form.userName"
                    />

                    <!--Email-->
                    <app-input
                        input-type="textInput"
                        class="mt-4"
                        :error="errors.userEmail"
                        :error-messages="errors.userEmailErrorMessage"
                        label="Email"
                        v-model.trim="form.userEmail"
                    />

                    <!--Position-->
                    <app-input
                        input-type="textInput"
                        class="mt-4"
                        :error="errors.userPosition"
                        :error-messages="errors.userPositionErrorMessage"
                        label="Job Title"
                        v-model.trim="form.userPosition"
                    />

                    <!--Contact Number-->
                    <app-input
                        input-type="textInput"
                        class="mt-4"
                        :error="errors.userTelephone"
                        :error-messages="errors.userTelephoneErrorMessage"
                        label="Contact Number"
                        v-model.trim="form.userTelephone"
                    />

                    <!--Admin Reg Code-->
                    <v-text-field
                        v-if="$route.params.id === 'admin'"
                        :background-color="inputBackgroundColor"
                        class="mb-1"
                        filled
                        label="Admin Registration Code"
                        v-model="adminRegCode"
                    />

                    <v-checkbox @change="handleAccessAndAbilities('Fire Marshall')" class="mt-4" hide-details label="I am a Fire Marshall" />
                    <v-checkbox @change="handleAccessAndAbilities('First Aider')" class="mt-4" hide-details label="I am a First Aider" />

                    <!--Data Use Consent-->
                    <v-checkbox
                        class="d-flex align-start mt-8"
                        color="primary"
                        hide-details
                        label="I consent to my email address and telephone number being displayed in-app so I can be contacted if necessary"
                        v-model="form.userCanUsePersonalDetails"
                    />

                    <!--Terms and Conditions-->
                    <v-checkbox
                        class="d-flex align-start mt-8"
                        color="primary"
                        :error="errors.privacyPolicyConsent"
                        hide-details
                        required
                        v-model="form.privacyPolicyConsent"
                    >
                        <template v-slot:label>
                            <div>
                                <span class="font-weight-bold">
                                    {{ $t('firebaseAuth.register.terms') }}
                                    <span @click="privacyDialog = true" class="primary--text font-weight-bold">
                                        {{ $t('firebaseAuth.register.termsLink') }}</span
                                    >
                                </span>
                            </div>
                        </template>
                    </v-checkbox>
                </v-form>

                <!--Action buttons-->
                <v-row no-gutters class="mt-8 mb-4">
                    <!--Login-->
                    <v-col :class="$vuetify.breakpoint.width < 400 ? 'mb-4' : 'pr-2'" :cols="$vuetify.breakpoint.width < 400 ? 12 : 6">
                        <app-btn @click.native="MIX_go('/login')" :block="true" color="grey" label="Back to Login" />
                    </v-col>

                    <v-spacer />

                    <!--Next-->
                    <v-col :class="$vuetify.breakpoint.width < 400 ? 'mb-0' : 'pl-2'" :cols="$vuetify.breakpoint.width < 400 ? 12 : 6">
                        <app-btn @click.native="validateStep1" :block="true" color="primary" label="Next" />
                    </v-col>
                </v-row>
            </v-window-item>

            <!--Password - Step 2 ------------------------------------------------------------------------- Password -->
            <v-window-item :value="2">
                <v-form lazy-validation ref="userPassword" v-model="valid2">
                    <!--Password-->
                    <v-text-field
                        @click:append="showPasswordIcon = !showPasswordIcon"
                        :append-icon="showPasswordIcon ? 'icons8-eye' : 'icons8-invisible'"
                        :background-color="inputBackgroundColor"
                        class="rounded-lg mb-4 pt-1"
                        :error="passwordErrors.password"
                        :error-messages="passwordErrors.passwordErrorMessage"
                        filled
                        flat
                        hide-details="auto"
                        :label="$t('fields.password')"
                        outlined
                        required
                        :type="showPasswordIcon ? 'text' : 'password'"
                        v-model.trim="password"
                    />

                    <!--Confirm Password-->
                    <v-text-field
                        @click:append="showPasswordIcon1 = !showPasswordIcon1"
                        :append-icon="showPasswordIcon1 ? 'icons8-eye' : 'icons8-invisible'"
                        :background-color="inputBackgroundColor"
                        class="rounded-lg"
                        :error="passwordErrors.confirmPassword"
                        :error-messages="passwordErrors.confirmPasswordErrorMessage"
                        filled
                        flat
                        hide-details="auto"
                        :label="$t('fields.confirmPassword')"
                        outlined
                        required
                        :type="showPasswordIcon1 ? 'text' : 'password'"
                        v-model.trim="confirmPassword"
                    />

                    <!-- <v-checkbox class="mt-8" hide-details="auto" label="I want to use 2FA" v-model="form.userShouldUse2fa" />
                    <app-text category="text-small" class="mt-2">
                        Two-Factor-Authentication (2FA) offers higher security by using a second factor to register your account. Uncheck this option
                        if you do not wish to use 2FA
                    </app-text> -->
                </v-form>

                <!--Buttons-->
                <v-row class="my-4">
                    <!--Back-->
                    <v-col cols="6">
                        <app-btn @click.native="step--" :block="true" color="grey" label="Back" />
                    </v-col>

                    <v-spacer />

                    <!--Register-->
                    <v-col cols="6">
                        <app-btn
                            @click.native="validatePasswords"
                            :block="true"
                            color="primary"
                            :disabled="loading"
                            label="Register"
                            :loading="loading"
                        />
                    </v-col>
                </v-row>
            </v-window-item>

            <!--Error message -->
            <v-window-item :value="3">
                <div class="pa-4 text-center" v-if="accountCreated === true">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    <h3 class="title font-weight-light mb-2">Welcome</h3>
                    <span class="caption grey--text">Thanks for signing up! You will be redirected to the home page</span>
                </div>
                <div class="pa-4 text-center" v-else>
                    <h3 class="title font-weight-light mb-2">Something went wrong</h3>
                    <h4 v-if="registerErrorMessage != null">{{ registerErrorMessage }}</h4>
                    <span class="caption grey--text">Try again!</span>
                </div>
            </v-window-item>
        </v-window>

        <!-- End of USER-INPUT -->
        <v-dialog max-width="512" style="z-index: 3000 !important" v-model="privacyDialog">
            <v-card class="background">
                <v-toolbar color="primary" dark
                    >Privacy and Terms & Conditions

                    <v-spacer />

                    <v-icon @click="privacyDialog = false" class="icons8-multiply" color="white" />
                </v-toolbar>

                <PrivacyTermsConditions />

                <v-divider />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PrivacyTermsConditions from '@/components/termsAndConditions.vue'
import PhotoUpload_button_component from '../photoUpload/photoUpload_button_component'
import DatePicker from '@/components/commonComponents/datePicker.vue'

export default {
    name: 'Register',

    components: {
        DatePicker,
        PhotoUpload_button_component,
        PrivacyTermsConditions
    },

    data: () => ({
        accountCreated: true, // show thank you/ try gain message at the end
        adminRegCode: '',
        confirmPassword: '', // check password
        errors: {
            tempUserLevel: false,
            tempUserLevelErrorMessage: '',
            userCompany: false,
            userCompanyErrorMessage: '',
            userEmail: false,
            userEmailErrorMessage: '',
            userName: false,
            userNameErrorMessage: '',
            userPosition: false,
            userPositionErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',
            privacyPolicyConsent: false
        },
        form: {
            id: '',
            authId: '',
            lastLocation: {
                locationId: '',
                locationName: ''
            },
            lastSite: {
                siteId: '',
                siteName: ''
            },
            privacyPolicyConsent: false,
            profilePicFileURL: null,
            swappMethod: null,
            swappStatus: 0,
            userAccessAndAbilities: [],
            userCanUsePersonalDetails: true,
            userCompany: '',
            userEmail: '',
            userLevel: 'Staff-User',
            userName: '',
            userObservationResponsibilities: [],
            userPosition: '',
            userRole: 'User',
            userShouldUse2fa: false,
            userStatus: 'PENDING',
            userTelephone: '',
            userType: 'Staff',
            userVisitorType: '',

            createdDateTime: '',
            createdUserData: {
                userEmail: '',
                userId: '',
                userName: ''
            },
            modifiedDateTime: '',
            modifiedUserData: {
                userEmail: '',
                userId: '',
                userName: ''
            }
        },
        inputBackgroundColor: 'white',
        loading: false,
        loader1: null,
        password: '', // password input
        passwordErrors: {
            password: false,
            passwordErrorMessage: '',
            confirmPassword: false,
            confirmPasswordErrorMessage: '',
            passwordMatch: false
        },
        privacyDialog: false,
        registerErrorMessage: null,
        showPasswordIcon: false,
        showPasswordIcon1: false,
        step: 1,
        tempUserLevel: '',
        valid1: true,
        valid2: true,
        valid3: true,
        valid4: true,

        //check if user was created in admin dashboard
        userInLocalStorage: false,
        userInLocalStorageId: null
    }),

    computed: {
        computedEnvironment() {
            let emailLink

            if (process.env.NODE_ENV === 'demo') emailLink = 'https://walters-swapp-demo.web.app'
            if (process.env.NODE_ENV === 'development') emailLink = 'https://walters-staging-swapp.web.app'
            if (process.env.NODE_ENV === 'production') emailLink = 'https://walters-swapp.web.app'

            return emailLink
        }
    },

    methods: {
        /**
         * Handle Access and Abilities
         *
         * Add/remove the user's Access and Abilities depending on the user type selection.
         */
        handleAccessAndAbilities(optionType) {
            const t = this
            const FORM_DATA = t.form

            // Check if the form.userAccessAndAbilities array contains the optionType
            // If it does, remove it. If it doesn't, add it
            if (!FORM_DATA.userAccessAndAbilities.includes(optionType)) {
                t.form.userAccessAndAbilities.push(optionType)
            } else {
                t.form.userAccessAndAbilities.splice(FORM_DATA.userAccessAndAbilities.indexOf(optionType), 1)
            }
        },

        /**
         * Clear Errors and Messages
         *
         * Clear all the errors and error messages from the inputs.
         */
        clearErrorsAndMessages() {
            const t = this

            for (const error in t.errors) {
                if (typeof t.errors[error] === 'string') t.errors[error] = ''
                if (typeof t.errors[error] === 'boolean') t.errors[error] = false
            }
        },

        /**
         * Validate Form
         *
         * Validate form fields as required.
         * If there are no errors, call to next().
         * If there are errors render them in their respective fields.
         */
        validateStep1() {
            const t = this

            t.clearErrorsAndMessages()

            // No name is present
            if (!t.form.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name is required'
            }

            // No email is present
            if (!t.form.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is required'
            }
            // Email is not valid
            else if (!/.+@.+\..+/.test(t.form.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is not valid'
            }

            // Is a staff member but no job title has been selected
            if (!t.form.userPosition.trim()) {
                t.errors.userPosition = true
                t.errors.userPositionErrorMessage = 'Job Title is required'
            }

            // No contact number is present
            if (!t.form.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Contact number is required'
            }
            // Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (
                ['1', '2', '3'].includes(t.form.userTelephone.trim()[1]) &&
                (t.form.userTelephone.trim().length < 10 || t.form.userTelephone.trim().length > 11)
            ) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.userTelephone.trim()[1]) && t.form.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (['0', '4', '5', '6', '8', '9'].includes(t.form.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // Terms and Conditions
            if (!t.form.privacyPolicyConsent) {
                t.errors.privacyPolicyConsent = true
            }

            // If there are no errors, call for the next section
            if (!Object.values(t.errors).includes(true)) t.goToStep2()
        },

        goToStep2() {
            const t = this

            t.step = 2
        },

        async checkUserInLocalStorage(email) {
            const t = this

            return new Promise(function (resolve, reject) {
                t.$firebase.db
                    .collection('users')
                    .where('userEmail', '==', email)
                    .get()
                    .then(function (querySnapshot) {
                        if (querySnapshot.docs.length === 0) {
                            t.userInLocalStorage = false
                            return resolve(t.userInLocalStorage)
                        } else {
                            t.userInLocalStorage = true
                            t.userInLocalStorageId = querySnapshot.docs[0].id
                            return resolve(t.userInLocalStorage)
                        }
                    })
                    .catch(function (error) {
                        return reject(error)
                    })
            })
        },

        setUserDetails(uid) {
            const t = this

            t.form.id = uid // * assign firebase auth user id to firebase user in collection
            t.form.createdDateTime = t.$moment().format('x')
            t.form.createdUserData.userEmail = t.form.userEmail
            t.form.createdUserData.userName = t.form.userName
            t.form.createdUserData.userId = uid
        },

        sendVerificationEmail() {
            const t = this
            console.log('sendVerificationEmail...')

            const ENV_URL = t.computedEnvironment

            // t.$firebase.auth.currentUser.sendEmailVerification({url: `${ENV_URL}/unauthorised`})
            t.$firebase.auth.currentUser
                .sendEmailVerification()
                .then((response) => {
                    t.MIX_alert(2, 'An account verification email has been sent to you.', null, null)
                })
                .catch((error) => {
                    console.error('Error sending email verification email: ', error)
                    t.MIX_alert(-1, error, null, null)
                })
        },

        redirectToHomepage() {
            const t = this
            setTimeout(() => {
                t.$router.push('/').catch((error) => {
                    console.log(error.message)
                })
            }, 1500) // ? added time out to allow the current user to be set
        },

        async register() {
            const t = this

            // if (this.$refs.userPassword.validate()) {

            this.step++ // * move to the next step
            t.form.userEmail = t.form.userEmail.toLowerCase()

            // * CHECK IF USER WAS CREATED FROM THE ADMIN DASHBOARD
            const userExists = await this.checkUserInLocalStorage(t.form.userEmail)

            // * if the user exists in the db
            if (userExists) {
                // * create user in authentication
                t.$firebase.auth
                    .createUserWithEmailAndPassword(t.form.userEmail, t.password)
                    .then((user) => {
                        // * set user details
                        t.setUserDetails(user.user.uid)

                        // * update the user from the collection
                        const fields = {
                            id: t.userInLocalStorageId,
                            authId: user.user.uid,
                            modifiedDateTime: t.form.modifiedDateTime,
                            modifiedUserData: t.form.modifiedUserData,
                            userName: t.form.userName,
                            userPosition: t.form.userPosition,
                            userTelephone: t.form.userTelephone,
                            userLevel: t.form.userLevel,
                            userRole: t.form.userRole,
                            userType: t.form.userType
                        }

                        t.$firebase.db
                            .collection('users')
                            .doc(t.userInLocalStorageId)
                            .update(fields)
                            .then((result) => {
                                this.MIX_alert(1, 'Your account was created.', null, null)
                                t.$store.commit('SET_currentUser', user)
                            })
                            .catch((error) => {
                                this.MIX_alert(-1, error, null, null)
                                console.error(error)
                            })

                        t.accountCreated = true
                        // * assign this user to the current logged in user
                        t.$store.commit('SET_currentUser', user)
                        this.MIX_alert(1, 'Your account was created.', null, null)

                        // * send verification email to user
                        if (t.form.userShouldUse2fa) t.sendVerificationEmail()

                        // * redirect user to homepage
                        t.redirectToHomepage()
                    })
                    .catch((err) => {
                        // ! firebase registering error
                        t.registerErrorMessage = err
                        t.accountCreated = false
                        console.log('firebase registering error: ' + err)
                        t.MIX_alert(-1, err, null, null)
                    })
            } else {
                // * create user
                t.$firebase.auth
                    .createUserWithEmailAndPassword(t.form.userEmail, t.password)
                    .then((user) => {
                        // * set user details
                        t.setUserDetails(user.user.uid)

                        // * add to the users collection
                        t.form.authId = user.user.uid
                        t.$firebase.db
                            .collection('users')
                            .doc(user.user.uid)
                            .set(t.form)
                            .then((Result) => {})
                            .catch((err) => {
                                console.log(err)
                            })

                        t.accountCreated = true
                        // * assign this user to the current logged-in user
                        t.$store.commit('SET_currentUser', user)
                        this.MIX_alert(1, 'Your account was created.', null, null)

                        // * send verification email to user
                        if (t.form.userShouldUse2fa) t.sendVerificationEmail()

                        // * redirect user to homepage
                        t.redirectToHomepage()
                    })
                    .catch((err) => {
                        // ! firebase registering error
                        t.registerErrorMessage = err
                        t.accountCreated = false
                        console.log('firebase registering error: ' + err)
                        t.MIX_alert(-1, err, null, null)
                    })
            }
        },

        /**
         * Validate Passwords
         *
         * Validate password fields as required.
         * If there are no errors, call to register().
         * If there are errors render them in their respective fields.
         */
        validatePasswords() {
            const t = this

            // Reset any errors
            t.passwordErrors.password = false
            t.passwordErrors.passwordErrorMessage = ''
            t.passwordErrors.confirmPassword = false
            t.passwordErrors.confirmPasswordErrorMessage = ''
            t.passwordErrors.passwordMatch = false

            // No password is present
            if (!t.password.trim()) {
                t.passwordErrors.password = true
                t.passwordErrors.passwordErrorMessage = 'Password is required'
            }
            // Password is less than 6 characters
            else if (t.password.trim().length < 6) {
                t.passwordErrors.password = true
                t.passwordErrors.passwordErrorMessage = 'Password must be 6 characters or more'
            }

            // No confirm password is present
            if (!t.confirmPassword.trim()) {
                t.passwordErrors.confirmPassword = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Password confirmation is required'
            }
            // Confirm password is less than 6 characters
            else if (t.confirmPassword.trim().length < 6) {
                t.passwordErrors.confirmPassword = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Password must be 6 characters or more'
            }

            // Passwords don't match
            if (t.password !== t.confirmPassword) {
                t.passwordErrors.passwordMatch = true
                t.passwordErrors.confirmPasswordErrorMessage = "Passwords don't match"
            }

            // If there are no errors, call to register
            if (!Object.values(t.passwordErrors).includes(true)) t.register()
        }
    },

    watch: {
        loader1() {
            const l = this.loader1
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader1 = null
        }
    }
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}
</style>
