<template>
    <div>
        <h1>Tasks</h1>
        <h2>Firebase CRUD Function Tests</h2>
        <v-row>
            <v-col cols="12"><h3>Firestore Collection</h3></v-col>
            <v-col cols="3"><v-text-field label="collectionName" v-model="collectionName" filled hide-details background-color="white" dense clearable></v-text-field></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><h3>Test 1. CREATE Test Document in <strong>{{ collectionName }}</strong> Firestore Collection</h3></v-col>
            <v-col cols="3"><v-text-field label="id" v-model="documentId" filled hide-details background-color="white" dense clearable></v-text-field></v-col>
            <v-col cols="3"><v-btn @click="createDocument">Test 1</v-btn><br /></v-col>
            <v-col cols="3"><pre class="caption">{{ createDocumentResult }}</pre></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><h3>Test 2. READ Document from <strong>{{ collectionName }}</strong> Firestore Collection</h3></v-col>
            <v-col cols="3"><v-text-field label="id" v-model="documentId" filled hide-details background-color="white" dense clearable></v-text-field></v-col>
            <v-col cols="3"><v-btn @click="readDocument">Test 2</v-btn><br /></v-col>
            <v-col cols="3"><pre class="caption">{{ readDocumentResult }}</pre></v-col>
        </v-row>
         <v-row>
            <v-col cols="12"><h3>Test 3. READ Documents from <strong>{{ collectionName }}</strong> Firestore Collection</h3></v-col>
            <v-col cols="3"><v-btn @click="readDocuments">Test 3</v-btn><br /></v-col>
            <v-col cols="3"><pre class="caption">{{ readDocumentsResult }}</pre></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><h3>Test 4. DELETE Document {{ documentId }} from <strong>{{ collectionName }}</strong> Firestore Collection</h3></v-col>
            <v-col cols="3"><v-text-field label="id" v-model="documentId" filled hide-details background-color="white" dense clearable></v-text-field></v-col>
            <v-col cols="3"><v-btn @click="deleteDocument">Test 4</v-btn><br /></v-col>
            <v-col cols="3"><pre class="caption">{{ deleteDocumentResult }}</pre></v-col>
        </v-row>
        <v-row>
            <v-col cols="12"><h3>Test 5. DELETE Document Permanently {{ documentId }} from <strong>{{ collectionName }}</strong> Firestore Collection</h3></v-col>
            <v-col cols="3"><v-text-field label="id" v-model="documentId" filled hide-details background-color="white" dense clearable></v-text-field></v-col>
            <v-col cols="3"><v-btn @click="deleteDocumentPermanently">Test 5</v-btn><br /></v-col>
            <v-col cols="3"><pre class="caption">{{ deleteDocumentPermanentlyResult }}</pre></v-col>
        </v-row>
         <v-row>
            <v-col cols="12"><h3>Test 6. UPDATE Value Field to false in Document {{ documentId }} from <strong>{{ collectionName }}</strong> Firestore Collection</h3></v-col>
            <v-col cols="3"><v-text-field label="id" v-model="documentId" filled hide-details background-color="white" dense clearable></v-text-field></v-col>
            <v-col cols="3"><v-btn @click="updateDocumentFieldsById">Test 6</v-btn><br /></v-col>
            <v-col cols="3"><pre class="caption">{{ updateDocumentFieldsResult }}</pre></v-col>
        </v-row>
    </div>
</template>


<script>
export default {
    data: () => ({
        collectionName: 'test',
        documents: [],
        documentId: '',
        createDocumentResult: {},
        readDocumentResult: {},
        readDocumentsResult: {},
        deleteDocumentResult: {},
        deleteDocumentPermanentlyResult: {},
        updateDocumentFieldsResult: {}


    }),
    methods: {
        createDocument: async function() {
            if (this.documentId === '') {
                this.createDocumentResult = await this.MIX_createDocument(this.collectionName, { value: true });
            } else {
                this.createDocumentResult = await this.MIX_createDocument(this.collectionName, { id: this.documentId, value: true });
            }
            if (this.createDocumentResult.data !== null) {
                this.documentId = this.createDocumentResult.data.id;
            }
        },
        readDocument: async function() {
            this.readDocumentResult = await this.MIX_readDocumentById(this.collectionName, this.documentId);
        },
        readDocuments: async function() {
            this.readDocumentsResult = await this.MIX_readDocuments(this.collectionName);
            if (this.readDocumentsResult.code === 1) {
                this.documents = this.readDocumentsResult.data;
            }
        },
        updateDocumentFieldsById: async function() {
            this.updateDocumentFieldsResult = await this.MIX_updateDocumentFieldsById(this.collectionName, this.documentId, { value: false });
        },
        deleteDocument: async function() {
            this.deleteDocumentResult = await this.MIX_deleteDocument(this.collectionName, this.documentId);
        },
        deleteDocumentPermanently: async function() {
            this.deleteDocumentPermanentlyResult = await this.MIX_destroyDocument(this.collectionName, this.documentId);
        }

    },
    async mounted() {
       console.log('Mounted - Firebase CRUD Function Tests');
    }
}
</script>
