<template>
	<v-row no-gutters>

		<!--Page Loader-->
		<page-loading :is-page-loading="isPageLoading"/>

		<!--Page Content-->
		<v-row v-if="!computedIsPageLoading" no-gutters style="height: 100%">

			<!--Left Panel-->
			<v-col v-if="computedSplitScreenColumns.left"
				   :cols="computedSplitScreenColumns.left"
				   class="pa-4">

				<!--Title-->
				<page-title icon="icons8-task-completed"
							page-title="Notice Board"
							description="Create Notice Board entries or administer existing notices, which will be shown on the Home page."
							:divider="true"/>

				<!--Filters-->
				<v-row no-gutters>

					<!--Name-->
					<v-col :class="$vuetify.breakpoint.width <= 600 ? '' : 'pr-2'"
						   :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6">

						<app-input v-model.trim="filterByNoticeTitle"
								   append-icon="icons8-search"
								   :clearable="true"
								   input-type="textInput"
								   label="Search"/>

					</v-col>

				</v-row>

				<!--Action Buttons-->
				<div class="d-flex justify-end mt-4">

					<!--New-->
					<app-btn v-if="!isRightPanelOpen"
							 @click.native="openRightPanel"
							 :block="$vuetify.breakpoint.width <= 600"
							 icon="icons8-task-completed"
							 label="New"/>

				</div>

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600"
							  :headers="computedTableHeaders"
							  :items="computedTableData"
							  class="rounded-lg my-4">

					<!--Id-->
					<template v-slot:item.id="{item}">
						<app-text category="text-small">{{ item.id }}</app-text>
					</template>

					<!--Notice Visibility-->
					<template v-slot:item.noticeVisibility="{item}">
						<v-icon v-if="item.noticeVisibility === 'Visible'"
								class="icons8-checkmark-yes" color="success" size="32"/>
						<v-icon v-if="item.noticeVisibility === 'Hidden'"
								class="icons8-cancel" color="error" size="32"/>
					</template>

					<!--Notice Priority-->
					<template v-slot:item.noticePriority="{item}">
						<v-icon v-if="item.noticePriority === 'Default'" class="icons8-filled-circle mr-2"
								color="success" size="24"/>
						<v-icon v-if="item.noticePriority === 'Medium'" class="icons8-filled-circle mr-2"
								color="warning" size="24"/>
						<v-icon v-if="item.noticePriority === 'High'" class="icons8-filled-circle mr-2" color="error"
								size="24"/>
						<!--<app-text category="text-small">{{ item.noticePriority }}</app-text>-->
					</template>

					<!--Notice Title-->
					<template v-slot:item.noticeTitle="{item}">
						<app-text category="text-small">{{ item.noticeTitle }}</app-text>
					</template>

					<!--Actions-->
					<template v-slot:item.actions="{item}">
						<v-btn class="primary--text white"
							   depressed @click="openRightPanel(item)">
							<v-icon class="icons8-forward pa-0" color="primary"/>
						</v-btn>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600">

					<v-card v-for="notice in computedTableData" :key="notice.id"
							@click="openRightPanel(notice)"
							class="d-flex align-center rounded-lg mt-4 pa-4" flat>

						<!--Visibility-->
						<div class="mr-2">
							<v-icon v-if="notice.noticeVisibility === 'Visible'"
									class="icons8-checkmark-yes" color="success" size="24"/>
							<v-icon v-if="notice.noticeVisibility === 'Hidden'"
									class="icons8-cancel" color="error" size="24"/>
						</div>

						<!--Priority-->
						<div class="mr-2">
							<v-icon v-if="notice.noticePriority === 'Default'"
									class="icons8-filled-circle" color="success" size="24"/>
							<v-icon v-if="notice.noticePriority === 'Medium'"
									class="icons8-filled-circle" color="error" size="24"/>
							<v-icon v-if="notice.noticePriority === 'High'"
									class="icons8-filled-circle" color="error" size="24"/>
						</div>

						<!--Title-->
						<app-text category="text-default-bold">{{ notice.noticeTitle }}</app-text>

					</v-card>

				</div>

			</v-col>

			<!--Right panel-->
			<v-col v-if="isRightPanelOpen"
				   :class="computedSplitScreenColumns.left && 'borderLeft'"
				   :cols="computedSplitScreenColumns.right">

				<!--Top Bar-->
				<div class="d-flex align-center justify-space-between appbackground pa-4">

					<!--Title-->
					<app-text v-if="$vuetify.breakpoint.width >= 600" category="text-medium">
						{{ selectedNotice && selectedNotice.noticeTitle ? selectedNotice.noticeTitle : 'New' }}
					</app-text>

					<v-spacer/>

					<div>

						<!--Edit Icon-->
						<edit-icon :isActive="!isFormReadOnly" @click.native="editForm"/>

						<!--Delete Icon-->
						<delete-icon-dialog v-if="(selectedNotice && selectedNotice.id) && tabs === 'overview'"
											:disabled="!isFormReadOnly"
											:item-to-delete="selectedNotice.noticeTitle"
											v-on:emitDeleteFromDialog="emittedDeleteFromDialog"/>

						<!--Close Icon-->
						<close-icon @click.native="closeRightPanel"/>

					</div>

				</div>

				<!--Tabs (Overview)-->
				<v-tabs v-model="tabs" show-arrows>

					<v-tabs-slider color="accent"/>

					<v-tab href="#overview">
						<app-text category="text-small">Overview</app-text>
					</v-tab>

				</v-tabs>

				<!--Tab Content (Overview | Contacts | Contact Form | Passes)-->
				<v-tabs-items v-model="tabs">

					<!--Overview Tab Content-->
					<v-tab-item class="appbackground pa-4" value="overview">

						<notice-boards-form :doesFormStartReadOnly="isFormReadOnly"
											:formData="selectedNotice"
											v-on:emitCloseRightPanel="emittedCloseRightPanel"/>

					</v-tab-item>

				</v-tabs-items>

			</v-col>

		</v-row>

	</v-row>
</template>

<script>
import NoticeBoardsForm from "@/views/noticeBoards/noticeBoardsForm/noticeBoardsForm";

export default {

	name: "noticeBoard",

	components: {NoticeBoardsForm},

	data: () => ({
		filterByNoticeTitle: '',
		isRightPanelOpen: false,
		isFormReadOnly: true,
		selectedNotice: {},
		tableHeaders: [
			{
				text: 'Id',
				value: 'id',
				sortable: false,
				align: 'left',
				hiddenByDefault: true,
				hideWithWidth: true
			},
			{
				text: 'Visibility',
				value: 'noticeVisibility',
				sortable: false,
				align: 'center',
				hiddenByDefault: false,
				hideWithWidth: false,
				width: '82px',
			},
			{
				text: 'Priority',
				value: 'noticePriority',
				sortable: false,
				align: 'center',
				hiddenByDefault: false,
				hideWithWidth: false,
				width: '82px',
			},
			{
				text: 'Title',
				value: 'noticeTitle',
				sortable: false,
				align: 'left',
				hiddenByDefault: false,
				hideWithWidth: false
			},
			// {
			//     text: 'Tenants',
			//     value: 'noticeTenantGroups',
			//     sortable: false,
			//     align: 'left',
			//     hiddenByDefault: false,
			//     hideWithWidth: false
			// },
			{
				text: '',
				value: 'actions',
				sortable: false,
				align: 'right',
				hiddenByDefault: false,
				hideWithWidth: false,
				width: '96px',
			},
		],
		tabs: '',

		// Collection Data
		noticeBoardsCollectionData: [],
		isNoticeBoardsCollectionDataLoaded: false,
		// tenantsCollectionData: [],
		// isTenantsCollectionDataLoaded: false,
		isPageLoading: true,
	}),

	computed: {

		/**
		 * Computed Is Page Loading
		 *
		 * Return a boolean for the page loading spinner to denote if all data has been loaded.
		 *
		 * @returns {boolean} if data has been loaded or not
		 */
		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !t.isNoticeBoardsCollectionDataLoaded

			return t.isPageLoading
		},

		/**
		 * Computed Split Screen Columns
		 *
		 * Calculate the width of the left snd right panels, based on the currently selected functionality.
		 * The left panel has the table, the right panel has the forms.
		 *
		 * @returns {{left: number, right: number}}
		 */
		computedSplitScreenColumns() {
			const t = this
			let left = 12
			let right = 0

			if (t.$vuetify.breakpoint.width >= 1200) {
				if (t.isRightPanelOpen) {
					left = 6
					right = 6
				}
			} else {
				if (t.isRightPanelOpen) {
					left = 0
					right = 12
				}
			}

			return {left, right}
		},

		/**
		 * Computed Table Headers
		 *
		 * Using the tableHeaders data, hide the fields that aren't required.
		 *
		 * @returns {Array} an array of table header objects
		 */
		computedTableHeaders() {
			const t = this
			let tableHeaders = t.tableHeaders

			tableHeaders = tableHeaders.filter(header => !header.hiddenByDefault)

			if (t.computedSplitScreenColumns.left < 12) {
				tableHeaders = tableHeaders.filter(header => !header.hideWithWidth)
			}

			return tableHeaders
		},

		/**
		 * Computed Table Data
		 *
		 * Create an array of custom JSON objects with the required Organisation and Contact data.
		 *
		 * @returns {Array} Array of processed Organisations JSON data
		 */
		computedTableData() {
			const t = this
			let tableData = t.noticeBoardsCollectionData

			if (t.filterByNoticeTitle) {
				tableData = tableData.filter(data =>
					data.noticeTitle.toUpperCase().match(t.filterByNoticeTitle.toLocaleUpperCase()))
			}

			tableData = tableData.sort((a, b) => {
				return a.noticeTitle > b.noticeTitle ? 1 : -1
			})

			return tableData
		},

	},

	methods: {

		async getNoticeBoardsCollectionData() {
			const t = this
			let noticeBoardsCollectionData = []

			await t.$firebase.db.collection('noticeBoards')
				.onSnapshot(snapshot => {

					noticeBoardsCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						if (!document.hasOwnProperty('delete')) noticeBoardsCollectionData.push(document)

					})
					t.noticeBoardsCollectionData = noticeBoardsCollectionData
					t.isNoticeBoardsCollectionDataLoaded = true
				})
		},

		/**
		 * Close Right Panel
		 *
		 * When closing the Organisation form, reset it to ensure it opens as new.
		 */
		closeRightPanel() {
			const t = this

			t.isFormReadOnly = true
			t.isRightPanelOpen = false
		},

		/**
		 * Edit Form
		 *
		 * Toggle the read only state of the form.
		 */
		editForm() {
			const t = this

			t.isFormReadOnly = !t.isFormReadOnly;
		},

		/**
		 * Emitted Close Right Panel
		 *
		 * Take the emitted message and call to close the right panel.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedCloseRightPanel() {
			const t = this

			t.closeRightPanel()
		},

		/**
		 * Emitted Delete From Dialog
		 *
		 * Take the emitted message from the 'deleteIconDialog', and mark the selected Organisation as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async emittedDeleteFromDialog() {
			const t = this

			const deleteDocument = await t.MIX_deleteDocument('noticeBoards', t.selectedNotice.id)

			t.closeRightPanel()

			t.MIX_renderConfirmationAlert(
				deleteDocument,
				'Successfully Deleted Notice',
				'Error Deleting Notice')
		},

		/**
		 * Open Right Panel
		 *
		 * Open the CRUD form.
		 * If the form is opened using the new button, it will be blank.
		 * If the form is opened from the table, it will be assigned here and populated.
		 *
		 * @param notice JSON containing the selected Notice data, if required
		 */
		openRightPanel(notice) {
			const t = this

			t.selectedNotice = notice

			t.isRightPanelOpen = true
		},

	},

	async mounted() {
		const t = this

		await t.getNoticeBoardsCollectionData()
	}

}
</script>

<style scoped>
.borderLeft {
	border-left: 4px solid #999999;
	min-height: calc(100vh - 128px);
}
</style>
