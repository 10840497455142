var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-text',{attrs:{"category":"text-default"}},[_vm._v(" Use the form below to Add Locations to Sites, so Users can SWAPP in. ")]),_c('v-divider',{staticClass:"my-4"}),_c('app-btn',{staticClass:"mr-2",attrs:{"color":"success","icon":"icons8-edit-property","label":"Add New Location"},nativeOn:{"click":function($event){return _vm.MIX_location(
					true,
					'externalSites',
					_vm.form.id,
					_vm.form.locations,
					{
						id: _vm.form.id,
						siteName: _vm.form.projectTitle,
						siteManager: _vm.form.projectBidLead,
						sitePostcode: _vm.form.projectLocationPostcode
					})}}}),_c('v-spacer'),_c('v-data-table',{staticClass:"rounded-lg mt-4",attrs:{"headers":_vm.locationsHeaders,"items":_vm.locationsTableData,"items-per-page":10},scopedSlots:_vm._u([{key:`item.locationName`,fn:function({ item }){return [_c('app-text',{attrs:{"category":"text-default"}},[_vm._v(_vm._s(item.locationName))])]}},{key:`item.staff`,fn:function({ item }){return [_c('app-text',{staticClass:"justify-center",attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(_vm.noOfUsersAtLocation(item.id, 'Staff'))+" ")])]}},{key:`item.totalCount`,fn:function({ item }){return [_c('app-text',{staticClass:"justify-center",attrs:{"category":"text-default"}},[_vm._v(" "+_vm._s(_vm.noOfUsersAtLocation(item.id, ''))+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"icons8-qr-code",attrs:{"color":"success","size":"32"},on:{"click":function($event){return _vm.openQrCode(item.id, item, item.locationName, _vm.form.siteTelephone)}}}),_c('v-icon',{staticClass:"icons8-trash ml-4",attrs:{"color":"error","size":"32"},on:{"click":function($event){return _vm.MIX_deleteCollectionArray(item.id, item, 'locations', 'externalSites', _vm.form.id)}}})]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }