<template>
    <qrcode-vue :value="qrcodeOptions.value" 
                :size="qrcodeOptions.size" 
                :level="qrcodeOptions.level" 
                :id="qrcodeOptions.id" 
                :renderAs="qrcodeOptions.renderAs" 
                :background="qrcodeOptions.background" 
                :foreground="qrcodeOptions.foreground">
    </qrcode-vue> 
</template>

<script>
import QrcodeVue from "qrcode.vue";
import * as htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import { mapState } from "vuex";

export default {
    name: 'QrCodeGenerator',
    components: {
        QrcodeVue
    },
    computed: {
        ...mapState('qr_code_generator_store/', {
            qrcodeOptions: state => state.qrcode_generator,
        })
    },
    methods: {
        closeQrCode() {
            this.MIX_generateCode(false, '', 0, "H", "qrcode", "canvas", "#ffffff", "#000000", false, ''); 
        },
        exportCode() {
            window.print();
         },
        downloadWithVueResource() {
            htmlToImage.toBlob(document.getElementById("downloadCode")).then(function(blob) {
                window.saveAs(blob, "QRcode.png");
            });
        },
    }
}
</script>

<style>
@media print {
  .noprint {
    display: none !important;
    clear: both !important;
  }
}
</style>