<!--
Question

Renders the form to add/edit/delete questions.
-->
<template>
    <div>

        <!--Header-->
        <v-row class="ma-0 pa-4" no-gutters>

            <!--Title-->
            <app-text category="text-default">{{ form.questionTitle ? form.questionTitle : 'New' }}</app-text>

            <v-spacer/>

            <!--Action buttons-->
            <div>

                <!--Edit-->
                <v-icon v-if="formMode !== 'New'"
                        @click.native="editDocument"
                        class="icons8-edit"
                        :class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"
                        :color="formReadOnly ? 'warning' : 'white'"
                        size="32"/>

                <!--Delete-->
                <v-icon v-if="formMode !== 'New'"
                        @click.native="deleteDocument(form.id)"
                        class="icons8-trash frc-icon"
                        color="error"
                        size="32"/>

            </div>

        </v-row>

        <!--Page Content-->
        <v-row no-gutters class="ma-0">
            <v-col xs="6" lg="12">

                <!--Tabs-->
                <v-tabs v-model="tabs" :height="buttonSizeDefault">

                    <v-tabs-slider color="accent"/>

                    <!--Overview-->
                    <v-tab href="#overview">
                        <app-text category="text-small">Overview</app-text>
                    </v-tab>

                </v-tabs>

                <!--Tab Content-->
                <v-tabs-items v-model="tabs" class="appbackground">

                    <!--Overview Tab Content-->
                    <v-tab-item value="overview" class="d-flex flex-column pa-0">

                        <!--Title | Action buttons-->
                        <v-row class="pa-4" no-gutters>

                            <!--Title | Instructions-->
                            <v-col cols="12">

                                <!--Instructions-->
                                <app-text category="text-default">
                                    To add a new question, please fill out the form below.
                                    Some of the fields will accept multiple entries,
                                    which you can enter by checking the boxes in the fields.
                                </app-text>

                                <v-divider class="mt-4"/>

                            </v-col>

                        </v-row>

                        <!--Form-->
                        <v-form>

                            <v-row class="px-4" no-gutters>

                                <!--Question Title-->
                                <v-col cols="12">
                                    <app-input input-type="textInput"
                                               class=""
                                               label="Title (optional)"
                                               :is-form-read-only="formReadOnly"
                                               v-model.trim="form.questionTitle"/>
                                </v-col>

                                <!--Question Name-->
                                <v-col cols="12">
                                    <app-input input-type="textInput"
                                               class="mt-4"
                                               :error="errors.questionName"
                                               :error-messages="errors.questionNameErrorMessage"
                                               label="Question"
                                               :is-form-read-only="formReadOnly"
                                               v-model="form.questionName"/>
                                </v-col>

                                <!--Question Description-->
                                <v-col cols="12">
                                    <app-input input-type="textArea"
                                               class="mt-4"
                                               :is-form-read-only="formReadOnly"
                                               label="Description (optional)"
                                               v-model.trim="form.questionDescription"/>
                                </v-col>

                                <!--Question Answers-->
                                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <app-input input-type="select"
                                               chips
                                               class="mt-4"
                                               :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                                               deletable-chips
                                               :error="errors.questionAnswers"
                                               :error-messages="errors.questionAnswersErrorMessage"
                                               :is-form-read-only="formReadOnly"
                                               :items="answersOptionsData"
                                               label="Answers"
                                               multiple
                                               small-chips
                                               v-model="form.questionAnswers"/>
                                </v-col>

                                <!--Question Correct Answers-->
                                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <app-input input-type="select"
                                               chips
                                               class="mt-4"
                                               :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                                               deletable-chips
                                               :error="errors.questionCorrectAnswers"
                                               :error-messages="errors.questionCorrectAnswersErrorMessage"
                                               :is-form-read-only="formReadOnly"
                                               :items="correctAnswersOptionsData"
                                               label="Correct Answers"
                                               multiple
                                               small-chips
                                               v-model="form.questionCorrectAnswers"/>
                                </v-col>

                                <!--Question Position-->
                                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <app-input input-type="textInput"
                                               class="mt-4"
                                               :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                                               label="Position"
                                               :is-form-read-only="formReadOnly"
                                               v-model="form.questionPosition"/>
                                </v-col>

                                <!--Question Site IDs-->
                                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <app-input input-type="select"
                                               chips
                                               class="mt-4"
                                               :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                                               deletable-chips
                                               :error="errors.questionSiteIDs"
                                               :error-messages="errors.questionSiteIDsErrorMessage"
                                               :is-form-read-only="formReadOnly"
                                               :items="sitesLookUp"
                                               item-text="siteName"
                                               label="Sites"
                                               multiple
                                               return-object
                                               small-chips
                                               v-model="form.questionSiteIDs"/>
                                </v-col>

                                <!--Question User Types-->
                                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <app-input input-type="select"
                                               chips
                                               class="mt-4"
                                               :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                                               deletable-chips
                                               :error="errors.questionUserCategories"
                                               :error-messages="errors.questionUserCategoriesErrorMessage"
                                               :is-form-read-only="formReadOnly"
                                               :items="userTypesOptionsDataExtended"
                                               label="User Types"
                                               multiple
                                               small-chips
                                               v-model="form.questionUserCategories"/>
                                </v-col>

                                <!--Question Types-->
                                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                    <app-input input-type="select"
                                               chips
                                               class="my-4"
                                               :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                                               deletable-chips
                                               :error="errors.questionTypes"
                                               :error-messages="errors.questionTypesErrorMessage"
                                               :is-form-read-only="formReadOnly"
                                               :items="questionTypesOptionsData"
                                               label="Question Types"
                                               multiple
                                               small-chips
                                               v-model="form.questionTypes"/>
                                </v-col>

                            </v-row>

                            <!--Save button-->
                            <div class="d-flex ma-4 mt-0">
                                <v-spacer/>
                                <app-btn v-if="formMode === 'Edit' || formMode === 'New'"
                                         @click.native="validateForm"
                                         color="success"
                                         icon="icons8-save"
                                         label="Save"/>
                            </div>

                        </v-form>

                    </v-tab-item>

                </v-tabs-items>

            </v-col>
        </v-row>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {

    name: "Questions",

    data: () => ({
        collection: 'questions',
        collectionTitle: 'Questions',
        collectionItem: 'question',
        collectionItemTitle: 'Question',
        errors: {
            questionName: false,
            questionNameErrorMessage: '',
            questionAnswers: false,
            questionAnswersErrorMessage: '',
            questionCorrectAnswers: false,
            questionCorrectAnswersErrorMessage: '',
            questionPosition: false,
            questionPositionErrorMessage: '',
            questionSiteIDs: false,
            questionSiteIDsErrorMessage: '',
            questionUserCategories: false,
            questionUserCategoriesErrorMessage: '',
            questionTypes: false,
            questionTypesErrorMessage: '',
        },
        form: {
            id: '',
            questionTitle: '',
            questionName: '',
            questionDescription: '',
            questionAnswers: [],
            questionCorrectAnswers: [],
            questionPosition: '',
            questionSiteIDs: [],
            questionUserCategories: [],
            questionTypes: []
        },
        formMode: 'New',
        formReadOnly: false,
        questionAnswers: [],
        questionCorrectAnswers: [],
        questionSites: [],
        questionUserCategories: [],
        sitesLookUp: [],
        tabs: null,
    }),

    computed: ({
        ...mapGetters({
            GET_openItem: 'GET_openQuestion',
            GET_sites: 'GET_sites',
            GET_lookupValues: 'GET_lookupValues',
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
        })
    }),

    methods: {
        ...mapActions({ACT_openItem: "ACT_openQuestion", ACT_openQuestion: "ACT_openQuestion"}),

        /**
         * Get Sites Collection Data
         *
         * Fetch the data from the collection.
         * Iterate over the collection and push documents not marked as deleted.
         */
        getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.orderBy('siteName')
                      .onSnapshot(snapshot => {

                // Clear the data to avoid duplicates
                t.sitesLookup = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesLookUp.push(document)
                    }

                })
            })
        },

        /**
         * Validate
         *
         * Validates the required fields for presence only.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this

            t.errors.questionName = false
            t.errors.questionNameErrorMessage = ''
            t.errors.questionAnswers = false
            t.errors.questionAnswersErrorMessage = ''
            t.errors.questionCorrectAnswers = false
            t.errors.questionCorrectAnswersErrorMessage = ''
            t.errors.questionPosition = false
            t.errors.questionPositionErrorMessage = ''
            t.errors.questionSiteIDs = false
            t.errors.questionSiteIDsErrorMessage = ''
            t.errors.questionUserCategories = false
            t.errors.questionUserCategoriesErrorMessage = ''
            t.errors.questionTypes = false
            t.errors.questionTypesErrorMessage = ''

            // Question
            if (!t.form.questionName.trim()) {
                t.errors.questionName = true
                t.errors.questionNameErrorMessage = 'Question required'
            }

            // Answers
            if (!t.form.questionAnswers.length) {
                t.errors.questionAnswers = true
                t.errors.questionAnswersErrorMessage = 'Answers required'
            }

            // Correct Answers
            if (!t.form.questionCorrectAnswers.length) {
                t.errors.questionCorrectAnswers = true
                t.errors.questionCorrectAnswersErrorMessage = 'Correct Answers required'
            }

            // Sites
            if (!t.form.questionSiteIDs.length) {
                t.errors.questionSiteIDs = true
                t.errors.questionSiteIDsErrorMessage = 'Sites required'
            }

            // User Categories
            if (!t.form.questionUserCategories.length) {
                t.errors.questionUserCategories = true
                t.errors.questionUserCategoriesErrorMessage = 'User Categories required'
            }

            // User Categories
            if (!t.form.questionTypes.length) {
                t.errors.questionTypes = true
                t.errors.questionTypesErrorMessage = 'Question Types required'
            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                this.saveDocument()
            }
        },

        /**
         * Save Questions
         *
         * Save or update the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this
            let createDocumentResult

            // If the formMode is New, create a new document
            if (t.formMode === 'New') {
                createDocumentResult = await t.MIX_createDocument(t.collection, t.form)
            }

            // If the formMode is Edit, update the current document
            else if (t.formMode === 'Edit') {
                createDocumentResult = await t.MIX_updateDocument(t.collection, t.form)
            }

            // If the document saved/updated successfully, set the form back to viewable state
            if (createDocumentResult.code === 1) {
                t.formMode = 'View'
                t.formReadOnly = true
                await t.refreshDocumentView();
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Edit Document
         *
         * Toggle the form between an editable, and a viewable state.
         */
        editDocument() {
            const t = this

            if (t.formReadOnly) {
                t.formMode = 'Edit'
                t.formReadOnly = false
            } else {
                t.formMode = 'View'
                t.formReadOnly = true
                t.refreshDocumentView()
            }

        },

        /**
         * Delete Document
         *
         * Call for the confirmation box to delete a document.
         * The deletion is handled in this file's watch -> GET_confirmBoxResult handler function.
         */
        deleteDocument: function (id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong></div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        /**
         * Cancel Document
         *
         * Set the form back to a readable state and refresh the data.
         */
        cancelDocument() {
            const t = this
            t.formMode = 'View'
            t.formReadOnly = true
            t.refreshDocumentView();
            t.closeRightPanel();
        },

        /**
         * Refresh Document View
         *
         * Load the new document and sets the payload onto ACT_openItem
         */
        async refreshDocumentView() {
            const t = this;

            const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
            t.form = itemResult.data
            let payload = {}
            payload.process = 'View';
            payload.popup = false;
            payload.data = itemResult.data;

            await t.ACT_openItem(payload);
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
            }
        }

    },

    watch: {
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === 'questions') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteDocumentResult = await t.MIX_deleteDocument('questions', t.GET_confirmBoxResult.data.id);

                        if (deleteDocumentResult.code === 1) {
                            t.MIX_alert(1, `Question Deleted`, deleteDocumentResult.data, null);
                            await t.refreshDocumentView();
                        } else {
                            t.MIX_alert(-1, `Error Deleting Question`, null, null);
                        }
                    }
                } else if (t.GET_confirmBoxResult.data.collection === 'questions/destroy') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const destroyDocumentResult = await t.MIX_destroyDocument('questions', t.GET_confirmBoxResult.data.id);

                        if (destroyDocumentResult.code === 1) {
                            t.MIX_alert(1, `Question Destroyed`, destroyDocumentResult.data, null);
                            t.MIX_setPanelLayout(12, 0, false, '', '');
                        } else {
                            t.MIX_alert(-1, `Error Destroying Question`, null, null);
                        }
                    }
                }

            }, deep: true
        }
    },

    async mounted() {
        const t = this

        // Get required  collection data
        await t.getSitesCollectionData()

        // Get and populate form data
        const openItem = await t.GET_openItem
        t.formMode = openItem.process;
        if (openItem.process === 'New') {
            t.formReadOnly = false
        } else {
            t.form = openItem.data
            t.formReadOnly = true
        }

        // if (openItem.popup === false) {
        // 	t.updateBreadcrumbs()
        // }
    }

}
</script>
