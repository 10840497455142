<!--
TERMS & CONDITIONS

Renders the T&Cs page.
Header comes from App.vue.
-->
<template>
	<div class="d-flex flex-column align-center pa-4">

		<!--Instructions-->
		<app-text category="text-default">
			Click on the links below to read our Privacy Policy and Terms & Conditions
		</app-text>

		<!--Privacy Policy-->
		<v-btn class="primary mt-4" style="width: 204px" href="https://walters-group.co.uk/privacy-policy/" target="_blank" :height="buttonSizeDefault">Privacy Policy</v-btn>

		<!--Terms and Conditions-->
		<v-btn class="primary mt-4" style="width: 204px" href="https://walters-group.co.uk/terms-and-conditions/" target="_blank" :height="buttonSizeDefault">Terms & Conditions</v-btn>


	</div>
</template>

<script>
export default {
	name: "PrivacyTermsConditions",
};
</script>
