<template>
	<div>

		{{ computedInit }}

		<!--Page Content-->
		<div>

			<!--Tabs (Report | Response)-->
			<v-tabs class="border-bottom-grey" v-model="tabs" :height="buttonSizeDefault">
				<v-tabs-slider color="accent" background-color="black"></v-tabs-slider>

				<!--Report-->
				<v-tab href="#report">
					<app-text category="text-small">Report</app-text>
				</v-tab>

				<!--Response-->
				<v-tab href="#response">
					<app-text category="text-small">Response</app-text>
				</v-tab>

			</v-tabs>

			<!--Tab Content (Report | Response)-->
			<v-tabs-items v-model="tabs" class="appbackground">

				<!--Reporting Tab Content-->
				<v-tab-item value="report" class="mb-4">

					<!--Locked report message-->
					<app-text v-if="form.id" class="red--text mt-4 ml-4">
						This report has been submitted and cannot be edited.
					</app-text>

					<!--Form-->
					<v-row class="pa-4 pt-0" no-gutters>

						<!--Title-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
							<app-input inputType="textInput"
									   :error="errors.observationTitle"
									   :error-messages="errors.observationTitleErrorMessage"
									   :is-form-read-only="computedIsFormReadOnly"
									   label="Title"
									   placeholder="Please provide a title"
									   v-model.trim="form.observationTitle"/>
						</v-col>

						<!--Description-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
							<app-input inputType="textArea"
									   :error="errors.observationReportingDescription"
									   :error-messages="errors.observationReportingDescriptionErrorMessage"
									   :is-form-read-only="computedIsFormReadOnly"
									   label="Description"
									   placeholder="Please provide as much detail as you can"
									   v-model.trim="form.observationReportingDescription"/>
						</v-col>

						<!--Site-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
							<app-input inputType="select"
									   :error="errors.observationSite"
									   :error-messages="errors.observationSiteErrorMessage"
									   :is-form-read-only="computedIsFormReadOnly"
									   :items="computedSitesData"
									   item-text="siteName"
									   label="Site"
									   :return-object="true"
									   v-model="selectedSiteObject"/>
						</v-col>

						<!--Location-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
							<app-input inputType="textInput"
									   :error="errors.observationSiteLocation"
									   :error-messages="errors.observationSiteLocationErrorMessage"
									   :is-form-read-only="computedIsFormReadOnly"
									   label="Location"
									   placeholder="Please provide details of the location"
									   v-model.trim="form.observationSiteLocation"/>
						</v-col>

						<!--People Involved-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
							<app-input inputType="textArea"
									   :error="errors.observationReportingPeopleInvolved"
									   :error-messages="errors.observationReportingPeopleInvolvedErrorMessage"
									   :is-form-read-only="computedIsFormReadOnly"
									   label="Who was involved?"
									   placeholder="Please provide details of those involved"
									   v-model.trim="form.observationReportingPeopleInvolved"/>
						</v-col>

						<!--Priority-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
							<app-input inputType="select"
									   :error="errors.observationPriority"
									   :error-messages="errors.observationPriorityErrorMessage"
									   :is-form-read-only="computedIsFormReadOnly"
									   :items="observationPriorityOptionsData"
									   label="Priority"
									   v-model="form.observationPriority"/>
						</v-col>

						<!--Who to Notify-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
							<app-input inputType="autocomplete"
									   :deletable-chips="true"
									   :error="errors.observationUsersToNotify"
									   :error-messages="errors.observationUsersToNotifyErrorMessage"
									   :is-form-read-only="computedIsFormReadOnly"
									   :items="usersData"
									   item-text="userName"
									   label="Who to Notify"
									   :multiple="true"
									   :return-object="true"
									   :small-chips="true"
									   v-model="selectedUsersArray"/>
						</v-col>

						<!--Date of Incident-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
							<date-picker @emitDate="handleDatePicker"
										 :date="form.observationReportingIncidentDate"
										 :error="errors.observationReportingIncidentDate"
										 :error-messages="errors.observationReportingIncidentDateErrorMessage"
										 :is-form-read-only="computedIsFormReadOnly"
										 label="Date Incident Occurred"
										 :minDate="[1, 'month', 'past']"
										 :maxDate="[0, 'years', 'future']"/>
						</v-col>

						<!--Time of Incident-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
							<time-picker @emitTime="handleObservationTime"
										 :error="errors.observationReportingIncidentTime"
										 :error-messages="errors.observationReportingIncidentTimeErrorMessage"
										 :is-form-read-only="computedIsFormReadOnly"
										 label="Time Incident Occurred"
										 :time="form.observationReportingIncidentTime"/>
						</v-col>

						<!--Images-->
						<div class="d-flex justify-space-between" style="width: 100%">
							<form-section-title class="mt-4"
												description="Add supporting images"
												title="Images"/>

							<!--Photo upload-->
							<photoupload v-if="form.observationReportingFiles.length < 3"
										 allowed-types="image/*"
										 comment="reporting"
										 :disabled="!!form.id"
										 :doc-link="{collection: 'observations', documentId: form.id}"
										 folder="/observations"
										 label="Add"/>
						</div>
						<v-col class="mt-4"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
							   style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 16px">

							<!--Image One-->
							<div class="d-flex align-center justify-center rounded-lg"
								 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

								<v-icon v-if="!form.observationReportingFiles[0]" class="icons8-image-document"
										size="48"/>
								<v-img v-else
									   @click="openFilePreview(form.observationReportingFiles[0])"
									   aspect-ratio="1"
									   :src="form.observationReportingFiles[0]"
									   class="rounded-lg ma-2"
									   width="48"/>

							</div>

							<!--Image Two-->
							<div class="d-flex align-center justify-center rounded-lg"
								 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

								<v-icon v-if="!form.observationReportingFiles[1]" class="icons8-image-document"
										size="48"/>
								<v-img v-else
									   @click="openFilePreview(form.observationReportingFiles[1])"
									   aspect-ratio="1"
									   :src="form.observationReportingFiles[1]"
									   class="rounded-lg ma-2"
									   width="48"/>

							</div>

							<!--Image Three-->
							<div class="d-flex align-center justify-center rounded-lg"
								 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

								<v-icon v-if="!form.observationReportingFiles[2]" class="icons8-image-document"
										size="48"/>
								<v-img v-else
									   @click="openFilePreview(form.observationReportingFiles[2])"
									   aspect-ratio="1"
									   :src="form.observationReportingFiles[2]"
									   class="rounded-lg ma-2"
									   width="48"/>

							</div>


						</v-col>

					</v-row>

					<!--Save Button-->
					<div class="d-flex justify-end mr-4">

						<app-btn v-if="!computedIsFormReadOnly"
								 @click.native="validateReportingForm"
								 color="success"
								 icon="icons8-save"
								 label="Save"/>

					</div>

					<form-meta-data class="ma-4" :form-data="form"/>

				</v-tab-item>

				<!--Response Tab Content-->
				<v-tab-item value="response">

					<!--Locked report message-->
					<app-text v-if="computedIsFormReadOnly" class="red--text mt-4 ml-4">
						{{ respondingFormAccessMessage }}
					</app-text>

					<!--Form-->
					<v-row class="pa-4 pt-0" no-gutters>

						<!--Responding User-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 8 : 9">
							<app-input input-type="textInput"
									   :disabled="true"
									   label="Responder's Name"
									   v-model="selectedRespondingUserObject.userName"/>
						</v-col>

						<!--Reference Number-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pl-2' : 'mt-4 pl-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 4 : 3">
							<app-input input-type="textInput"
									   :disabled="true"
									   label="Reference"
									   v-model="form.observationReferenceNumber"/>
						</v-col>

						<!--Actions Taken - Read Only-->
						<v-col v-for="item in form.observationResponses"
							   :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
							<div
								style="background: white; border: 1px solid #AAAAAA; border-radius: 8px; padding: 12px">
								<app-text category="text-small" class="grey--text">
									{{ item.responseUserName }}
									-
									{{ $moment(Number(item.responseDateTime)).format('ddd do MMM YYYY @ HH:mm') }}
								</app-text>
								<v-divider class="mt-2"/>
								<app-text class="grey--text mt-2">{{ item.responseText }}</app-text>
							</div>
						</v-col>

						<!--Actions Taken-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
							<app-input input-type="textArea"
									   :error="errors.observationRespondingActionTaken"
									   :error-messages="errors.observationRespondingActionTakenErrorMessage"
									   :is-form-read-only="computedIsFormReadOnly"
									   label="Add action taken"
									   placeholder="What actions were taken to avoid any recurrence (We Did)"
									   v-model.trim="observationRespondingActionTaken"/>
						</v-col>

						<!--Status-->
						<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
							<app-input input-type="select"
									   :disabled="!['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager'].includes(GET_currentUser.userLevel)"
									   :is-form-read-only="computedIsFormReadOnly"
									   :items="observationStatusOptionsData"
									   label="Status"
									   v-model="temp_observationStatus"/>
						</v-col>

						<!--Images-->
						<div class="d-flex justify-space-between" style="width: 100%">
							<form-section-title class="mt-4"
												description="Add supporting images"
												title="Images"/>

							<!--Photo upload-->
							<photoupload v-if="form.observationRespondingFiles.length < 3"
										 allowed-types="image/*"
										 comment="responding"
										 :disabled="computedIsFormReadOnly || form.observationStatus === 'Resolved'"
										 :doc-link="{collection: 'observations', documentId: form.id}"
										 folder="/observations"
										 label="Add"/>
						</div>
						<v-col class="mt-4"
							   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
							   style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 16px">

							<!--Image One-->
							<div class="d-flex align-center justify-center rounded-lg"
								 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

								<v-icon v-if="!form.observationRespondingFiles[0]" class="icons8-image-document"
										size="48"/>
								<v-img v-else
									   @click="openFilePreview(form.observationRespondingFiles[0])"
									   aspect-ratio="1"
									   :src="form.observationRespondingFiles[0]"
									   class="rounded-lg ma-2"
									   width="48"/>

							</div>

							<!--Image Two-->
							<div class="d-flex align-center justify-center rounded-lg"
								 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

								<v-icon v-if="!form.observationRespondingFiles[1]" class="icons8-image-document"
										size="48"/>
								<v-img v-else
									   @click="openFilePreview(form.observationRespondingFiles[1])"
									   aspect-ratio="1"
									   :src="form.observationRespondingFiles[1]"
									   class="rounded-lg ma-2"
									   width="48"/>

							</div>

							<!--Image Three-->
							<div class="d-flex align-center justify-center rounded-lg"
								 style="aspect-ratio: 1/1; border: 4px dashed lightgrey;">

								<v-icon v-if="!form.observationRespondingFiles[2]" class="icons8-image-document"
										size="48"/>
								<v-img v-else
									   @click="openFilePreview(form.observationRespondingFiles[2])"
									   aspect-ratio="1"
									   :src="form.observationRespondingFiles[2]"
									   class="rounded-lg ma-2"
									   width="48"/>

							</div>


						</v-col>

					</v-row>

					<!--Save Button-->
					<div class="d-flex justify-end mr-4">

						<!--Save-->
						<app-btn v-if="!computedIsFormReadOnly"
								 @click.native="validateRespondingForm"
								 class="d-flex align-center"
								 color="success"
								 icon="icons8-save"
								 label="Save"/>

					</div>

					<form-meta-data class="ma-4" :form-data="form"/>

				</v-tab-item>

			</v-tabs-items>

		</div>

		<!--Dialogs ------------------------------------------------------------------------------------------------ -->

		<!--File Preview Dialog-->
		<v-dialog max-width="75vw" v-model="isFilePreviewVisible">
			<v-toolbar class="d-flex justify-end rounded-t-lg" color="primary">
				<delete-icon-dialog @emitDeleteFromDialog="deleteFile(selectedFile)"
									item-to-delete="this file."/>
				<close-icon @click.native="closeFilePreview"/>
			</v-toolbar>
			<v-img :src="selectedFile" class="rounded-b-lg"/>
		</v-dialog>

		<!--Save Confirmation-->
		<v-dialog max-width="512" v-model="isSaveConfirmationDialogVisible">
			<v-toolbar class="d-flex justify-end rounded-t-lg" color="primary">
				<close-icon @click.native="closeSaveConfirmationDialog"/>
			</v-toolbar>
			<div class="white pa-4">

				<!--Information Text-->
				<app-text>
					You are about to save a new Observation Report.
					<br>
					<br>
					Please note this report cannot be edited after it has been saved, so ensure it is completed
					to the best of your knowledge, and any evidence has been added.
					<br>
					<br>
					<span class="font-weight-bold">Do you want to save it now?</span>
				</app-text>

				<!--Action Buttons-->
				<div class="d-flex justify-space-between mt-4">

					<!--Cancel-->
					<app-btn @click.native="closeSaveConfirmationDialog"
							 color="lightgrey" icon="icons8-cancel" label="Cancel"/>

					<!--Save-->
					<app-btn @click.native="saveDocument" icon="icons8-save" label="Save"/>

				</div>

			</div>
		</v-dialog>

	</div>
</template>

<script>
import {mapGetters} from 'vuex'

import TimePicker from "../../../components/commonComponents/timePicker.vue";
import DatePicker from "../../../components/commonComponents/datePicker.vue"

export default {

	name: "observationsForm",

	components: {
		TimePicker,
		DatePicker
	},

	props: ['doesFormStartReadOnly', 'externalSitesData', 'formData', 'sitesData', 'usersData'],

	data: () => ({
		errors: {
			observationType: false,
			observationTypeErrorMessage: '',
			observationTitle: false,
			observationTitleErrorMessage: '',
			observationReportingDescription: false,
			observationReportingDescriptionErrorMessage: '',
			observationSite: false,
			observationSiteErrorMessage: '',
			observationSiteLocation: false,
			observationSiteLocationErrorMessage: '',
			observationReportingPeopleInvolved: false,
			observationReportingPeopleInvolvedErrorMessage: '',
			observationPriority: false,
			observationPriorityErrorMessage: '',
			observationUsersToNotify: false,
			observationUsersToNotifyErrorMessage: '',
			observationReportingIncidentDate: false,
			observationReportingIncidentDateErrorMessage: '',
			observationReportingIncidentTime: false,
			observationReportingIncidentTimeErrorMessage: '',
			observationRespondingActionTaken: false,
			observationRespondingActionTakenErrorMessage: '',
		},
		form: {
			id: '',

			// Reporting
			observationType: 'Observation',
			observationTitle: '',
			observationReportingDescription: '',
			observationSite: '',
			observationSiteLocation: '',
			observationReportingPeopleInvolved: '',
			observationPriority: '',
			observationUsersToNotify: [],
			observationReportingIncidentDate: '',
			observationReportingIncidentTime: '',
			observationReportingFiles: [],

			// Responding
			observationRespondingUser: '',
			observationReferenceNumber: '',
			observationRespondingDateTime: '',
			observationStatus: 'Pending',
			observationRespondingFiles: [],
			observationResponses: [],

			createdUserData: {},
			createdDateTime: '',
			modifiedUserData: {},
			modifiedDateTime: '',
		},
		observationRespondingActionTaken: '',

		// Temp data for the form
		selectedSiteObject: {},
		selectedUsersArray: [],
		selectedRespondingUserObject: {},

		fileUploadResults: [],
		isFilePreviewVisible: false,
		isFormReadOnly: false,
		isSaveConfirmationDialogVisible: false,
		respondingFormAccessMessage: '',
		selectedFile: {},
		tabs: 'observation',

		temp_observationStatus: 'Pending',

		// Data
		filesCollectionData: [],
	}),

	computed: {
		...mapGetters({
			GET_panelLayout: 'GET_panelLayout',
			GET_currentUser: 'GET_currentUser',
			GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult'
		}),

		/**
		 * Computed Init
		 *
		 * If this is an existing document, set the form data.
		 * If it's not, the default form will be used.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = {...t.$props.formData}
			const SITES_DATA = t.$props.sitesData
			const EXTERNAL_SITES_DATA = t.$props.externalSitesData
			const USERS_DATA = t.$props.usersData

			// Set the form data if it's an existing document
			if (FORM_DATA?.id) {
				t.form = FORM_DATA
				t.selectedSiteObject = SITES_DATA.find(site => site.id === FORM_DATA.observationSite) || EXTERNAL_SITES_DATA.find(site => site.id === FORM_DATA.observationSite)
				t.selectedUsersArray = USERS_DATA.filter(user => FORM_DATA.observationUsersToNotify.includes(user.id))
				if (t.form.observationRespondingUser) t.selectedRespondingUserObject = USERS_DATA.find(user => user.id === FORM_DATA.observationRespondingUser)
				else t.selectedRespondingUserObject = t.GET_currentUser
				t.getFilesCollectionData()
			} else {
				t.selectedRespondingUserObject = t.GET_currentUser
			}
		},

		/**
		 * Computed Is Form Read Only
		 *
		 * When the form is first created in the Supervisor's Control Panel,
		 * the form should be editable.
		 * If the form is open through the Reporting pages, it should open uneditable.
		 *
		 * @returns {boolean} is form read only
		 */
		computedIsFormReadOnly() {
			const t = this
			const CURRENT_USER_DATA = t.GET_currentUser
			let isReadOnly = true

			// Reporting
			// If this is an existing report, it should not be editable
			if (t.tabs === 'report') isReadOnly = !!t.form?.id

			// Responding
			if (t.tabs === 'response') {

				if (t.form.observationStatus === 'Resolved') {
					isReadOnly = true
					t.respondingFormAccessMessage = 'This report has been resolved and cannot be edited.'
				}

					// The form should be editable if:
					//  - The current user's userLevel is an Admin, Director, Senior Manager, or Project Manager
					//  - The current user has the 'Teamship Reports' Observation Responsibility
				//  - The current user has the 'Teamship: Can Respond' Access & Ability
				else if (['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager', 'Staff-Project-Manager'].includes(CURRENT_USER_DATA.userLevel)
					|| CURRENT_USER_DATA.userObservationResponsibilities.includes('Teamship Reports')
					|| CURRENT_USER_DATA.userAccessAndAbilities.includes('Teamship: Can Respond')) {
					isReadOnly = false
				} else {
					isReadOnly = true
					t.respondingFormAccessMessage = 'You do not have permission to edit this report.'
				}

			}

			return isReadOnly
		},

		/**
		 * Computed Sites Data
		 *
		 * Offices and Project Sites need to be in the same list, but Project Sites have a 'projectTitle' instead of
		 * 'siteName', so add a siteName to all Project Sites and return as a single array.
		 *
		 * @returns {array} array of site objects
		 */
		computedSitesData() {
			const t = this
			const OFFICES_DATA = t.$props.sitesData
			const SITES_DATA = t.$props.externalSitesData

			SITES_DATA.forEach(site => site.siteName = site.projectTitle)

			return [...OFFICES_DATA, ...SITES_DATA]
		},

	},

	methods: {

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Close File Preview
		 *
		 * Close the file preview dialog and reset the selected file.
		 */
		closeFilePreview() {
			const t = this

			t.selectedFile = {}
			t.isFilePreviewVisible = false
		},

		/**
		 * Close Save Confirmation Dialog
		 *
		 * Close the Save Confirmation dialog.
		 */
		closeSaveConfirmationDialog() {
			const t = this

			t.isSaveConfirmationDialogVisible = false
		},

		/**
		 * Delete File
		 *
		 * Update the document to remove the file from the array.
		 * Update Files collection to mark the selected photo as deleted.
		 *
		 * @param fileURL - the URL of the file to delete
		 * @returns {Promise<void>}
		 */
		async deleteFile(fileURL) {
			const t = this
			const FILES_DATA = t.filesCollectionData
			let documentResult

			// Get file data
			const FILE_DATA = FILES_DATA.find(fileData => fileData.fileURL === fileURL)

			// Remove the FILE_DATA from either the reporting or responding files array, depending on which tab is open
			if (FILE_DATA.comment === 'reporting') t.form.observationReportingFiles = t.form.observationReportingFiles.filter(f => f !== fileURL)
			if (FILE_DATA.comment === 'responding') t.form.observationRespondingFiles = t.form.observationRespondingFiles.filter(f => f !== fileURL)

			// Update the document to remove the file
			documentResult = await t.MIX_updateDocument('observations', t.form)
			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully Deleted File',
				'Error Deleting File',
			)

			// If the document was successfully updated, delete the file from storage
			if (documentResult.code === 1) documentResult = await t.MIX_deleteDocument('files', FILE_DATA.id)

			t.closeFilePreview()
		},

		/**
		 * Get Files Collection Data
		 *
		 * Get the files collection data for the current document.
		 */
		async getFilesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('files')
				.where('docLink', '==', t.form.id)
				.onSnapshot(snapshot => {

					collectionData = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						if (!DOCUMENT.hasOwnProperty('delete')) collectionData.push(DOCUMENT)
					})

					t.filesCollectionData = collectionData
				})
		},

		/**
		 * Generate Reference Number
		 *
		 * Observation reference numbers are stored in a Counters collection.
		 * Read the document and increment the number, convert back to a string, and return the value.
		 * The format for the number is five incrementing digits (e.g. 01234)
		 */
		async generateReferenceNumber() {
			const t = this
			let counter
			let referenceNumber

			const collection = await t.$firebase.db.collection('counters')
				.doc('observationsReferenceNumbers')
				.get()

			if (collection.exists) {
				counter = await collection.data().observationNumber
				counter++
				referenceNumber = `${String(counter).padStart(5, '0')}`
			}

			return {referenceNumber, counter}
		},

		/**
		 * Handle Observation Time
		 *
		 * Takes the time emitted from TimePicker and assigns it to the form data.
		 *
		 * @param time
		 */
		handleObservationTime(time) {
			const t = this

			t.form.observationReportingIncidentTime = time
		},

		/**
		 * Handle Date Picker
		 *
		 * Takes the date emitted from Date Picker and assigns it to the form data.
		 *
		 * @param date
		 */
		handleDatePicker(date) {
			const t = this

			// Convert to Unix timestamp
			date = t.$moment(date).format('x')
			t.form.observationReportingIncidentDate = date
		},

		/**
		 * Open File Preview
		 *
		 * Open the file preview dialog and set the selected file.
		 * @param imageURL the file path to set
		 */
		openFilePreview(imageURL) {
			const t = this

			t.selectedFile = imageURL
			t.isFilePreviewVisible = true
		},

		/**
		 * Open Save Confirmation Dialog
		 *
		 * Open the save confirmation dialog.
		 */
		openSaveConfirmationDialog() {
			const t = this

			t.isSaveConfirmationDialogVisible = true
		},

		/**
		 * Save Document
		 *
		 * Create/update the document.
		 *
		 * @returns {Promise<void>}
		 */
		async saveDocument() {
			const t = this
			const FORM_DATA = t.form
			let documentResult

			// Get the site ID from the selected site object
			FORM_DATA.observationSite = t.selectedSiteObject.id

			// Get the user IDs from the selected users array
			FORM_DATA.observationUsersToNotify = t.selectedUsersArray.map(user => user.id)

			// Create
			if (!FORM_DATA?.id) {

				// Generate new document ID
				FORM_DATA.id = t.MIX_generateId()

				FORM_DATA.observationRespondingUser = ''

				// Get Incremented Tender Number, and the new counter value
				const {referenceNumber, counter} = await t.generateReferenceNumber()
				FORM_DATA.observationReferenceNumber = referenceNumber

				// Save new Organisation document
				documentResult = await t.MIX_createDocument('observations', FORM_DATA)

				if (documentResult.code === 1) {
					await t.MIX_updateDocumentFieldsById('counters', 'observationsReferenceNumbers', {observationNumber: counter})
					t.sendNewObservationEmail()
				}
			}

			// Update
			else {

				// Set the responder's ID
				FORM_DATA.observationRespondingUser = FORM_DATA.observationRespondingUser ? FORM_DATA.observationRespondingUser : t.GET_currentUser.id

				// Set the responding date time
				FORM_DATA.observationRespondingDateTime = t.$moment().format('x')

				// Set the responding status
				FORM_DATA.observationStatus = t.temp_observationStatus

				// Create a response object and add it to the form
				if (t.observationRespondingActionTaken) {
					const RESPONSE_OBJECT = {
						responseDateTime: new Date().getTime(),
						responseUserName: t.GET_currentUser.userName,
						responseText: t.observationRespondingActionTaken,
					}
					FORM_DATA.observationResponses.push(RESPONSE_OBJECT)
				}

				documentResult = await t.MIX_updateDocument('observations', FORM_DATA)

				// Send an email to selected users
				if (documentResult.code === 1) {
					if (FORM_DATA.observationStatus === 'Pending') t.sendUpdatedObservationEmail()
					if (FORM_DATA.observationStatus === 'Resolved') t.sendResolvedObservationEmail()
				}

			}

			// If the document was successfully saved, loop through the fileUploadResults array and update the docLink in the files collection
			if (documentResult.code === 1) {
				for (const result of t.fileUploadResults) {
					await t.MIX_updateDocumentFieldsById('files', result.id, {docLink: FORM_DATA.id})
				}
			}

			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully Saved Report',
				'Error Saving Report'
			)

			t.$emit('emitCloseRightPanel')
		},

		/**
		 * Send New Observation Email
		 *
		 * Trigger FB function (sendNewObservationEmail) to send email notifying selected users of a new Observation.
		 */
		sendNewObservationEmail() {
			const t = this

			const CREATE_EMAIL = t.$firebase.functions.httpsCallable('sendNewObservationEmail')

			CREATE_EMAIL(t.form)
				.then(() => console.log('Successfully sent New Observation email'))
				.catch(error => console.error('Error sending New Observation email', error))
		},

		/**
		 * Send Updated Observation Email
		 *
		 * Trigger FB function (sendUpdatedObservationEmail) to send email notifying selected users of an update to the Observation.
		 */
		sendUpdatedObservationEmail() {
			const t = this

			const CREATE_EMAIL = t.$firebase.functions.httpsCallable('sendUpdatedObservationEmail')

			CREATE_EMAIL(t.form)
				.then(() => console.log('Successfully sent Updated Observation email'))
				.catch(error => console.error('Error sending Updated Observation email', error))
		},

		/**
		 * Send Resolved Observation Email
		 *
		 * Trigger FB function (sendResolvedObservationEmail) to send email notifying selected users of a resolution to the Observation.
		 */
		sendResolvedObservationEmail() {
			const t = this

			const CREATE_EMAIL = t.$firebase.functions.httpsCallable('sendResolvedObservationEmail')

			CREATE_EMAIL(t.form)
				.then(() => console.log('Successfully sent Resolved Observation email'))
				.catch(error => console.error('Error sending Resolved Observation email', error))
		},

		/**
		 * Validate Reporting Form
		 *
		 * Validates the reporting form and sets the errors object.
		 */
		validateReportingForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Title
			if (!t.form.observationTitle.trim()) {
				t.errors.observationTitle = true
				t.errors.observationTitleErrorMessage = 'Title is required'
			}

			// Description
			if (!t.form.observationReportingDescription.trim()) {
				t.errors.observationReportingDescription = true
				t.errors.observationReportingDescriptionErrorMessage = 'Title is required'
			}

			// Site
			if (!t.selectedSiteObject?.id) {
				t.errors.observationSite = true
				t.errors.observationSiteErrorMessage = 'Site is required'
			}

			// Site location
			if (!t.form.observationSiteLocation.trim()) {
				t.errors.observationSiteLocation = true
				t.errors.observationSiteLocationErrorMessage = 'Site location is required'
			}

			// People Involved
			if (!t.form.observationReportingPeopleInvolved.trim()) {
				t.errors.observationReportingPeopleInvolved = true
				t.errors.observationReportingPeopleInvolvedErrorMessage = 'People Involved is required'
			}

			// Priority
			if (!t.form.observationPriority) {
				t.errors.observationPriority = true
				t.errors.observationPriorityErrorMessage = 'Priority is required'
			}

			// Users to Notify
			if (!t.selectedUsersArray.length) {
				t.errors.observationUsersToNotify = true
				t.errors.observationUsersToNotifyErrorMessage = 'Noticed for Action is required'
			}

			// Date
			if (!t.form.observationReportingIncidentDate) {
				t.errors.observationReportingIncidentDate = true
				t.errors.observationReportingIncidentDateErrorMessage = 'Date is required'
			}

			// Time
			if (!t.form.observationReportingIncidentTime) {
				t.errors.observationReportingIncidentTime = true
				t.errors.observationReportingIncidentTimeErrorMessage = 'Time is required'
			}

			// If there are no errors, call to save
			if (!Object.values(t.errors).includes(true)) t.openSaveConfirmationDialog()
		},

		/**
		 * Validate Responding Form
		 *
		 * Validates the responding form and sets the errors object.
		 */
		validateRespondingForm() {
			const t = this

			t.clearErrorsAndMessages()

			// Action Taken
			if (!t.form.observationResponses.length && !t.observationRespondingActionTaken.trim()) {
				t.errors.observationRespondingActionTaken = true
				t.errors.observationRespondingActionTakenErrorMessage = 'Action Taken required'
			}

			// If there are no errors, call to save
			if (!Object.values(t.errors).includes(true)) t.saveDocument()
		},

	},

	watch: {

		GET_photoUploadResult: {
			async handler() {
				const t = this
				const PHOTO_UPLOAD_RESULT = t.GET_photoUploadResult

				t.fileUploadResults.push(PHOTO_UPLOAD_RESULT)

				if (PHOTO_UPLOAD_RESULT.comment === 'reporting') {
					t.form.observationReportingFiles.push(PHOTO_UPLOAD_RESULT.fileURL)
					await t.MIX_updateDocumentFieldsById(
						'observations',
						t.form.id,
						{observationReportingFiles: t.form.observationReportingFiles})
				}

				if (PHOTO_UPLOAD_RESULT.comment === 'responding') {
					t.form.observationRespondingFiles.push(PHOTO_UPLOAD_RESULT.fileURL)
					await t.MIX_updateDocumentFieldsById(
						'observations',
						t.form.id,
						{observationRespondingFiles: t.form.observationRespondingFiles})
				}

			}
		}

	},

}

</script>

<style scoped>

.disabled >>> .v-input__slot::before {
	border-style: none !important;
}

.v-tabs-items {
	width: 100% !important;
	height: 100% !important;
}

.v-window-item {
	height: calc(100vh - 170px);
	overflow-y: auto;
}

.v-menu__content {
	box-shadow: none !important;
}

.fade-in-enter-active {
	transition: all 0.5s ease;
}

.fade-in-leave-active {
	transition: all 0.5s ease;
}

.fade-in-enter, .fade-in-leave-to {
	position: absolute; /* add for smooth transition between elements */
	opacity: 0;
}

</style>
