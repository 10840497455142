<template>
    <div style="width: 100%">
        <page-loading :is-page-loading="isPageLoading" />

        <!--Page Content-->
        <v-row v-if="!computedIsPageLoading" no-gutters style="width: 100% !important">
            <!--Left panel-->
            <v-col :cols="GET_panelLayout.leftPanel" class="pa-4">
                <!--Page title-->
                <page-title
                    icon="icons8-people"
                    pageTitle="Directory"
                    description="View, edit and delete Users, or update a Users access permissions and status."
                    :divider="true"
                />

                <!--Searches-->
                <v-row class="mb-4" no-gutters>
                    <!--Name-->
                    <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4" :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'">
                        <app-input
                            input-type="textInput"
                            :append-icon="'icons8-search'"
                            :clearable="true"
                            label="Name"
                            v-model.trim="searchByUserName"
                        />
                    </v-col>

                    <!--Position-->
                    <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4" :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'px-2'">
                        <app-input
                            input-type="textInput"
                            :append-icon="'icons8-search'"
                            :clearable="true"
                            label="Job Title"
                            v-model.trim="searchByPosition"
                        />
                    </v-col>

                    <!--Categories-->
                    <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 4" :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'">
                        <app-input
                            input-type="autocomplete"
                            :append-icon="'icons8-search'"
                            :clearable="true"
                            :items="computedUserCategoriesOptionsData"
                            label="Categories"
                            :multiple="true"
                            v-model.trim="searchByCategories"
                        />
                    </v-col>
                </v-row>

                <!--Filter buttons | Action buttons-->
                <v-row
                    v-if="
                        ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) ||
                        GET_currentUser.userAccessAndAbilities.includes('Users: Can View, Edit, and Delete')
                    "
                    no-gutters
                >
                    <!--Toggle filters | Action buttons-->
                    <v-col v-if="$vuetify.breakpoint.width > 600" class="d-flex align-end pa-0 mb-4" cols="12">
                        <!--Toggle buttons-->
                        <v-btn-toggle
                            v-model="filter.userStatus"
                            multiple
                            color="white"
                            class="rounded-lg mr-4"
                            active-class="white"
                            background-color="lightgrey"
                            dense
                        >
                            <!--Pending-->
                            <v-btn value="PENDING" large :height="buttonSizeDefault">
                                <v-icon color="accent" class="icons8-circle" />
                                <app-text category="text-small" class="grey--text">
                                    {{ $t(`filters.pending`) }}
                                </app-text>
                            </v-btn>

                            <!--Approved-->
                            <v-btn value="APPROVED" large :height="buttonSizeDefault">
                                <v-icon color="success" class="icons8-checkmark-yes" />
                                <app-text category="text-small" class="grey--text">
                                    {{ $t(`filters.approved`) }}
                                </app-text>
                            </v-btn>

                            <!--Rejected-->
                            <v-btn
                                value="REJECTED"
                                large
                                :height="buttonSizeDefault"
                                v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1200"
                            >
                                <v-icon color="darkgrey" class="icons8-cancel" />
                                <app-text
                                    category="text-small"
                                    class="grey--text"
                                    v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168"
                                >
                                    {{ $t(`filters.rejected`) }}
                                </app-text>
                            </v-btn>

                            <!--Suspended-->
                            <v-btn
                                value="SUSPENDED"
                                large
                                :height="buttonSizeDefault"
                                v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1200"
                            >
                                <v-icon color="darkgrey" class="icons8-private" />
                                <app-text
                                    category="text-small"
                                    class="grey--text"
                                    v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168"
                                >
                                    {{ $t(`filters.suspended`) }}
                                </app-text>
                            </v-btn>

                            <!--Archived-->
                            <v-btn
                                value="ARCHIVED"
                                large
                                :height="buttonSizeDefault"
                                v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1200"
                            >
                                <v-icon color="grey" class="icons8-xbox-a" />
                                <app-text
                                    category="text-small"
                                    class="grey--text"
                                    v-if="GET_panelLayout.leftPanel === 12 && $vuetify.breakpoint.width > 1168"
                                >
                                    {{ $t(`filters.archived`) }}
                                </app-text>
                            </v-btn>
                        </v-btn-toggle>

                        <v-spacer />

                        <!--Action buttons-->
                        <div class="d-flex">
                            <!--Columns-->
                            <app-btn
                                v-if="$vuetify.breakpoint.width >= 600"
                                @click.native="columnsDialog = true"
                                class="ml-4"
                                color="primary"
                                hide-label-below="810"
                                icon="icons8-select-column"
                                label="Columns"
                            />

                            <!--Export-->
                            <app-btn
                                v-if="$vuetify.breakpoint.width >= 600"
                                @click.native="MIX_exportDocuments(headersCSV, 'Staff Directory', formatExport)"
                                class="ml-4"
                                color="primary"
                                hide-label-below="810"
                                icon="icons8-export-csv"
                                label="Export"
                            />
                        </div>
                    </v-col>
                </v-row>

                <!--Table - for larger-screened devices-->
                <v-data-table
                    v-if="$vuetify.breakpoint.width >= 600"
                    class="rounded-lg"
                    :headers="computedHeaders"
                    :items="computedTableData"
                    item-key="id"
                    sort-by="userName"
                >
                    <!--Id-->
                    <template v-slot:[`item.id`]="{ item }">
                        <app-text category="text-small">{{ item.id }}</app-text>
                    </template>

                    <!--User Status-->
                    <template v-slot:[`item.userStatus`]="{ item }">
                        <div>
                            <v-icon v-if="item.userStatus === 'PENDING'" class="icons8-circle" color="accent" size="32" />
                            <v-icon v-if="item.userStatus === 'REJECTED'" class="icons8-cancel" color="warning" size="32" />
                            <v-icon v-if="item.userStatus === 'APPROVED'" class="icons8-checkmark-yes" color="success" size="32" />
                            <v-icon v-if="item.userStatus === 'SUSPENDED'" class="icons8-private" color="error" size="32" />
                            <v-icon v-if="item.userStatus === 'ARCHIVED'" class="icons8-xbox-a" color="grey" size="32" />
                        </div>
                    </template>

                    <!--User Name-->
                    <template v-slot:[`item.userName`]="{ item }">
                        <app-text category="text-small">{{ item.userName }}</app-text>
                    </template>

                    <!--Job Title-->
                    <template v-slot:[`item.userPosition`]="{ item }">
                        <app-text category="text-small">{{ item.userPosition }}</app-text>
                    </template>

                    <!--Telephone Number-->
                    <template v-slot:[`item.userTelephone`]="{ item }">
                        <app-text v-if="item.userCanUsePersonalDetails" category="text-small">
                            {{ item.userTelephone }}
                        </app-text>
                        <app-text v-if="!item.userCanUsePersonalDetails" category="text-small" class="grey--text"> Hidden </app-text>
                    </template>

                    <!--Email Address-->
                    <template v-slot:[`item.userEmail`]="{ item }">
                        <app-text v-if="item.userCanUsePersonalDetails" category="text-small">
                            {{ item.userEmail }}
                        </app-text>
                        <app-text v-if="!item.userCanUsePersonalDetails" category="text-small" class="grey--text"> Hidden </app-text>
                    </template>

                    <!--Last SWAPP-->
                    <template v-slot:[`item.lastSite.siteName`]="{ item }">
                        <app-text category="text-small">{{ item.lastSite.siteName }}</app-text>
                        <app-text category="text-small" class="grey--text">{{ item.lastLocation.locationName }} </app-text>
                    </template>

                    <!--Last location-->
                    <template v-slot:[`item.lastLocation.locationName`]="{ item }">
                        <app-text category="text-small">{{ item.lastLocation.locationName }}</app-text>
                    </template>

                    <!-- Action button -->
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            v-if="
                                ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(GET_currentUser.userLevel) ||
                                GET_currentUser.userAccessAndAbilities.includes('Users: Can View, Edit, and Delete')
                            "
                            @click="openItem(item.id, 'View', false, item)"
                            class="primary--text white pa-4"
                            depressed
                        >
                            <v-icon class="icons8-forward" color="primary" />
                        </v-btn>
                    </template>
                </v-data-table>

                <!--Mobile Cards - for smaller-screened devices-->
                <div v-if="$vuetify.breakpoint.width < 600">
                    <div
                        v-for="item in computedTableData"
                        :key="item.id"
                        @click="openItem(item.id, 'View', false)"
                        class="d-flex align-center rounded-lg white mb-4 pa-2"
                    >
                        <!--If an image is present, render it-->
                        <div class="rounded-lg mr-2" style="border: 1px solid lightgray">
                            <v-img v-if="item.profilePicFileURL" center class="rounded-lg" height="80" :src="item.profilePicFileURL" width="80" />

                            <!--If an image is NOT present, render an icon-->
                            <v-icon v-else class="icons8-customer" size="80" />
                        </div>

                        <!--User details (name | position )-->
                        <div class="user-nameOverflow d-flex flex-column justify-space-between">
                            <div class="d-flex">
                                <!--Status Icon-->
                                <div class="mr-2">
                                    <v-icon v-if="item.userStatus === 'PENDING'" class="icons8-circle" color="accent" size="32" />
                                    <v-icon v-if="item.userStatus === 'REJECTED'" class="icons8-cancel" color="warning" size="32" />
                                    <v-icon v-if="item.userStatus === 'APPROVED'" class="icons8-checkmark-yes" color="success" size="32" />
                                    <v-icon v-if="item.userStatus === 'SUSPENDED'" class="icons8-private" color="error" size="32" />
                                    <v-icon v-if="item.userStatus === 'ARCHIVED'" class="icons8-xbox-a" color="grey" size="32" />
                                </div>

                                <!--Name-->
                                <app-text category="text-default-bold" class="primary--text">
                                    {{ item.userName }}
                                </app-text>
                            </div>

                            <v-divider class="lightgrey my-2" />

                            <!--Position-->
                            <app-text category="text-small" class="grey--text">
                                {{ item.userLevel === 'Visitor-User' ? 'Visitor' : item.userPosition }}
                            </app-text>
                        </div>
                    </div>
                </div>
            </v-col>

            <!--Right panel-->
            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn animated__faster"
                leave-active-class="animate__animated animate__fadeOut animated__faster"
                mode="out-in"
            >
                <rightpanel></rightpanel>
                <!-- <component :is="dynamicComponent" :key="dynamicComponentKey"></component>      -->
            </transition>

            <!--Column Dialog Box-->
            <v-dialog v-model="columnsDialog" scrollable max-width="300px">
                <v-card class="rounded-lg">
                    <!--Title-->
                    <app-text category="text-medium" class="primary--text ma-4">Show/Hide Columns</app-text>

                    <v-divider />

                    <!--List items-->
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="(header, index) in headers.slice(0, -3)" :key="index">
                                <app-text category="text-default">
                                    <v-checkbox
                                        color="grey darken-1"
                                        hide-details
                                        v-model="headers[index].hidden"
                                        :false-value="true"
                                        :true-value="false"
                                        :label="header.text"
                                    />
                                </app-text>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                    <v-divider />

                    <!--Close button-->
                    <v-card-actions class="text-right">
                        <v-spacer />
                        <v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'StaffDirectory',

    data: () => ({
        collection: 'staffDirectory',
        collectionItem: 'staffDirectory',
        collectionItemTitle: 'Staff Directory',
        columnsDialog: false,
        exportStaff: [],
        filter: {
            status: [1, 0, 2, 3],
            userStatus: []
        },
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'start',
                sortable: false,
                hidden: true,
                hide: false,
                hasAccess: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager']
            },
            {
                text: 'Status',
                value: 'userStatus',
                align: 'center',
                sortable: false,
                hidden: false,
                hide: false,
                width: '69px'
            },
            {
                text: 'Name',
                value: 'userName',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false
            },
            {
                text: 'Job Title',
                value: 'userPosition',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: false
            },
            {
                text: 'Telephone Number',
                value: 'userTelephone',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: true
            },
            {
                text: 'Email Address',
                value: 'userEmail',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: true
            },
            {
                text: 'Last Site Visited',
                value: 'lastSite.siteName',
                align: 'start',
                sortable: false,
                hidden: false,
                hide: true
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                hasAccess: ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'],
                hidden: false,
                hide: false,
                width: '96px'
            }
        ],
        headersCSV: {
            userName: 'User Name',
            userTelephone: 'User Telephone',
            userEmail: 'User Email',
            userTitle: 'User Position',
            userType: 'User Type',
            lastLocation: 'Last Location',
            lastSite: 'Last Site',
            userLevel: 'User Level',
            userRole: 'User Role',
            userStatus: 'Access'
        },
        isPageLoading: true,
        searchByCategories: [],
        searchByPosition: '',
        searchByUserName: '',

        // Data
        usersCollectionData: [],
        isUsersCollectionDataLoaded: false,
        visitorsCollectionData: [],
        isVisitorsCollectionDataLoaded: false
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: 'GET_panelLayout',
            GET_currentUser: 'GET_currentUser',
            GET_lookupValues: 'GET_lookupValues'
        }),

        /**
         * Computed Headers
         *
         * Only display headers that are not set to hidden and the user has access to (via userLevel).
         *
         * @returns {[headers]} - filtered headers to render in the table
         */
        computedHeaders() {
            const t = this
            const headers = t.headers
            let filteredHeaders = []

            headers.forEach((header) => {
                // If the column is not hidden, show it
                if (!header.hidden) filteredHeaders.push(header)
            })

            return filteredHeaders
        },

        //Format of the Export File
        computedIsPageLoading() {
            const t = this

            t.isPageLoading = !(t.isUsersCollectionDataLoaded && t.isVisitorsCollectionDataLoaded)

            return t.isPageLoading
        },

        /**
         * Computed Table Data
         *
         * Filter the table data based on the filters set in the UI.
         *
         * @returns {[tableData]} - filtered table data to render in the table
         */
        computedTableData() {
            const t = this
            let tableData = [...t.usersCollectionData, ...t.visitorsCollectionData]

            // Hide the Vindico Dev account
            tableData = tableData.filter((td) => td.userEmail !== 'support@swapp.work')

            // Only show users that:
            // - Are not using 2FA
            // - Are using 2FA, and have passed 2FA
            // Do not show users that are using 2FA, but have not passed 2FA
            // tableData = tableData.filter(td => !td.userShouldUse2fa || (td.userShouldUse2fa && td.userHasPassed2fa))

            // Filter by User Status for Access
            if (t.filter.userStatus.length) tableData = tableData.filter((item) => t.filter.userStatus.includes(item.userStatus))

            // Filter by SWAPP status
            if (t.filter.status.length) tableData = tableData.filter((item) => t.filter.status.includes(item.swappStatus))

            // Search by UserName
            if (t.searchByUserName) {
                const SEARCH_BY_USERNAME = t.searchByUserName.toUpperCase()
                tableData = tableData.filter((user) => user.userName?.toUpperCase().includes(SEARCH_BY_USERNAME))
            }

            // Search by Position
            if (t.searchByPosition) {
                const SEARCH_BY_POSITION = t.searchByPosition.toUpperCase()
                tableData = tableData.filter((user) => user.userPosition?.toUpperCase().includes(SEARCH_BY_POSITION))
            }

            // Filter by Categories
            if (t.searchByCategories.length) {
                tableData = tableData.filter((user) => user.userCategories?.some((category) => t.searchByCategories.includes(category)))
            }

            // Sort alphabetically by userName
            tableData = tableData.sort((a, b) => (a.userName > b.userName ? 1 : -1))

            return tableData
        },

        /**
         * Computed User Categories Options Data
         *
         * Flatten the userCategoriesOptionsData and sort alphabetically.
         *
         * @returns {*[]}
         */
        computedUserCategoriesOptionsData() {
            const t = this
            const USER_CATEGORIES = t.userCategoriesOptionsData
            let userCategoriesOptionsData = []

            // Flatten the structure into a single array
            userCategoriesOptionsData = USER_CATEGORIES.reduce((acc, current) => {
                Object.values(current).forEach((category) => {
                    acc.push(...category)
                })
                return acc
            }, [])

            // Sort alphabetically
            userCategoriesOptionsData = userCategoriesOptionsData.sort((a, b) => (a > b ? 1 : -1))

            return userCategoriesOptionsData
        },

        formatExport() {
            const t = this
            const staff = JSON.parse(JSON.stringify(t.computedTableData))
            t.exportStaff = []

            for (let i = 0; i < staff.length; i++) {
                // Change all these from const to let
                let userName = ''
                let userTelephone = ''
                let userEmail = ''
                let userTitle = ''
                let userType = ''
                let lastLocation = ''
                let lastSite = ''
                let userLevel = ''
                let userRole = ''
                let userStatus = ''

                // Assign values
                userName = staff[i].userName || 'UNKNOWN'
                userTelephone = staff[i].userTelephone || 'UNKNOWN'
                userEmail = staff[i].userEmail || 'UNKNOWN'
                userTitle = staff[i].userPosition || 'UNKNOWN'
                userType = staff[i].userType || 'UNKNOWN'
                lastLocation = staff[i].lastLocation?.locationName || 'UNKNOWN'
                lastSite = staff[i].lastSite?.siteName || 'UNKNOWN'
                userLevel = staff[i].userLevel || 'UNKNOWN'
                userRole = staff[i].userRole || 'UNKNOWN'
                userStatus = staff[i].userStatus || 'UNKNOWN'

                t.exportStaff.push({
                    userName,
                    userTelephone,
                    userEmail,
                    userTitle,
                    userType,
                    lastLocation,
                    lastSite,
                    userLevel,
                    userRole,
                    userStatus
                })
            }

            return t.exportStaff
        }
    },

    methods: {
        ...mapActions({
            ACT_openItem: 'ACT_openStaffDirectory',
            ACT_openStaffDirectory: 'ACT_openStaffDirectory'
        }),

        /**
         * Get Collection Data
         *
         * Fetch the data from the collection and assign them to the class state.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this
            let collectionData = []

            await t.$firebase.db.collection('users').onSnapshot((snapshot) => {
                // Rest array to avoid data duplication
                collectionData = []

                snapshot.forEach((doc) => {
                    const DOCUMENT = doc.data()
                    DOCUMENT.id = doc.id

                    // Only add documents that:
                    //  - Aren't marked as deleted
                    //  - Have NOT signed up with 2FA
                    //  - Have signed up with, and have passed 2FA
                    if (!DOCUMENT.hasOwnProperty('delete')) {
                        collectionData.push(DOCUMENT)
                    }
                })

                t.usersCollectionData = collectionData
                t.isUsersCollectionDataLoaded = true
            })
        },

        async getVisitorsCollectionData() {
            const t = this
            let collectionData = []

            await t.$firebase.db.collection('visitors').onSnapshot((snapshot) => {
                // Rest array to avoid data duplication
                collectionData = []

                snapshot.forEach((doc) => {
                    const DOCUMENT = doc.data()
                    DOCUMENT.id = doc.id

                    if (!DOCUMENT.hasOwnProperty('delete')) collectionData.push(DOCUMENT)
                })

                t.visitorsCollectionData = collectionData.sort((a, b) => (a.userName > b.userName ? 1 : -1))
                t.isVisitorsCollectionDataLoaded = true
            })
        },

        async openItem(id, process, popup, itemData) {
            const t = this

            // Only allow opening if the user has access
            if (
                ['Staff-Admin', 'Staff-Director', 'Staff-Senior-Manager'].includes(t.GET_currentUser.userLevel) ||
                t.GET_currentUser.userAccessAndAbilities.includes('Users: Can View, Edit, and Delete')
            ) {
                const item = {}

                item.process = process
                item.popup = popup

                // If this is NOT a new document, fetch the user data by id
                if (item.process !== 'New') {
                    if (itemData.userType === 'Staff') {
                        const itemResult = await t.MIX_readDocumentById('users', id)
                        item.data = itemResult.data
                    }
                    if (itemData.userType === 'Visitor') {
                        const itemResult = await t.MIX_readDocumentById('visitors', id)
                        item.data = itemResult.data
                    }
                } else {
                    item.data = null
                }

                t.ACT_openItem(item)

                if (itemData.userType === 'Staff') {
                    if (t.$vuetify.breakpoint.lgAndDown) {
                        t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
                        t.MIX_setPanelLayout(12, 0, false, '')
                    } else {
                        t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)
                        // * HIDE HEADERS
                        for (let i = 0; i < t.headers.length; i++) {
                            if (t.headers[i].hide === true) {
                                t.headers[i].hidden = true
                            }
                        }
                    }
                }

                if (itemData.userType === 'Visitor') {
                    if (t.$vuetify.breakpoint.lgAndDown) {
                        t.MIX_fsDialog(true, 'Visitor Directory', 'visitorDirectory')
                        t.MIX_setPanelLayout(12, 0, false, '')
                    } else {
                        t.MIX_setPanelLayout(6, 6, true, 'Visitor Directory', 'visitorDirectory')
                        // * HIDE HEADERS
                        for (let i = 0; i < t.headers.length; i++) {
                            if (t.headers[i].hide === true) {
                                t.headers[i].hidden = true
                            }
                        }
                    }
                }
            }
        }
    },

    async mounted() {
        const t = this

        t.MIX_setPanelLayout(12, 0, false, '') // Hide Right Panel

        // Get collection data
        await t.getUsersCollectionData()
        await t.getVisitorsCollectionData()
    }
}
</script>

<style scoped>
.user-nameOverflow {
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
