<template>
    <v-card flat>

        <!--Toolbar-->
        <v-toolbar class="px-2" color="primary">

            <app-text category="text-medium" class="white--text">Project Lost</app-text>

            <v-spacer/>

            <close-icon @click.native="closeDialog"/>

        </v-toolbar>

        <!--Content-->
        <div class="pa-4">

            <!--Description-->
            <app-text>
                You are about to mark this project as 'lost' which will archive it and hide it from the list.
                If you need to retrieve it, you can toggle the Archived Projects switch.
            </app-text>

            <!--Instructional Text-->
            <app-text category="text-default-bold" class="mt-4">
                Are you sure you want to mark this project as lost?
            </app-text>

            <!--Action Buttons-->
            <div class="d-flex justify-space-between mt-4">

                <!--No-->
                <app-btn @click.native="closeDialog"
                         color="lightgrey"
                         icon="icons8-cancel"
                         label="Cancel"/>

                <!--Yes-->
                <app-btn @click.native="handleYesButton"
                         color="success"
                         icon="icons8-checkmark-yes"
                         label="Yes"/>

            </div>

        </div>

    </v-card>
</template>

<script>
export default {

    name: "jobLostDialog",

    methods: {

        closeDialog() {
            const t = this

            t.$emit('emitCloseJobLostDialog')
        },

        handleYesButton() {
            const t = this

            t.$emit('emitSetJobAsLost')
        },

    }
}
</script>

<style scoped>

</style>
