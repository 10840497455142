<template>
	<v-form>

		{{ computedInit }}

		<!--Form Fields-->
		<v-row no-gutters>

			<!--Checkbox | Field Name | Field Notes-->
			<v-col v-for="item in completedChecklist" :key="item.fieldName"
				   :cols="$vuetify.breakpoint.width < 600 ? 12 : 12"
				   :class="$vuetify.breakpoint.width < 600 ? 'mt-4 pr-2' : 'mt-4 pr-2'">

				<!--Checkbox | Field Name-->
				<div class="d-flex align-center">

					<!--Checkbox-->
					<form-check-box @click.native="handleCheckBoxSelection(item.fieldName)"
									:isChecked="form[item.fieldName]"
									:disabled="!isConvertingSite"/>

					<!--Field Name-->
					<app-text class="ml-4">{{ item.checklistItem }}</app-text>

				</div>

				<!--Field Notes-->
				<app-input input-type="textArea"
						   class="mt-4"
						   :disabled="!isConvertingSite"
						   label="Internal Comments/Notes (optional)"
						   v-model="form[item.fieldNameNotes]"/>

				<v-divider class="mt-4"/>

			</v-col>

		</v-row>

		<!--Save button-->
		<div v-if="!computedIsFormReadOnly" class="d-flex justify-end mt-4">
			<app-text v-if="errors.hasErrors" class="error--text mr-4">{{ errors.errorMessage }}</app-text>
			<app-btn @click.native="saveDocument"
					 color="success"
					 icon="icons8-save"
					 label="Save"/>
		</div>

	</v-form>
</template>

<script>
import DatePicker from "@/components/commonComponents/datePicker.vue";

export default {

	name: "completedSiteForm",

	components: {DatePicker},

	props: ['doesFormStartReadOnly', 'formData', 'isConvertingSite', 'usersData'],

	data: () => ({
		errors: {
			hasErrors: false,
			errorMessage: ''
		},
		form: {
			id: '',
			projectBidLead: '',
			projectClient: '',
			projectCompletionDate: '',
			projectContractAward: '',
			projectContractNumber: '',
			projectContractType: '',
			projectContractsManager: '',
			projectDescription: '',
			projectEstimator: '',
			projectEvaluationScoring: '',
			projectForeman: '',
			projectFramework: '',
			projectLocationLine1: '',
			projectLocationLine2: '',
			projectLocationTown: '',
			projectLocationCity: '',
			projectLocationCounty: '',
			projectLocationPostcode: '',
			projectLocationLatitude: '',
			projectLocationLongitude: '',
			projectManagerAgent: '',
			projectOperationalLead: '',
			projectQS: '',
			projectRegion: '',
			projectStartDate: '',
			projectSubmissionDate: '',
			projectTenderNumber: '',
			projectTitle: '',
			projectValue: 0,
			projectArchivedAndCertified: false,
			projectArchivedAndCertifiedNotes: '',
			projectAcceptedIntoAdoption: false,
			projectAcceptedIntoAdoptionNotes: '',
			projectLeftDefectFree: false,
			projectLeftDefectFreeNotes: '',
			projectRetentionCertifiedOrReleased: false,
			projectRetentionCertifiedOrReleasedNotes: '',
			projectHsFileSubmittedAndAccepted: false,
			projectHsFileSubmittedAndAcceptedNotes: '',
			projectClosePEP: false,
			projectClosePEPNotes: '',

			siteStatus: 'In Progress',
		},
		isFormReadOnly: false,

		completedChecklist: [
			{
				checklistItem: 'Confirm Completion has been archived and is certified',
				fieldName: 'projectArchivedAndCertified',
				fieldNameNotes: 'projectArchivedAndCertifiedNotes',
			},
			{
				checklistItem: 'Confirm scheme has been accepted into adoption process',
				fieldName: 'projectAcceptedIntoAdoption',
				fieldNameNotes: 'projectAcceptedIntoAdoptionNotes',
			},
			{
				checklistItem: 'Confirm site has been left defect free',
				fieldName: 'projectLeftDefectFree',
				fieldNameNotes: 'projectLeftDefectFreeNotes',
			},
			{
				checklistItem: 'Confirm retention has been certified or released',
				fieldName: 'projectRetentionCertifiedOrReleased',
				fieldNameNotes: 'projectRetentionCertifiedOrReleasedNotes',
			},
			{
				checklistItem: 'Confirm H&S File has been submitted and accepted',
				fieldName: 'projectHsFileSubmittedAndAccepted',
				fieldNameNotes: 'projectHsFileSubmittedAndAcceptedNotes',
			},
			{
				checklistItem: 'Project Team close out PEP',
				fieldName: 'projectClosePEP',
				fieldNameNotes: 'projectClosePEPNotes',
			},
		],
	}),

	computed: {

		/**
		 * Computed From Data
		 *
		 * If this is an existing document, set the form data.
		 * If it's not, the default form will be used.
		 */
		computedInit() {
			const t = this

			const formData = t.$props.formData

			if (formData?.id) t.form = formData
		},

		/**
		 * Computed Is Form Read Only
		 *
		 * When the form is first created in the Supervisor's Control Panel,
		 * the form should be editable.
		 * If the form is open through the Reporting pages, it should open uneditable.
		 *
		 * @returns {boolean} is form read only
		 */
		computedIsFormReadOnly() {
			const t = this

			return t.$props.doesFormStartReadOnly
				? t.$props.doesFormStartReadOnly
				: t.isFormReadOnly
		},

	},

	methods: {

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		handleCheckBoxSelection(field) {
			const t = this

			if (!t.$props.isConvertingSite) return

			t.form[field] = !t.form[field]
		},

		/**
		 * Save Document
		 *
		 * Create/update the document.
		 *
		 * @returns {Promise<void>}
		 */
		async saveDocument() {
			const t = this
			let documentResult

			if (!t.validateForm()) return

			t.form.siteStatus = 'Complete'

			// Update
			if (t.form?.id) {

				documentResult = await t.MIX_updateDocument('externalSites', t.form)

				if (documentResult.code === 1) {
					await t.MIX_addSiteNoticeBoardEntry(t.form, 'Complete')
					await t.sendCompletedSiteNotification(t.form, t.$props.usersData)
					await t.send360ReviewNotification(t.form)
				}

			}

			t.MIX_renderConfirmationAlert(
				documentResult,
				'Successfully Saved Site',
				'Error Saving Site'
			)

			t.$emit('emitCloseRightPanel')
			t.$emit('emitCompletedSiteFormComplete')

		},

		/**
		 * Send Completed Site Notification
		 *
		 * Call a Firebase function to email all users when a site has been completed.
		 *
		 * @param siteData the new site data
		 * @param usersData all users data
		 */
		sendCompletedSiteNotification(siteData, usersData) {
			const t = this

			// Only send the required data
			usersData = usersData.map(user => {
				return ({
					userLevel: user.userLevel,
					delete: user.delete === true,
					userEmail: user.userEmail,
					userStatus: user.userStatus,
				})
			})

			const createEmail = t.$firebase.functions.httpsCallable('sendCompletedSiteNotification')
			createEmail({
				usersData,
				siteData,
			})
				.then(result => {
					console.log('Successfully sent Completed Site emails: ', result)
				})
				.catch(error => {
					console.error('Error sending Completed Site emails: ', error)
				})

		},

		/**
		 * Send 360 Review Notification
		 *
		 * Call a Firebase function to email all users when a site has been completed.
		 *
		 * @param siteData the new site data
		 */
		send360ReviewNotification(siteData) {
			const t = this
			const calendarDate = (Number(siteData.projectCompletionDate) + 2419200000).toString() // 4 weeks in millis
			let usersEmails = []

			// Get users from New Sites form (if applicable)
			const projectBidLead = t.$props.usersData.find(user => user.id === t.form.projectBidLead)
			if (projectBidLead?.id) usersEmails.push(projectBidLead.userEmail)
			const projectEstimator = t.$props.usersData.find(user => user.id === t.form.projectEstimator)
			if (projectEstimator?.id) usersEmails.push(projectEstimator.userEmail)
			const projectOperationalLead = t.$props.usersData.find(user => user.id === t.form.projectOperationalLead)
			if (projectOperationalLead?.id) usersEmails.push(projectOperationalLead.userEmail)
			const projectContractsManager = t.$props.usersData.find(user => user.id === t.form.projectContractsManager)
			if (projectContractsManager?.id) usersEmails.push(projectContractsManager.userEmail)
			const projectManagerAgent = t.$props.usersData.find(user => user.id === t.form.projectManagerAgent)
			if (projectManagerAgent?.id) usersEmails.push(projectManagerAgent.userEmail)
			const projectQS = t.$props.usersData.find(user => user.id === t.form.projectQS)
			if (projectQS?.id) usersEmails.push(projectQS.userEmail)
			const projectForeman = t.$props.usersData.find(user => user.id === t.form.projectForeman)
			if (projectForeman?.id) usersEmails.push(projectForeman.userEmail)

			// Set the date for the calendar
			siteData.projectCompletionDate = calendarDate

			const createEmail = t.$firebase.functions.httpsCallable('send360ReviewNotification')
			createEmail({
				usersEmails,
				siteData,
			})
				.then(result => {
					console.log('Successfully sent Completed Site emails: ', result)
				})
				.catch(error => {
					console.error('Error sending Completed Site emails: ', error)
				})

		},

		/**
		 * Validate Form
		 *
		 * Validate all required form fields before saving.
		 *
		 * @returns {boolean} if the form has passed validation or not
		 */
		validateForm() {
			const t = this

			t.clearErrorsAndMessages()

			if (t.form.projectArchivedAndCertified !== true ||
				t.form.projectAcceptedIntoAdoption !== true ||
				t.form.projectLeftDefectFree !== true ||
				t.form.projectRetentionCertifiedOrReleased !== true ||
				t.form.projectHsFileSubmittedAndAccepted !== true ||
				t.form.projectClosePEP !== true
			) {

				t.errors.hasErrors = true
				t.errors.errorMessage = 'All boxes must be checked'

			}

			return !Object.values(t.errors).includes(true)
		}

	},

}
</script>

<style scoped>

</style>
