<template>
	<v-row class="fill-height" no-gutters>

		<!--Page Loader-->
		<page-loading :is-page-loading="isPageLoading"/>

		<!--Page Content-->
		<v-row v-if="!computedIsPageLoading" class="fill-height" no-gutters>

			<!--Left panel-->
			<v-col class="pa-4">

				<!--Page title-->
				<page-title icon="icons8-fires"
							page-title="Fire Roll Call"
							description="Initiate a new Fire Roll Call for a particular site, or view past Roll Calls"
							:divider="true"/>

				<!--Action buttons-->
				<div class="d-flex justify-end mt-4">

					<v-spacer v-if="$vuetify.breakpoint.width >= 600"/>

					<!--Start a Roll Call-->
					<fire-roll-call-start-dialog v-if="!isFireRegisterVisible && (['Staff-Admin', 'Staff-Director'].includes(GET_currentUser.userLevel) || GET_currentUser.userAccessAndAbilities.includes('Fire Roll Call: Can Start'))"
												 :sitesData="computedSitesData"
												 v-on:emitHandleStartRegister="emittedHandleStartRegister"/>

					<!--Cancel the Roll Call-->
					<fire-roll-call-cancel-dialog
						v-if="isFireRegisterVisible"
						v-on:emitHandleCancelRegister="emittedHandleCancelRegister"/>

				</div>

				<!--Table-->
				<v-data-table v-if="$vuetify.breakpoint.width >= 600 && !isFireRegisterVisible"
							  class="rounded-lg mt-4"
							  :headers="tableHeaders"
							  :items="computedTableData"
							  sort-desc>

					<!--Title-->
					<template v-slot:item.title="{ item }">
						<app-text category="text-small">{{ item.title }}</app-text>
					</template>

					<!--Type-->
					<template v-slot:item.type="{ item }">
						<app-text category="text-small">{{ item.type }}</app-text>
					</template>

					<!--Date-->
					<template v-slot:item.date="{ item }">
						<app-text category="text-small">
							{{ $moment(Number(item.createdDateTime)).format('ddd Do MMM \'YY @ HH:mm') }}
						</app-text>
					</template>

					<!--Site-->
					<template v-slot:item.site="{ item }">
						<app-text category="text-small">{{ item.site }}</app-text>
					</template>

					<!--Open-->
					<template v-slot:item.open="{ item }">
						<v-btn @click="openDocument(item.id, 'View', false)"
							   depressed class="primary--text white pa-4">
							<v-icon class="icons8-forward" color="primary"/>
						</v-btn>
					</template>

				</v-data-table>

				<!--Mobile Cards-->
				<div v-if="$vuetify.breakpoint.width < 600 && !isFireRegisterVisible">

					<!--No data message-->
					<app-text v-if="!computedTableData.length"
							  category="text-default" class="justify-center mt-4">You have no Fire Roll Calls
					</app-text>

					<div v-for="item in computedTableData">

						<v-card @click="openDocument(item.id, 'View', false)"
								class="rounded-lg mt-4 pa-4" flat>

							<!--Title-->
							<app-text category="text-default-bold">{{ item.title }}</app-text>

							<!--Type | Date-->
							<div class="d-flex">

								<!--Type-->
								<app-text category="text-default">
									{{ item.type }}
								</app-text>

								<v-spacer/>

								<!--Date-->
								<app-text category="text-default">
									{{ $moment(Number(item.createdDateTime)).format('ddd Do MMM \'YY @ HH:mm') }}
								</app-text>

							</div>

						</v-card>

					</div>

				</div>

				<!--Register-->
				<fire-roll-call-register v-if="isFireRegisterVisible"
										 :users="computedSiteUsersData"
										 :selectedSite="selectedSiteName"
										 v-on:emitCloseRegister="isFireRegisterVisible = false"/>

			</v-col>

			<!--Right panel-->
			<transition name="custom-classes-transition"
						enter-active-class="animate__animated animate__slideIn animated__faster"
						leave-active-class="animate__animated animate__fadeOut animated__faster"
						mode="out-in">
				<rightpanel/>
			</transition>

		</v-row>

	</v-row>
</template>

<script>
import FireRollCallRegister from "./fireRollCallRegister/fireRollCallRegister";
import FireRollCallCancelDialog from "./fireRollCallCancelDialog/fireRollCallCancelDialog";
import FireRollCallStartDialog from "./fireRollCallStartDialog/fireRollCallStartDialog";
import {mapActions, mapGetters} from "vuex";

export default {

	name: "fireRollCall",

	components: {
		FireRollCallStartDialog,
		FireRollCallCancelDialog,
		FireRollCallRegister
	},

	data: () => ({
		isFireRegisterVisible: false,
		selectedSiteId: '',
		selectedSiteName: '',
		tableHeaders: [
			{text: 'Title', value: 'title', align: 'left', hidden: false},
			{text: 'Type', value: 'type', align: 'left', hidden: false},
			{text: 'Date', value: 'date', align: 'left', hidden: false, sortable: false},
			{text: 'Site', value: 'site', align: 'left', hidden: false},
			{text: '', value: 'open', align: 'right', hidden: false, sortable: false, width: '96px'},
		],

		// Data
		fireRollCallCollectionData: [],
		isFireRollCallCollectionDataLoaded: false,
		sitesCollectionData: [],
		isSitesCollectionDataLoaded: false,
		externalSitesCollectionData: [],
		isExternalSitesCollectionDataLoaded: false,
		usersCollectionData: [],
		isUsersCollectionDataLoaded: false,
		visitorsCollectionData: [],
		isVisitorsCollectionDataLoaded: false,
		isPageLoading: false,
	}),

	computed: {
		...mapGetters({
			GET_panelLayout: "GET_panelLayout",
		}),

		computedIsPageLoading() {
			const t = this

			t.isPageLoading = !(
				t.isFireRollCallCollectionDataLoaded &&
				t.isUsersCollectionDataLoaded &&
				t.isVisitorsCollectionDataLoaded &&
				t.isSitesCollectionDataLoaded &&
				t.isExternalSitesCollectionDataLoaded
			)

			return t.isPageLoading
		},

		computedSiteUsersData() {
			const t = this
			let usersData = [...t.usersCollectionData, ...t.visitorsCollectionData]
			const selectedSiteId = t.selectedSiteId
			let hoursAgo = t.$moment().subtract(1, 'hours').format('x')

			// Gat all the Users for the selected site
			usersData = usersData.filter(user => user.lastSite.siteId === selectedSiteId)

			// Get all the Users either currently SWAPP'd in, or those who have SWAPP'd out in the last hour
			usersData = usersData.filter(user => user.swappStatus === 1 || user.swappStatus === 0 && user.swappTime > hoursAgo)

			return usersData
		},

		computedTableData() {
			const t = this
			let tableData = t.fireRollCallCollectionData

			tableData = tableData.sort((a, b) => {
				return a.createdDateTime < b.createdDateTime ? 1 : -1
			})

			return tableData
		},

		/**
		 * Computed Sites Data
		 *
		 * Offices and Project Sites need to be in the same list, but Project Sites have a 'projectTitle' instead of
		 * 'siteName', so add a siteName to all Project Sites and return as a single array.
		 *
		 * @returns {array} array of site objects
		 */
		computedSitesData() {
			const t = this
			const OFFICES_DATA = t.sitesCollectionData
			const SITES_DATA = t.externalSitesCollectionData

			SITES_DATA.forEach(site => {
				site.siteName = site.projectTitle
			})

			return [...OFFICES_DATA, ...SITES_DATA]
		},

	},

	methods: {
		...mapActions({
			ACT_openItem: "ACT_openFireRollCall",
			ACT_openFireRollCall: "ACT_openFireRollCall",
		}),

		/**
		 * Get Fire Roll Call Collection Data
		 *
		 * Fetch the collection data and add the records to the data array.
		 *
		 * @returns {Promise<void>}
		 */
		async getFireRollCallCollectionData() {
			const t = this
			let fireRollCallCollectionData = []

			await t.$firebase.db.collection('fireRollCall')
				.onSnapshot(snapshot => {

					// Clear the array to avoid data duplication
					fireRollCallCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						fireRollCallCollectionData.push(document)

					})

					t.fireRollCallCollectionData = fireRollCallCollectionData
					t.isFireRollCallCollectionDataLoaded = true
				})
		},

		/**
		 * Get Users Collection Data
		 *
		 * Fetch the collection data and add those records which are not marked as 'deleted'.
		 *
		 * @returns {Promise<void>}
		 */
		async getUsersCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('users')
				.onSnapshot(snapshot => {

					// Clear the array to avoid data duplication
					collectionData = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						// Only add documents that:
						//  - Aren't marked as deleted
						//  - Are Approved
						//  - Have NOT signed up with 2FA
						//  - Have signed up with, and have passed 2FA
						if (!DOCUMENT.hasOwnProperty('delete') &&
							DOCUMENT.userStatus === 'APPROVED' &&
							(!DOCUMENT.userShouldUse2fa || (DOCUMENT.userHasPassed2fa))
						) {
							collectionData.push(DOCUMENT)
						}
					})

					// Sort alphabetically by userName
					collectionData = collectionData.sort((a, b) => a.userName > b.userName ? 1 : -1)

					t.usersCollectionData = collectionData
					t.isUsersCollectionDataLoaded = true
				})
		},

		/**
		 * Get Visitors Collection Data
		 *
		 * Fetch the collection data and add those records which are not marked as 'deleted'.
		 *
		 * @returns {Promise<void>}
		 */
		async getVisitorsCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('visitors')
				.onSnapshot(snapshot => {

					// Clear the array to avoid data duplication
					collectionData = []

					snapshot.forEach(doc => {

						const DOCUMENT = doc.data()
						DOCUMENT.id = doc.id

						// Only add documents that:
						//  - Aren't marked as deleted
						if (!DOCUMENT.hasOwnProperty('delete')) collectionData.push(DOCUMENT)
					})

					// Sort alphabetically by userName
					collectionData = collectionData.sort((a, b) => a.userName > b.userName ? 1 : -1)

					t.visitorsCollectionData = collectionData
					t.isVisitorsCollectionDataLoaded = true
				})
		},

		/**
		 * Get Sites Collection Data
		 *
		 * Fetch the collection data and add those records which are not marked as 'deleted'.
		 *
		 * @returns {Promise<void>}
		 */
		async getSitesCollectionData() {
			const t = this
			let sitesCollectionData = []

			await t.$firebase.db.collection('sites')
				.orderBy('siteName')
				.onSnapshot(snapshot => {

					// Clear the array to avoid data duplication
					sitesCollectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only push documents not marked as deleted
						if (!document.hasOwnProperty('delete')) sitesCollectionData.push(document)
					})

					t.sitesCollectionData = sitesCollectionData
					t.isSitesCollectionDataLoaded = true
				})
		},

		/**
		 * Get External Sites Collection Data
		 *
		 * Fetch all the data from the collection.
		 * Iterate over the collection and only push documents that aren't marked as deleted.
		 *
		 * @returns {Promise<void>}
		 */
		async getExternalSitesCollectionData() {
			const t = this
			let collectionData = []

			await t.$firebase.db.collection('externalSites')
				.onSnapshot(snapshot => {

					// Rest the data to avoid duplication
					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						// Only add documents that aren't marked as deleted
						if (!document.hasOwnProperty('delete')) collectionData.push(document)
					})

					t.externalSitesCollectionData = collectionData
					t.isExternalSitesCollectionDataLoaded = true
				})
		},

		/**
		 * Open Document
		 *
		 * When a table row is opened, populate and show the right panel with the row details.
		 *
		 * @param id
		 * @param process
		 * @param popup
		 * @returns {Promise<void>}
		 */
		async openDocument(id, process, popup) {
			const t = this

			const item = {}
			item.process = process
			item.popup = popup

			const itemResult = await t.MIX_readDocumentById('fireRollCall', id)
			item.data = itemResult.data

			t.ACT_openItem(item)

			if (t.$vuetify.breakpoint.lgAndDown) {
				t.MIX_fsDialog(true, 'Fire Roll Call', 'fireRollCall')
				t.MIX_setPanelLayout(12, 0, false, '')
			} else {
				t.MIX_setPanelLayout(6, 6, true, 'Fire Roll Call', 'fireRollCall')
			}
		},

		emittedHandleStartRegister(site) {
			const t = this

			t.selectedSiteId = site.id
			t.selectedSiteName = site.siteName

			t.isFireRegisterVisible = !t.isFireRegisterVisible
		},

		emittedHandleCancelRegister() {
			const t = this

			t.isFireRegisterVisible = !t.isFireRegisterVisible
		}

	},

	async mounted() {
		const t = this

		// Fetch collections data
		await t.getFireRollCallCollectionData()
		await t.getUsersCollectionData()
		await t.getVisitorsCollectionData()
		await t.getSitesCollectionData()
		await t.getExternalSitesCollectionData()

	},

}
</script>

<style>

</style>
