import { render, staticRenderFns } from "./VirginPulseCard.vue?vue&type=template&id=7c777011&scoped=true"
import script from "./VirginPulseCard.vue?vue&type=script&lang=js"
export * from "./VirginPulseCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c777011",
  null
  
)

export default component.exports