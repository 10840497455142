import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// auth
import createPersistedState from "vuex-persistedstate";

//alert component
import alertbox_store from "@/modules/alertbox/alertbox_store.js";

// * IMPORT MODULES
import fsDialog_store from '@/modules/fsDialog/fsDialog_store.js';
import puDialog_store from '@/modules/puDialog/puDialog_store.js';
import upload_file_store from "@/modules/file-upload/upload_file_store.js";
import photoUpload_store from '@/modules/photoUpload/photoUpload_store.js';
import photoViewer_store from '@/modules/photoViewer/photoViewer_store.js';
import confirmBox_store from '@/modules/confirmBox/confirmBox_store.js';
import location_store from '@/modules/location/location_store.js';
import confirmation_store from '@/modules/confirmation/confirmation_store.js';

//qr code
import qr_code_reader_store from '@/modules/qr-code-reader/qr_code_reader_store.js';
import qr_code_generator_store from '@/modules/qr-code-generator/qr_code_generator_store.js';

// * FIREBASE AUTH STORE
import firebase_auth_store from '@/modules/auth/firebase_auth_store.js';
import progressBar_store from "@/modules/progressBar/progressBar_store.js"; // AlertBox Module

export default new Vuex.Store({
	state: {
		currentUser: null,
		mobileNav: false,
		panelLayout: {
			leftPanel: 12,
			rightPanel: 0,
			show: false,
			title: '',
			dynamicComponent: ''
		},
		breadcrumbs: [],  // breadcrumbs for page header

		openFireRollCall: {},
		openObservation: {},
		openQuestion: {},
		openSites: {},
		openStaffDirectory: {},

		lookupTables: [],
		lookupValues: [],

		sites: [],
	},

	plugins: [createPersistedState()],

	getters: {
		GET_currentUser(state) {
			return state.currentUser;
		},
		GET_currentUserData(state) {
			return {
				userId: state.currentUser.id,
				userName: state.currentUser.userName,
				userEmail: state.currentUser.userEmail
			}
		},
		GET_mobileNav(state) {
			return state.mobileNav
		}, // GET MOBILE NAV
		GET_panelLayout(state) {
			return state.panelLayout
		}, // GET PANEL LAYOUT
		GET_breadcrumbs(state) {
			return state.breadcrumbs
		},

		GET_openFireRollCall(state) {
			return state.openFireRollCall
		}, // GET OPEN FIRE ROLL CALL
		GET_openQuestion(state) {
			return state.openQuestion
		}, // GET OPEN QUESTION
		GET_openObservation(state) {
			return state.openObservation
		}, // GET OPEN OBSERVATION
		GET_openSites(state) {
			return state.openSites
		}, // GET OPEN SITES
		GET_openStaffDirectory(state) {
			return state.openStaffDirectory
		}, // GET OPEN STAFF DIRECTORY

		GET_lookupValues: (state) => (lookupTable) => {
			return state.lookupValues.filter((lookupValue) => {
				return lookupValue.lookupTable === lookupTable
			})
		},

		GET_sites(state) { return state.sites }, // GET sites
	},

	mutations: {
		SET_currentUser(state, payload) {
			state.currentUser = payload;
		},
		MUT_mobileNav(state, data) {
			state.mobileNav = data
		}, // SET MOBILE NAV
		MUT_panelLayout(state, data) {
			state.panelLayout = data
		}, // PANEL LAYOUT MUTATIONS
		// BREADCRUMBS
		MUT_breadcrumbs(state, data) {
			state.breadcrumbs = data
		},

		MUT_openFireRollCall(state, data) {
			state.openFireRollCall = data
		},
		MUT_openObservation(state, data) {
			state.openObservation = data
		},
		MUT_openQuestion(state, data) {
			state.openQuestion = data
		},
		MUT_openSites(state, data) {
			state.openSites = data
		},
		MUT_openStaffDirectory(state, data) {
			state.openStaffDirectory = data
		},

		MUT_lookupTables(state, data) {
			state.lookupTables = data
		},
		MUT_lookupValues(state, data) {
			state.lookupValues = data
		},

		MUT_sites(state, data) { state.sites = data }, // Mutates sites state
	},

	actions: {
		ACT_mobileNav({commit}, payload) {
			commit('MUT_mobileNav', payload)
		}, // SET MOBILE NAV
		ACT_panelLayout({commit}, payload) {
			commit('MUT_panelLayout', payload);
		}, // LAYOUT PANEL ACTIONS
		// BREADCRUMBS
		ACT_breadcrumbs({commit}, payload) {
			commit('MUT_breadcrumbs', payload)
		},

		ACT_openFireRollCall({commit}, payload) {
			commit('MUT_openFireRollCall', payload)
		},
		ACT_openObservation({commit}, payload) {
			commit('MUT_openObservation', payload)
		},
		ACT_openQuestion({commit}, payload) {
			commit('MUT_openQuestion', payload)
		},
		ACT_openSites({commit}, payload) {
			commit('MUT_openSites', payload)
		},
		ACT_openStaffDirectory({commit}, payload) {
			commit('MUT_openStaffDirectory', payload)
		},

		ACT_lookupTables({commit}, payload) {
			commit('MUT_lookupTables', payload)
		},
		ACT_lookupValues({commit}, payload) {
			commit('MUT_lookupValues', payload)
		},

		ACT_sites({commit}, payload){ commit('MUT_sites', payload) },
	},

	modules: {
		alertbox_store: alertbox_store,
		fsDialog_store: fsDialog_store,
		puDialog_store: puDialog_store,
		upload_file_store: upload_file_store,
		photoUpload_store: photoUpload_store,
		photoViewer_store: photoViewer_store,
		progressBar_store: progressBar_store,
		firebase_auth_store: firebase_auth_store,
		confirmBox_store: confirmBox_store,
		location_store: location_store,
		confirmation_store: confirmation_store,
		qr_code_reader_store: qr_code_reader_store,
		qr_code_generator_store: qr_code_generator_store,
	}

})
