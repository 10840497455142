<!-- The comment component -->
<template>
    <v-dialog fullscreen
              hide-overlay
              style="z-index: 9999 !important;"
              transition="dialog-bottom-transition"
              v-model="GET_fsDialog.show">

        <v-card ref="popup" class="appbackground">

            <div class="primary text-right appclosebar d-flex align-center">

                <app-text category="text-medium" class="white--text ma-4">{{ GET_fsDialog.title }}</app-text>

                <v-spacer/>

                <v-icon @click.native="closefsDialog"
                        class="icons8-multiply ma-4"
                        color="white"/>

            </div>

            <div>
                <component :is="GET_fsDialog.dynamicComponent" :key="dynamicComponentKey"></component>
            </div>

        </v-card>

    </v-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import app_text from '../../components/base/app_text.vue';

export default {

    components: {app_text},

    data: () => ({
        dynamicComponent: '',
        dynamicComponentKey: 0
    }),

    computed: {
        ...mapGetters({
            GET_fsDialog: 'fsDialog_store/GET_fsDialog',
        }),
    },

    watch: {
        GET_fsDialog: {
            handler() {
                console.log('fsDialog Updated so increase component key')
                this.dynamicComponentKey++
            }, deep: true
        }
    },

    methods: {
        closefsDialog() {
            this.MIX_fsDialog(false, '')
        },
    }

}
</script>

<style scoped>

</style>
