<template>
	<v-card class="rounded-lg pa-4" flat>

		<!--Icon | Title-->
		<div class="d-flex align-center">

			<v-icon class="icons8-high-importance mr-4" color="red" size="48"/>

			<app-text category="text-medium-bold" class="primary--text">Outstanding Actions</app-text>

		</div>

		<!--Instructional Text-->
		<app-text class="mt-4">You have outstanding Actions that you must read and accept</app-text>

		<!--Instructional Text - Countdown-->
		<app-text v-if="['1 day', '2 days', '3 days'].includes(MIX_notifications_getDaysRemainingForOldestOutstandingActionResponse())" class="mt-4">
			In
			<span class="font-weight-bold red--text">{{ MIX_notifications_getDaysRemainingForOldestOutstandingActionResponse() }}</span>
			you will be blocked from SWAPPing
		</app-text>

		<!--Instructional Text - Elapsed-->
		<app-text v-if="MIX_notifications_getDaysRemainingForOldestOutstandingActionResponse() === 'Blocked'" class="red--text mt-4">
			Your 3 days have expired and you are now blocked from SWAPPing
		</app-text>

		<app-text category="text-small" class="grey--text mt-4">Required by: {{ $moment(Number(dateOfOldestOutstandingActionResponse)).format('Do ddd MMM \'YY @ HH:mm') }}</app-text>

	</v-card>
</template>

<script>
import {mixinNotifications} from "@/mixin-notifications";
export default {

	name: "homeOutstandingActions",

	mixins: [mixinNotifications],

}
</script>

<style scoped>

</style>
