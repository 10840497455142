<!--
User Schedule

Renders a schedule for the user to select where they're going to be Mon-Fri.
To make entering the same value for every day simpler, the user can also use the Set All button/dialog.
-->
<template>
    <v-row no-gutters class="ma-4">

        <!--Title-->
        <page-title icon="icons8-schedule" :pageTitle="`${computedScheduleName} Schedule`"/>

        <!--Instructional text-->
        <v-col cols="12">
            <app-text category="text-default">
                Use this scheduler to set {{ computedNoun }} expected location.
                Any time {{ computedNoun }} schedule changes (e.g. changing site, holidays etc) please update this form.
            </app-text>
        </v-col>

        <!--Divider-->
        <v-col cols="12">
            <v-divider class="my-4"/>
        </v-col>

        <!--Missing fields | Set All button/dialog-->
        <v-col class="d-flex" cols="12">

            <!--Missing fields-->
            <app-text v-if="scheduleError" category="text-default-bold" class="red--text">{{ scheduleError }}</app-text>

            <v-spacer/>

            <!--Set All button/dialog-->
            <user-schedule-set-all-dialog v-on:updateUserScheduleSetAll="updateUserScheduleSetAll"
                                          :scheduleOptions="scheduleOptions"/>
        </v-col>

        <!--Days form-->
        <v-form style="width: 100%">

            <!--Day fields-->
            <v-col class="pa-0" cols="12">

                <!--Monday-->
                <app-input inputType="select"
                           class="mt-4"
                           :error="errors.monday"
                           :items="scheduleOptions"
                           item-text="siteName"
                           label="Monday"
                           v-model="form.monday"/>

                <!--Tuesday-->
                <app-input inputType="select"
                           class="mt-4"
                           :error="errors.tuesday"
                           :items="scheduleOptions"
                           item-text="siteName"
                           label="Tuesday"
                           v-model="form.tuesday"/>

                <!--Wednesday-->
                <app-input inputType="select"
                           class="mt-4"
                           :error="errors.wednesday"
                           :items="scheduleOptions"
                           item-text="siteName"
                           label="Wednesday"
                           v-model="form.wednesday"/>

                <!--Thursday-->
                <app-input inputType="select"
                           class="mt-4"
                           :error="errors.thursday"
                           :items="scheduleOptions"
                           item-text="siteName"
                           label="Thursday"
                           v-model="form.thursday"/>

                <!--Friday-->
                <app-input inputType="select"
                           class="mt-4"
                           :error="errors.friday"
                           :items="scheduleOptions"
                           item-text="siteName"
                           label="Friday"
                           v-model="form.friday"/>

                <!--Saturday-->
                <app-input inputType="select"
                           class="mt-4"
                           :error="errors.saturday"
                           :items="scheduleOptions"
                           item-text="siteName"
                           label="Saturday"
                           v-model="form.saturday"/>

                <!--Sunday-->
                <app-input inputType="select"
                           class="mt-4"
                           :error="errors.sunday"
                           :items="scheduleOptions"
                           item-text="siteName"
                           label="Sunday"
                           v-model="form.sunday"/>

            </v-col>

        </v-form>

        <!--Action buttons-->
        <v-col class="d-flex mt-4">

            <v-spacer/>

            <!--Submit-->
            <app-btn @click.native="validate"
                     color="success"
                     icon="icons8-checkmark-yes"
                     label="Submit"/>

        </v-col>

    </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import userScheduleSetAllDialog from "./userScheduleSetAllDialog/userScheduleSetAllDialog"

export default {

    props: ['user'],

    components: {
        userScheduleSetAllDialog
    },

    data: () => ({
        form: {
            id: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
        },
        errors: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        },
        scheduleError: '',
        scheduleOptions: [],
        sitesData: [],
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),

        computedScheduleName() {
            const t = this

            return t.$props.user ? t.$props.user.userName.split(' ')[0] + '\'s' : 'My'
        },

        computedNoun() {
            const t = this

            return t.$props.user ? t.$props.user.userName.split(' ')[0] + '\'s' : 'your'
        }

    },

    methods: {
        /**
         * Get Schedule Collection
         *
         * Fetch Schedule collection data.
         * Assign document day values to form day values.
         * If the user has no schedule, render an error.
         *
         * @returns {Promise<void>}
         */
        async getScheduleCollectionData(userId) {
            const t = this
            let mySchedule = {}

            // const collection = t.$firebase.db.collection('schedule').doc(t.GET_currentUser.id)
            const collection = t.$firebase.db.collection('schedule').doc(userId)
            const doc = await collection.get()

            if (doc.exists) {
                mySchedule = doc.data()
            } else {
                t.scheduleError = 'You must complete your schedule'
            }

            t.form.monday = mySchedule.monday
            t.form.tuesday = mySchedule.tuesday
            t.form.wednesday = mySchedule.wednesday
            t.form.thursday = mySchedule.thursday
            t.form.friday = mySchedule.friday
            t.form.saturday = mySchedule.saturday
            t.form.sunday = mySchedule.sunday
        },

        /**
         * Get Sites Collection
         *
         * Clear the data to avoid duplications.
         * Fetch all the data from the Sites collection.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                t.sitesData = []

                // Iterate over the collection and add the records to the array
                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesData.push(document.siteName)
                    }
                })

                t.mergeSiteAndScheduleOptions(t.sitesData)

            })
        },

        /**
         * Merge Site and Schedule Options
         *
         * Take the sites data and the schedule lookup values and create a new new array
         * of just the text-based values to use in the select drop-downs.
         */
        mergeSiteAndScheduleOptions(sitesData) {
            const t = this

            t.scheduleOptions = [...sitesData, ...t.extendedScheduleOptionsData]
        },

        /**
         * Update UserSchedule Set All
         *
         * @param payload - the selected option returned from userScheduleSetAllDialog.vue.
         */
        updateUserScheduleSetAll(payload) {
            const t = this

            t.form.monday = payload
            t.form.tuesday = payload
            t.form.wednesday = payload
            t.form.thursday = payload
            t.form.friday = payload
            t.form.saturday = payload
            t.form.sunday = payload
        },

        /**
         * Validate
         *
         * Validate all fields for presence.
         * If all days are filled call to saveDocument().
         * If any fields are missing, render an error.
         */
        validate() {
            const t = this

            !t.form.monday ? t.errors.monday = true : t.errors.monday = false
            !t.form.tuesday ? t.errors.tuesday = true : t.errors.tuesday = false
            !t.form.wednesday ? t.errors.wednesday = true : t.errors.wednesday = false
            !t.form.thursday ? t.errors.thursday = true : t.errors.thursday = false
            !t.form.friday ? t.errors.friday = true : t.errors.friday = false
            !t.form.saturday ? t.errors.saturday = true : t.errors.saturday = false
            !t.form.sunday ? t.errors.sunday = true : t.errors.sunday = false

            if (!Object.values(t.errors).includes(true)) {
                t.saveDocument()
            }
        },

        /**
         * Save Document
         *
         * Save the current document to the database.
         * If there was a schedule error, clear it.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this

            // Create document
            const createDocumentResult = await t.MIX_updateDocument('schedule', t.form)

            if (createDocumentResult.code === 1) {
                t.scheduleError = ''
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `Schedule saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving Schedule`, null, null)
            }
        }
    },

    async mounted() {
        const t = this
        let usersScheduleId

        // If there are props (not the current user), use their ID, if not, use the current user's ID
        t.form.id = t.$props.user ? t.$props.user.id : t.GET_currentUser.id

        // Fetch collection data
        await t.getScheduleCollectionData(t.form.id)
        await t.getSitesCollectionData()
    }

}
</script>
