export const mixinNotifications = {

	data: () => ({
		numberOfActionsNotifications: 0,
		dateOfOldestOutstandingActionResponse: '',
	}),

	methods: {

		/**
		 * Number of Actions
		 *
		 * Call for the current user's ActionsResponses, and set:
		 *   -> The number that have not been accepted.
		 *   -> The date of the oldest outstanding Action Response.
		 *
		 * @returns {Promise<{dateOfOldestOutstandingActionResponse}>}
		 */
		async MIX_notifications_numberOfActions() {
			const t = this
			const currentUser = t.GET_currentUser
			let collectionData = []

			let collection = await t.$firebase.db.collection('actionsResponses')

				if (t.GET_currentUser?.id) collection = collection.where('userId', '==', currentUser.id)

				collection.onSnapshot(snapshot => {

					collectionData = []

					snapshot.forEach(doc => {

						const document = doc.data()
						document.id = doc.id

						if (!document.hasAccepted) collectionData.push(document)

					})

					// Set the number of outstanding Actions
					t.numberOfActionsNotifications = collectionData.length

					// Sort by date, oldest first
					collectionData = collectionData.sort((a, b) => {
						return a.createdDateTime > b.createdDateTime ? 1 : -1
					})

					// Set the date of the oldest outstanding Action Response
					// NB... This is being used as the countdown to blocking the user from SWAPPing (3 days)
					t.dateOfOldestOutstandingActionResponse = collectionData.length ? collectionData[0].createdDateTime : ''
				})
		},

		/**
		 * Get Days Remaining For Oldest Outstanding Action Response
		 *
		 * The user has three days to read and accept any required Actions,
		 * so calculate the number of days since the oldest outstanding required Action was posted,
		 * and the opposite. So, if an Action has just been posted, return '3 days' (as a countdown).
		 * When the three days has expired, return 'Blocked'.
		 *
		 * @returns {string} the number of days remaining, or 'Blocked', if three days have elapsed
		 */
		MIX_notifications_getDaysRemainingForOldestOutstandingActionResponse() {
			const t = this
			const ONE_DAY = 86400000
			const TWO_DAYS = 172800000
			const THREE_DAYS = 259200000
			const DATETIME_NOW = new Date().getTime()
			let OLDEST_ACTION_DATE = t.dateOfOldestOutstandingActionResponse
			let daysRemaining

			// If there is no oldest action date, all Actions have been accepted. Set to false.
			if (!OLDEST_ACTION_DATE) return ''

			let diff = DATETIME_NOW - OLDEST_ACTION_DATE

			if (diff < ONE_DAY) daysRemaining = '3 days'

			else if (diff < TWO_DAYS) daysRemaining = '2 days'

			else if (diff < THREE_DAYS) daysRemaining = '1 day'

			else if (diff > THREE_DAYS) daysRemaining = 'Blocked'

			return daysRemaining
		},

	},

	async created() {
		await this.MIX_notifications_numberOfActions()
	}
}
