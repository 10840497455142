<template>
	<div>

		{{ computedInit }}

		<!--Instructional Text-->
		<app-text v-if="computedIsViewOnly" class="red--text pa-4">
			This document does not apply to you, you are viewing this as <strong>read only</strong>.
		</app-text>
		<app-text v-else-if="!computedIsDocumentAccepted" class="pa-4">
			Please mark that you have read this document by checking the box underneath
		</app-text>

		<!--Already Accepted Document-->
		<div v-if="!computedIsViewOnly && formData.documentAcceptanceRequired && computedIsDocumentAccepted"
			 class="d-flex align-center pl-4">
			<v-icon class="icons8-checkmark-yes" color="success" size="32"/>
			<app-text class="pa-4">You have read and accepted this document</app-text>
		</div>

		<!--Images-->
		<div v-if="form.documentType === 'image/png' || form.documentType === 'image/jpeg'"
			 class="d-flex justify-center pb-4 px-4" style="width: 100%">
			<v-img :src="getFile" contain max-width="100%"/>
		</div>

		<!--PDFs-->
		<iframe v-else :src="getFile" class="expandtofill"/>

		<!--Acceptance of Document -->
		<div v-if="!computedIsViewOnly && !computedIsDocumentAccepted" class="d-flex align-center justify-space-between px-4">

			<v-checkbox label="I have read and accept the terms of this document."
						v-model="checkboxChecked"/>

			<!--Confirm Button-->
			<app-btn v-if="checkboxChecked"
					 @click.native="handleAcceptDocumentSelection"
					 class="ml-4"
					 color="success"
					 label="Confirm"/>

		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {

	name: "actionsViewer",

	props: ['formData', 'userActionsResponsesCollectionData', 'usersCollectionData'],

	data: () => ({
		checkboxChecked: false,
		currentUserCurrentActionResponse: {},
		form: {},
	}),

	computed: {
		...mapGetters({
			GET_currentUser: 'GET_currentUser',
		}),

		computedInit() {
			const t = this
			const formData = t.$props.formData

			// If there is any form data, assign it to the form
			if (formData?.id) t.form = formData

			// Find the current user's ActionResponse for this ActionDocument
			if (t.userActionsResponsesCollectionData.length) {
				t.currentUserCurrentActionResponse = t.userActionsResponsesCollectionData.find(ar => ar.documentId === t.formData.id) ?? {}
			}

			// If this document requires acceptance, and is the first time the user has opened this document,
			// update the user's ActionsResponses document's firstOpenedDateTime field with a timestamp
			if (t.formData.documentAcceptanceRequired && (t.currentUserCurrentActionResponse.id && !t.currentUserCurrentActionResponse.firstOpenedDateTime)) {
				t.$emit('emitUserReadActionDocument', t.currentUserCurrentActionResponse.id)
			}

		},

		/**
		 * Computed Is Document Accepted
		 *
		 * Calculate and return whether the document has been accepted, or not.
		 *
		 * @returns {boolean} whether the document has been accepted, or not
		 */
		computedIsDocumentAccepted() {
			const t = this
			let currentUserCurrentActionResponse

			// Return true if the document does not need accepting
			if (!t.form.documentAcceptanceRequired) return true

			// Find the current user's ActionResponse for this ActionDocument
			if (t.userActionsResponsesCollectionData.length) {
				currentUserCurrentActionResponse = t.userActionsResponsesCollectionData.find(ar => ar.documentId === t.formData.id) ?? {}
			}

			return currentUserCurrentActionResponse.hasAccepted
		},

		/**
		 * Computed Is View Only
		 *
		 * Calculate and return whether the document is view only, or not.
		 * For the current document (formData), the document should be View Only if:
		 *  - The current user's ID isn't included in userActionsResponsesCollectionData (userId field).
		 *
		 * @returns {boolean} whether the document is view only, or not
		 */
		computedIsViewOnly() {
			const t = this

			// Get the ActionResponse for the current user, for the current document
			const currentUserCurrentActionResponse = t.$props.userActionsResponsesCollectionData.find(
				ar => ar.documentId === t.formData.id) || {}

			return !currentUserCurrentActionResponse.id
		},

		/**
		 * Get File
		 *
		 * Return the URL of the file to be displayed.
		 *
		 * @returns {string} the URL of the file to be displayed
		 */
		getFile() {
			const t = this
			let url

			// PDF
			if (t.$props.formData.documentType === "application/pdf") url = t.$props.formData.documentUrl

			// MPEG
			else if (t.$props.formData.documentType === "video/mpeg") url = t.$props.formData.documentUrl

			// Office
			else if (
				(t.$props.formData.documentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
				(t.$props.formData.documentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
				(t.$props.formData.documentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {

				url = "https://view.officeapps.live.com/op/embed.aspx?src=" + t.$props.formData.documentUrl
			}

			// URL
			else url = t.$props.formData.documentUrl

			return url
		},

	},

	methods: {

		/**
		 * Handle Accept Document Selection
		 *
		 * @returns {Promise<void>}
		 */
		async handleAcceptDocumentSelection() {
			const t = this
			const currentUserDocumentResponse = t.currentUserCurrentActionResponse
			let fb_response

			if (currentUserDocumentResponse?.id) {

				fb_response = await t.MIX_updateDocumentFieldsById(
					'actionsResponses',
					currentUserDocumentResponse.id,
					{
						hasAccepted: true,
						responseDateTime: t.$moment().format('x')
					}
				)

			}

			t.MIX_renderConfirmationAlert(
				fb_response,
				'Successfully Updated Action',
				'Error Updating Action'
			)

			t.$emit('emitCloseRightPanel')
		},

	}

}
</script>

<style scoped>
.expandtofill {
	display: flex;
	justify-content: center;

	border: none;
	height: 100vh !important;
	width: 100% !important;
}
</style>
