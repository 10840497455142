<template>
	<v-card>

		<!--Toolbar-->
		<v-toolbar class="pl-2" color="primary">

			<!--Title-->
			<app-text category="text-medium" class="white--text">{{ point.siteName }}</app-text>

			<v-spacer/>

			<close-icon @click.native="closeDialog"/>

		</v-toolbar>

		<!--Content-->
		<div class="d-flex flex-column justify-center text-center pa-4">


			<!--Site Name-->
			<div class="d-flex flex-column align-center">
				<app-text category="text-small" class="grey--text">Office</app-text>
				<app-text>{{ point.siteName }}</app-text>
			</div>

			<v-divider class="my-4"/>

			<!--Email-->
			<div class="d-flex flex-column align-center">
				<app-text category="text-small" class="grey--text">Email</app-text>
				<app-text>{{ point.siteContact.contactEmail || '(Not Supplied)' }}</app-text>
			</div>

			<v-divider class="my-4"/>

			<!--Telephone-->
			<div class="d-flex flex-column align-center">
				<app-text category="text-small" class="grey--text">Telephone</app-text>
				<app-text>{{ point.siteTelephone || '(Not Supplied)' }}</app-text>
			</div>

			<v-divider class="my-4"/>

			<!--Description-->
			<div class="d-flex flex-column align-center">
				<app-text category="text-small" class="grey--text text-center">Description</app-text>
				<app-text class="text-left" :style="point.siteDescription && 'width: 100%'">{{ point.siteDescription || '(Not Supplied)' }}</app-text>
			</div>

		</div>

	</v-card>
</template>

<script>

export default {

	name: "mapOfficePointDialog",

	props: ['point'],

	data: () => ({}),

	methods: {

		closeDialog() {
			const t = this

			t.$emit('emitCloseMapPointDialog')
		},

	}

}
</script>

<style scoped>
.mpd-2row-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
}

.mpd-3row-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr auto 1fr;

	margin-top: 32px;
}
</style>
